// src/components/AddressAutocomplete.js
import React, { useState } from "react";
import Autosuggest from "react-autosuggest";
// import mapboxClient from "../mapboxClient";
import PropTypes from "prop-types";
import "./AddressAutocomplete.css"; // You'll create this for styling
import mapboxClient from "./mapboxClient";

const AddressAutocomplete = ({ onSelect }) => {
  const [value, setValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  // Fetch suggestions from Mapbox Geocoding API
  const fetchSuggestions = async ({ value }) => {
    if (!value) {
      setSuggestions([]);
      return;
    }

    try {
      const response = await mapboxClient
        .forwardGeocode({
          query: value,
          autocomplete: true,
          limit: 5,
        })
        .send();

      const features = response.body.features || [];
      setSuggestions(features);
    } catch (error) {
      console.error("Error fetching suggestions:", error);
      setSuggestions([]);
    }
  };

  // When suggestions are cleared
  const clearSuggestions = () => {
    setSuggestions([]);
  };

  // Render each suggestion
  const renderSuggestion = (suggestion) => <div>{suggestion.place_name}</div>;

  // Get the value from a suggestion
  const getSuggestionValue = (suggestion) => suggestion.place_name;

  // When a suggestion is selected
  const onSuggestionSelected = (event, { suggestion }) => {
    onSelect({
      address: suggestion.place_name,
      coordinates: {
        lat: suggestion.center[1],
        lng: suggestion.center[0],
      },
    });
  };

  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={fetchSuggestions}
      onSuggestionsClearRequested={clearSuggestions}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      onSuggestionSelected={onSuggestionSelected}
      inputProps={{
        placeholder: "Enter address",
        value,
        onChange: (e, { newValue }) => setValue(newValue),
      }}
    />
  );
};

AddressAutocomplete.propTypes = {
  onSelect: PropTypes.func.isRequired,
};

export default AddressAutocomplete;

// EventRegistrationSystem.js

import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  Alert,
} from "react-bootstrap";
import { FaCalendarAlt } from "react-icons/fa";
import axios from "axios";
import { QRCodeSVG } from "qrcode.react";
import html2canvas from "html2canvas";
import POPIDeclarationContent from "../../components/POPIDeclaration";

const EventRegistrationSystem = () => {
  const [selectedEvent, setSelectedEvent] = useState("");
  const initialFormData = {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    idNumber: "",
    age: "",
    gender: "",
    race: "",
    province: "",
    businessName: "",
    occupation: "",
    industry: "",
    area: "",
    areaOther: "",
    interest: "",
    interestOther: "",
    absaPersonAccount: false,
    absaBusinessAccount: false,
    disability: false,
    popiConsent: false,
  };

  const [formData, setFormData] = useState(initialFormData);

  const [error, setError] = useState(null); // To handle and display errors
  const [success, setSuccess] = useState(null); // To display success message
  const [loading, setLoading] = useState(false); // To handle loading state
  const [ticket, setTicket] = useState(null); // To store ticket information

  const ticketRef = useRef(); // Reference for ticket component

  const eventTypes = [
    {
      id: "symposium",
      name: "YAEI X Absa Youth Enterprise Development Symposium",
      date: "29th November 2024",
      time: "9h00 - 16h00",
      venue: "The Great Hall, Wits University",
    },
    {
      id: "market",
      name: "YAEI X Absa Proudly Youth African Market Festival",
      date: "30th November 2024",
      time: "12h00 - 18h00",
      venue: "Wits University",
    },
    {
      id: "innovation",
      name: "YAEI X Absa Youth Innovation Challenge",
      date: "28th November 2024",
      time: "9h00 - 16h30",
      venue: "Wits Ehub @ the Matrix, Wits University",
    },
    {
      id: "gala",
      name: "Emerging30 Innovators Gala Dinner",
      date: "29th of November 2024",
      time: "1730h00 - 21h00",
      venue: "Wits University",
    },
  ];

  // Common form handling logic
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleEventSelect = (e) => {
    const value = e.target.value;
    setSelectedEvent(value);
    // Reset form when changing events
    setFormData(initialFormData);
    setError(null);
    setSuccess(null);
    setTicket(null);
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);
    setLoading(true);

    // Simple validation
    if (!formData.popiConsent) {
      setError("You must accept the POPI Act Declaration to proceed.");
      setLoading(false);
      return;
    }

    // Get the selected event details
    const selectedEventDetails = eventTypes.find(
      (event) => event.id === selectedEvent
    );

    if (!selectedEventDetails) {
      setError("Please select a valid event.");
      setLoading(false);
      return;
    }

    const eventName = selectedEventDetails.name;

    // Build the registration data
    const registrationData = {
      ...formData,
      eventName,
    };

    try {
      // Make the API call to the backend
      const response = await axios.post(
        "https://yaei-backend-n63o.onrender.com/api/public-events/register",
        registrationData
      );

      if (response.data.success) {
        setSuccess("Registration successful!");
        setTicket(response.data.registration); // Save ticket info
        // Do not reset the formData here as we might need it for the ticket
        // setFormData(initialFormData); // If you want to reset the form
      } else {
        setError("Registration failed. Please try again.");
      }
    } catch (error) {
      // Handle errors from the backend
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setError(error.response.data.message);
      } else {
        setError("An error occurred during registration.");
      }
    } finally {
      setLoading(false);
    }
  };

  // Function to download the ticket as an image
  const downloadTicket = () => {
    const ticketElement = ticketRef.current;
    html2canvas(ticketElement).then((canvas) => {
      const link = document.createElement("a");
      link.download = `event-ticket-${ticket.eventName}.png`;
      link.href = canvas.toDataURL();
      link.click();
    });
  };

  // Render appropriate form based on selected event
  const renderForm = () => {
    if (ticket) {
      return (
        <Ticket
          ticket={ticket}
          formData={formData}
          selectedEventDetails={eventTypes.find(
            (event) => event.id === selectedEvent
          )}
          downloadTicket={downloadTicket}
          ticketRef={ticketRef}
        />
      );
    }

    switch (selectedEvent) {
      case "symposium":
        return (
          <SymposiumForm
            formData={formData}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            error={error}
            success={success}
            loading={loading}
          />
        );
      case "market":
        return (
          <MarketForm
            formData={formData}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            error={error}
            success={success}
            loading={loading}
          />
        );
      case "innovation":
        return (
          <InnovationForm
            formData={formData}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            error={error}
            success={success}
            loading={loading}
          />
        );

      case "gala":
        return (
          <GalaForm
            formData={formData}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            error={error}
            success={success}
            loading={loading}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Container
      fluid
      className="min-vh-100 d-flex align-items-center justify-content-center"
      style={{ backgroundColor: "#F0F3FA" }}>
      <Row className="justify-content-center w-100">
        <Col md={10} lg={8} xl={7} xs={12} sm={12}>
          <Card
            className="shadow-lg border-0 rounded-lg"
            style={{ width: "100%" }}>
            {/* Adjust padding for responsiveness */}
            <Card.Body className="p-3 p-md-5">
              <div className="text-center mb-4">
                <img
                  src="/logo.png"
                  alt="Young African Entrepreneurs Institute"
                  height="120"
                />
              </div>
              <h2
                className="text-center mb-4"
                style={{ color: "#061151", fontWeight: "bold" }}>
                Youth Entrepreneurship Week 2024 Registration
              </h2>

              {!ticket && (
                <Form.Group controlId="eventSelect" className="mb-4">
                  <Form.Label style={{ color: "#061151", fontWeight: "bold" }}>
                    <FaCalendarAlt className="me-2" />
                    Select Event*
                  </Form.Label>
                  <Form.Control
                    as="select"
                    value={selectedEvent}
                    onChange={handleEventSelect}
                    style={{ borderColor: "#bf9731" }}>
                    <option value="">Choose an event to register</option>
                    {eventTypes.map((event) => (
                      <option key={event.id} value={event.id}>
                        {event.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              )}

              {selectedEvent && <div>{renderForm()}</div>}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

// Ticket Component
const Ticket = ({
  ticket,
  formData,
  selectedEventDetails,
  downloadTicket,
  ticketRef,
}) => {
  // State to manage QR code size
  const [qrCodeSize, setQrCodeSize] = useState(200);

  useEffect(() => {
    const updateSize = () => {
      if (window.innerWidth < 350 && window.innerWidth > 270) {
        setQrCodeSize(170); // Smaller QR code on small screens
      } else if (window.innerWidth <= 270) {
        setQrCodeSize(150);
      } else {
        setQrCodeSize(250); // Default size on larger screens
      }
    };

    updateSize(); // Set initial size
    window.addEventListener("resize", updateSize); // Listen for window resize
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return (
    <Card
      ref={ticketRef}
      className="p-2 p-md-4"
      style={{
        width: "100%",
        margin: "0 auto",
        background: "#FFF",
        border: "2px solid #061151",
        boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
        borderRadius: "16px",
      }}>
      <div className="text-center">
        <h3 style={{ color: "#061151", fontWeight: "700" }}>
          {ticket.firstName} {ticket.lastName}
        </h3>

        <div className="my-3">
          <strong style={{ color: "#bf9731", fontSize: "1.2rem" }}>
            {ticket.eventName}
          </strong>
          {selectedEventDetails && (
            <div className="text-muted small mt-1">
              <div>Date: {selectedEventDetails.date}</div>
              <div>Time: {selectedEventDetails.time}</div>
              <div>Venue: {selectedEventDetails.venue}</div>
            </div>
          )}
        </div>

        {/* Center the QR code and adjust its size */}
        <div className="mb-4 text-center">
          <QRCodeSVG value={ticket.qrCode} size={qrCodeSize} level="H" />
        </div>

        <div className="mb-4">
          <p className="mb-1">{ticket.email}</p>
          <p className="mb-1">{ticket.phoneNumber}</p>
        </div>

        <div className="mb-4">
          <p className="small text-muted">
            Please present this ticket for event check-in and meal services.
            <br />
            <a
              href="https://yaeiacademy.co.za/#/ev"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#bf9731" }}>
              Event Evaluation Form
            </a>
            <div>yaeiacademy.co.za/#/ev</div>
          </p>
        </div>

        <Button
          onClick={downloadTicket}
          style={{
            background: "#061151",
            borderColor: "#061151",
            width: "100%",
          }}>
          Download Ticket
        </Button>
      </div>
    </Card>
  );
};

// Common Form Fields Component
const CommonFields = ({ formData, handleChange }) => (
  <>
    <Row>
      <Col md={6}>
        <Form.Group className="mb-3">
          <Form.Label>First Name*</Form.Label>
          <Form.Control
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            required
            style={{ borderColor: "#bf9731" }}
          />
        </Form.Group>
      </Col>
      <Col md={6}>
        <Form.Group className="mb-3">
          <Form.Label>Last Name*</Form.Label>
          <Form.Control
            type="text"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            required
            style={{ borderColor: "#bf9731" }}
          />
        </Form.Group>
      </Col>
    </Row>

    <Row>
      <Col md={6}>
        <Form.Group className="mb-3">
          <Form.Label>Phone Number*</Form.Label>
          <Form.Control
            type="tel"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
            required
            style={{ borderColor: "#bf9731" }}
          />
        </Form.Group>
      </Col>
      <Col md={6}>
        <Form.Group className="mb-3">
          <Form.Label>Email Address*</Form.Label>
          <Form.Control
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            style={{ borderColor: "#bf9731" }}
          />
        </Form.Group>
      </Col>
    </Row>

    <Form.Group className="mb-3">
      <Form.Label>ID Number*</Form.Label>
      <Form.Control
        type="text"
        name="idNumber"
        value={formData.idNumber}
        onChange={handleChange}
        required
        style={{ borderColor: "#bf9731" }}
      />
    </Form.Group>

    <Form.Group className="mb-3">
      <Form.Label>Gender*</Form.Label>
      <Form.Control
        as="select"
        name="gender"
        value={formData.gender}
        onChange={handleChange}
        required
        style={{ borderColor: "#bf9731" }}>
        <option value="">Select Gender</option>
        <option value="Female">Female</option>
        <option value="Male">Male</option>
        <option value="Prefer not to say">Prefer not to say</option>
      </Form.Control>
    </Form.Group>

    {/* Innovation-specific fields */}
    <Form.Group className="mb-3">
      <Form.Label>Age*</Form.Label>
      <Form.Control
        type="number"
        name="age"
        value={formData.age}
        onChange={handleChange}
        required
        min="10"
        max="100"
        style={{ borderColor: "#bf9731" }}
      />
    </Form.Group>

    <Form.Group className="mb-3">
      <Form.Label>Race*</Form.Label>
      <Form.Control
        as="select"
        name="race"
        value={formData.race}
        onChange={handleChange}
        required
        style={{ borderColor: "#bf9731" }}>
        <option value="">Select Race</option>
        <option value="Black">Black</option>
        <option value="Coloured">Coloured</option>
        <option value="Indian">Indian</option>
        <option value="White">White</option>
      </Form.Control>
    </Form.Group>

    <Form.Group className="mb-3">
      <Form.Label>Province*</Form.Label>
      <Form.Control
        as="select"
        name="province"
        value={formData.province}
        onChange={handleChange}
        required
        style={{ borderColor: "#bf9731" }}>
        <option value="">Select Province</option>
        <option value="Gauteng">Gauteng</option>
        <option value="Mpumalanga">Mpumalanga</option>
        <option value="Limpopo">Limpopo</option>
        <option value="KwaZulu-Natal">KwaZulu-Natal</option>
        <option value="Northern Cape">Northern Cape</option>
        <option value="North West">North West</option>
        <option value="Eastern Cape">Eastern Cape</option>
        <option value="Western Cape">Western Cape</option>
        <option value="Free State">Free State</option>
      </Form.Control>
    </Form.Group>

    <Form.Group className="mb-3">
      <Form.Label>Name of Business/Organization*</Form.Label>
      <Form.Control
        type="text"
        name="businessName"
        value={formData.businessName}
        onChange={handleChange}
        required
        style={{ borderColor: "#bf9731" }}
      />
    </Form.Group>

    <Form.Group controlId="disability" className="mt-3">
      <Form.Check
        type="checkbox"
        name="disability"
        label="Are you living with disability?"
        checked={formData.disability}
        onChange={handleChange}
      />
    </Form.Group>

    <Form.Group controlId="absaPersonAccount" className="mt-3">
      <Form.Check
        type="checkbox"
        name="absaPersonAccount"
        label="Do you have an Absa person bank account"
        checked={formData.absaPersonAccount}
        onChange={handleChange}
      />
    </Form.Group>

    <Form.Group controlId="absaBusinessAccount" className="mt-3">
      <Form.Check
        type="checkbox"
        name="absaBusinessAccount"
        label="Do you have an Absa business bank account"
        checked={formData.absaBusinessAccount}
        onChange={handleChange}
      />
    </Form.Group>
  </>
);

// POPI Declaration Component
const POPIDeclaration = ({ formData, handleChange }) => (
  <div
    className="mt-4 p-3 border rounded"
    style={{
      background: "#F0F3FA",
      maxHeight: "500px",
      overflowY: "scroll",
      fontSize: "0.9rem",
    }}>
    <h5 style={{ color: "#061151", fontWeight: "bold" }}>
      POPI Act Declaration
    </h5>

    {/* style={{ maxHeight: "200px", overflowY: "scroll", fontSize: "0.9rem" }}> */}
    <POPIDeclarationContent />

    <Form.Group controlId="popiConsent" className="mt-3">
      <Form.Check
        type="checkbox"
        name="popiConsent"
        label="I acknowledge that I have read and understood this POPI Act Declaration and consent to the collection, use, and sharing of my personal information as described herein."
        checked={formData.popiConsent}
        onChange={handleChange}
        required
      />
    </Form.Group>
  </div>
);

// Event Header Component
const EventHeader = ({ title, date, time, venue, dressCode }) => (
  <div className="p-3 mb-4 bg-light border rounded">
    <h5 style={{ color: "#061151", fontWeight: "bold" }}>{title}</h5>
    <p>
      Date: {date}
      <br />
      Time: {time}
      <br />
      Venue: {venue}
      <br />
      {dressCode && <>Dress Code: {dressCode}</>}
    </p>
  </div>
);

// Form Components for each event
const SymposiumForm = ({
  formData,
  handleChange,
  handleSubmit,
  error,
  success,
  loading,
}) => {
  return (
    <Form onSubmit={handleSubmit}>
      <EventHeader
        title="YAEI X Absa Youth Enterprise Development Symposium"
        date="Friday, 29th November 2024"
        time="9h00 - 16h00"
        venue="The Great Hall, Wits University"
      />

      <CommonFields formData={formData} handleChange={handleChange} />

      {/* Symposium-specific fields */}
      <Form.Group className="mb-3">
        <Form.Label>What Best Describes You?*</Form.Label>
        <Form.Control
          as="select"
          name="occupation"
          value={formData.occupation}
          onChange={handleChange}
          required
          style={{ borderColor: "#bf9731" }}>
          <option value="">Select Occupation</option>
          <option value="Student Entrepreneur">Student Entrepreneur</option>
          <option value="Young Entrepreneur">Young Entrepreneur</option>
          <option value="Young Professional">Young Professional</option>
          <option value="Unemployed Youth">Unemployed Youth</option>
          <option value="Government Representative">
            Government Representative
          </option>
          <option value="Corporate Representative">
            Corporate Representative
          </option>
        </Form.Control>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Industry*</Form.Label>
        <Form.Control
          as="select"
          name="industry"
          value={formData.industry}
          onChange={handleChange}
          required
          style={{ borderColor: "#bf9731" }}>
          <option value="">Select Industry</option>
          <option value="Agriculture and Agro-Processing Sector">
            Agriculture and Agro-Processing Sector
          </option>
          <option value="Automotive and Transport Sector">
            Automotive and Transport Sector
          </option>
          <option value="Chemical and Manufacturing Sector">
            Chemical and Manufacturing Sector
          </option>
          <option value="Clothing and Textile Sector">
            Clothing and Textile Sector
          </option>
          <option value="Construction and Built Environment Sector">
            Construction and Built Environment Sector
          </option>
          <option value="Higher Education">Higher Education</option>
          <option value="Mining and Geosciences Sector">
            Mining and Geosciences Sector
          </option>
          <option value="Non-Profit Sector">Non-Profit Sector</option>
          <option value="Services Sector">Services Sector</option>
          <option value="Technology and Digital Solutions Sector">
            Technology and Digital Solutions Sector
          </option>
        </Form.Control>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>What Best Describes Your Area?*</Form.Label>
        <Form.Control
          as="select"
          name="area"
          value={formData.area}
          onChange={handleChange}
          required
          style={{ borderColor: "#bf9731" }}>
          <option value="">Select Area</option>
          <option value="Urban">Urban</option>
          <option value="Rural">Rural</option>
          <option value="Township">Township</option>
          <option value="Informal Settlement">Informal Settlement</option>
          <option value="Other">Other</option>
        </Form.Control>
      </Form.Group>

      {formData.area === "Other" && (
        <Form.Group className="mb-3">
          <Form.Label>Please specify your area</Form.Label>
          <Form.Control
            type="text"
            name="areaOther"
            value={formData.areaOther}
            onChange={handleChange}
            required
            style={{ borderColor: "#bf9731" }}
          />
        </Form.Group>
      )}

      <Form.Group className="mb-3">
        <Form.Label>
          What Best Describes Your Interest in the Youth Entrepreneurship Week?*
        </Form.Label>
        <Form.Control
          as="select"
          name="interest"
          value={formData.interest}
          onChange={handleChange}
          required
          style={{ borderColor: "#bf9731" }}>
          <option value="">Select Interest</option>
          <option value="Access to funding">Access to funding</option>
          <option value="Access to markets">Access to markets</option>
          <option value="Entrepreneurial Skills Development Opportunity">
            Entrepreneurial Skills Development Opportunity
          </option>
          <option value="Mentorship">Mentorship</option>
          <option value="Networking Opportunities">
            Networking Opportunities
          </option>
          <option value="Becoming a YAEI partner">
            Becoming a YAEI partner
          </option>
          <option value="Other">Other</option>
        </Form.Control>
      </Form.Group>

      {formData.interest === "Other" && (
        <Form.Group className="mb-3">
          <Form.Label>Please specify your interest</Form.Label>
          <Form.Control
            type="text"
            name="interestOther"
            value={formData.interestOther}
            onChange={handleChange}
            required
            style={{ borderColor: "#bf9731" }}
          />
        </Form.Group>
      )}

      {/* POPI Act Declaration */}
      <POPIDeclaration formData={formData} handleChange={handleChange} />

      {error && (
        <Alert variant="danger" className="mt-3 mb-3">
          {error}
        </Alert>
      )}
      {success && (
        <Alert variant="success" className="mt-3 mb-3">
          {success}
        </Alert>
      )}

      <Button
        variant="primary"
        type="submit"
        className="w-100 mt-3"
        style={{ background: "#bf9731", border: "none" }}
        disabled={loading}
        onMouseEnter={(e) => (e.target.style.backgroundColor = "#00021D")}
        onMouseLeave={(e) => (e.target.style.backgroundColor = "#bf9731")}>
        {loading ? "Submitting..." : "Submit Registration"}
      </Button>
    </Form>
  );
};

const MarketForm = ({
  formData,
  handleChange,
  handleSubmit,
  error,
  success,
  loading,
}) => {
  return (
    <Form onSubmit={handleSubmit}>
      <EventHeader
        title="YAEI X Absa Proudly Youth African Market Festival"
        date="Saturday, 30th November 2024"
        time="12h00 - 18h00"
        venue="Wits University"
      />

      <CommonFields formData={formData} handleChange={handleChange} />

      {/* Market-specific fields */}

      {/* POPI Act Declaration */}
      <POPIDeclaration formData={formData} handleChange={handleChange} />

      {error && (
        <Alert variant="danger" className="mt-3 mb-3">
          {error}
        </Alert>
      )}
      {success && (
        <Alert variant="success" className="mt-3 mb-3">
          {success}
        </Alert>
      )}

      <Button
        variant="primary"
        type="submit"
        className="w-100 mt-3"
        style={{ background: "#bf9731", border: "none" }}
        disabled={loading}
        onMouseEnter={(e) => (e.target.style.backgroundColor = "#00021D")}
        onMouseLeave={(e) => (e.target.style.backgroundColor = "#bf9731")}>
        {loading ? "Submitting..." : "Submit Registration"}
      </Button>
    </Form>
  );
};

const InnovationForm = ({
  formData,
  handleChange,
  handleSubmit,
  error,
  success,
  loading,
}) => {
  return (
    <Form onSubmit={handleSubmit}>
      <EventHeader
        title="YAEI X Absa Youth Innovation Challenge"
        date="Thursday, 28th November 2024"
        time="9h00 - 16h30"
        venue="Wits Ehub @ the Matrix, Wits University"
        dressCode="Formal"
      />

      <CommonFields formData={formData} handleChange={handleChange} />

      {/* POPI Act Declaration */}
      <POPIDeclaration formData={formData} handleChange={handleChange} />

      {error && (
        <Alert variant="danger" className="mt-3 mb-3">
          {error}
        </Alert>
      )}
      {success && (
        <Alert variant="success" className="mt-3 mb-3">
          {success}
        </Alert>
      )}

      <Button
        variant="primary"
        type="submit"
        className="w-100 mt-3"
        style={{ background: "#bf9731", border: "none" }}
        disabled={loading}
        onMouseEnter={(e) => (e.target.style.backgroundColor = "#00021D")}
        onMouseLeave={(e) => (e.target.style.backgroundColor = "#bf9731")}>
        {loading ? "Submitting..." : "Submit Registration"}
      </Button>
    </Form>
  );
};

const GalaForm = ({
  formData,
  handleChange,
  handleSubmit,
  error,
  success,
  loading,
}) => {
  return (
    <Form onSubmit={handleSubmit}>
      <EventHeader
        title="Emerging30 Innovators Gala Dinner"
        date="Friday, 29th of November 2024"
        time="1730h00 - 21h00"
        venue="Wits University"
        dressCode="Black Tie/ Traditional Attire"
      />

      <CommonFields formData={formData} handleChange={handleChange} />

      {/* POPI Act Declaration */}
      <POPIDeclaration formData={formData} handleChange={handleChange} />

      {error && (
        <Alert variant="danger" className="mt-3 mb-3">
          {error}
        </Alert>
      )}
      {success && (
        <Alert variant="success" className="mt-3 mb-3">
          {success}
        </Alert>
      )}

      <Button
        variant="primary"
        type="submit"
        className="w-100 mt-3"
        style={{ background: "#bf9731", border: "none" }}
        disabled={loading}
        onMouseEnter={(e) => (e.target.style.backgroundColor = "#00021D")}
        onMouseLeave={(e) => (e.target.style.backgroundColor = "#bf9731")}>
        {loading ? "Submitting..." : "Submit Registration"}
      </Button>
    </Form>
  );
};

export default EventRegistrationSystem;

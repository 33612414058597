import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import {
  Card,
  Button,
  Modal,
  Form,
  Row,
  Col,
  Container,
} from "react-bootstrap";
import { FaPlus, FaEdit, FaTrash } from "react-icons/fa";
import axios from "axios";
import "react-big-calendar/lib/css/react-big-calendar.css";

import Sidebar from "../../components/SideBar";
import TopNavBar from "../../components/topNavBar";
import Profile from "../../components/userProfile";

const localizer = momentLocalizer(moment);

const EventManagement = () => {
  const [events, setEvents] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currentEvent, setCurrentEvent] = useState(null);
  const [view, setView] = useState("month");

  useEffect(() => {
    fetchEvents();
  }, []);

  const fetchEvents = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        "https://yaei-backend-n63o.onrender.com/api/events",
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setEvents(
        response.data.map((event) => ({
          ...event,
          start: new Date(event.start),
          end: new Date(event.end),
        }))
      );
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  const handleViewChange = (newView) => {
    setView(newView);
  };

  const handleSelectSlot = ({ start, end }) => {
    setCurrentEvent({
      start,
      end,
      title: "",
      location: "",
      background: "#061151",
    });
    setShowModal(true);
  };

  const handleSelectEvent = (event) => {
    setCurrentEvent(event);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setCurrentEvent(null);
  };

  const handleSaveEvent = async () => {
    try {
      const token = localStorage.getItem("token");
      if (currentEvent._id) {
        await axios.put(
          `https://yaei-backend-n63o.onrender.com/api/events/${currentEvent._id}`,
          currentEvent,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
      } else {
        await axios.post(
          "https://yaei-backend-n63o.onrender.com/api/events",
          currentEvent,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
      }
      fetchEvents();
      handleCloseModal();
    } catch (error) {
      console.error("Error saving event:", error);
    }
  };

  const handleDeleteEvent = async () => {
    if (window.confirm("Are you sure you want to delete this event?")) {
      try {
        const token = localStorage.getItem("token");
        await axios.delete(
          `https://yaei-backend-n63o.onrender.com/api/events/${currentEvent._id}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        fetchEvents();
        handleCloseModal();
      } catch (error) {
        console.error("Error deleting event:", error);
      }
    }
  };

  const eventStyleGetter = (event) => ({
    style: {
      backgroundColor: event.background || "#061151",
      borderRadius: "5px",
      opacity: 0.8,
      color: "white",
      border: "0px",
      display: "block",
    },
  });

  const EventComponent = ({ event }) => (
    <div>
      <strong>{event.title}</strong>
      <br />
      <small>
        {moment(event.start).format("LT")} - {moment(event.end).format("LT")}
      </small>
      <br />
      <small>{event.location}</small>
    </div>
  );

  return (
    <div
      className="d-flex flex-row gap-4"
      style={{
        minHeight: "100vh",
        maxHeight: "100vh",
        overflowY: "hidden",
        background: "#FFF",
      }}>
      <Sidebar />

      <Container fluid className="d-flex flex-column mt-4">
        <TopNavBar />
        <div className="d-flex flex-row justify-content-between">
          <h4
            style={{ fontSize: "23px", fontWeight: "500", color: "#061151" }}
            className="mb-4">
            Event Management
          </h4>

          <Profile />
        </div>

        <Card
          className="p-2 mt-3"
          style={{
            border: "none",
            background: "#F0F3FA",
            minHeight: "80vh",
            maxHeight: "80vh",
            overflowY: "scroll",
          }}>
          <Card.Body>
            <div className="d-flex justify-content-between align-items-center mb-4">
              <h4 style={{ color: "#061151", fontWeight: "500" }}>Events</h4>
              <Button
                onClick={() => setShowModal(true)}
                variant="outline-primary"
                style={{ borderColor: "#061151", color: "#061151" }}>
                <FaPlus className="me-2" /> Add Event
              </Button>
            </div>
            <div style={{ height: "70vh" }}>
              <Calendar
                localizer={localizer}
                events={events}
                startAccessor="start"
                endAccessor="end"
                style={{ height: "100%" }}
                view={view}
                onView={handleViewChange}
                views={["month", "week", "day"]}
                selectable
                onSelectSlot={handleSelectSlot}
                onSelectEvent={handleSelectEvent}
                eventPropGetter={eventStyleGetter}
                components={{ event: EventComponent }}
              />
            </div>
          </Card.Body>
        </Card>

        <Modal show={showModal} onHide={handleCloseModal} centered>
          <Modal.Header
            closeButton
            style={{ background: "#061151", color: "#bf9731" }}>
            <Modal.Title>
              {currentEvent && currentEvent._id ? "Edit Event" : "Add Event"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  type="text"
                  value={currentEvent ? currentEvent.title : ""}
                  onChange={(e) =>
                    setCurrentEvent({ ...currentEvent, title: e.target.value })
                  }
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Location</Form.Label>
                <Form.Control
                  type="text"
                  value={currentEvent ? currentEvent.location : ""}
                  onChange={(e) =>
                    setCurrentEvent({
                      ...currentEvent,
                      location: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Start</Form.Label>
                    <Form.Control
                      type="datetime-local"
                      value={
                        currentEvent
                          ? moment(currentEvent.start).format(
                              "YYYY-MM-DDTHH:mm"
                            )
                          : ""
                      }
                      onChange={(e) =>
                        setCurrentEvent({
                          ...currentEvent,
                          start: new Date(e.target.value),
                        })
                      }
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>End</Form.Label>
                    <Form.Control
                      type="datetime-local"
                      value={
                        currentEvent
                          ? moment(currentEvent.end).format("YYYY-MM-DDTHH:mm")
                          : ""
                      }
                      onChange={(e) =>
                        setCurrentEvent({
                          ...currentEvent,
                          end: new Date(e.target.value),
                        })
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group className="mb-3">
                <Form.Label>Color</Form.Label>
                <Form.Control
                  type="color"
                  value={currentEvent ? currentEvent.background : "#061151"}
                  onChange={(e) =>
                    setCurrentEvent({
                      ...currentEvent,
                      background: e.target.value,
                    })
                  }
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Cancel
            </Button>
            {currentEvent && currentEvent._id && (
              <Button variant="danger" onClick={handleDeleteEvent}>
                <FaTrash className="me-2" /> Delete
              </Button>
            )}
            <Button
              variant="primary"
              onClick={handleSaveEvent}
              style={{ background: "#061151", borderColor: "#061151" }}>
              <FaEdit className="me-2" />{" "}
              {currentEvent && currentEvent._id ? "Update" : "Create"}
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </div>
  );
};

export default EventManagement;

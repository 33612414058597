import React, { useState } from "react";
import { Scanner } from "@yudiel/react-qr-scanner";
import axios from "axios";
import {
  Container,
  Card,
  Button,
  Alert,
  ListGroup,
  ButtonGroup,
} from "react-bootstrap";

const MealTicketScanner = () => {
  const [scanning, setScanning] = useState(false);
  const [scanResult, setScanResult] = useState(null);
  const [error, setError] = useState(null);
  const [scannedMeals, setScannedMeals] = useState([]);
  const [selectedMealType, setSelectedMealType] = useState("lunch");

  const handleScan = async (detectedCodes) => {
    if (!detectedCodes?.length) return;

    const result = detectedCodes[0];
    try {
      const response = await axios.post(
        "https://yaei-backend-n63o.onrender.com/api/meal-tickets/verify",
        {
          qrCode: result.rawValue,
          mealType: selectedMealType,
        }
      );

      if (response.data.success) {
        const { email, phoneNumber, verifiedAt, mealType, mealsRemaining } =
          response.data.ticketDetails;
        setScanResult(
          `${
            mealType.charAt(0).toUpperCase() + mealType.slice(1)
          } verified for ${email} (${mealsRemaining} meal${
            mealsRemaining !== 1 ? "s" : ""
          } remaining)`
        );
        setScannedMeals((prev) => [
          {
            email,
            phoneNumber,
            mealType,
            mealsRemaining,
            verifiedAt: new Date(verifiedAt).toLocaleString(),
          },
          ...prev,
        ]);
      }
    } catch (err) {
      console.error("Error verifying meal ticket:", err);
      setError(err.response?.data?.message || "Failed to verify meal ticket");
    }
    setScanning(false);
  };

  const handleError = (err) => {
    console.error(err);
    setError("Error accessing camera. Please check permissions.");
    setScanning(false);
  };

  return (
    <Container className="mt-4">
      <h2 style={{ color: "#061151", fontWeight: "700" }}>
        Meal Ticket Scanner
      </h2>

      <Card className="mb-4" style={{ border: "2px solid #061151" }}>
        <Card.Body>
          <div className="mb-3">
            <ButtonGroup>
              <Button
                variant={
                  selectedMealType === "lunch" ? "primary" : "outline-primary"
                }
                onClick={() => setSelectedMealType("lunch")}
                style={{
                  background:
                    selectedMealType === "lunch" ? "#061151" : "white",
                  borderColor: "#061151",
                  color: selectedMealType === "lunch" ? "white" : "#061151",
                }}>
                Lunch
              </Button>
              <Button
                variant={
                  selectedMealType === "breakfast"
                    ? "primary"
                    : "outline-primary"
                }
                onClick={() => setSelectedMealType("breakfast")}
                style={{
                  background:
                    selectedMealType === "breakfast" ? "#061151" : "white",
                  borderColor: "#061151",
                  color: selectedMealType === "breakfast" ? "white" : "#061151",
                }}>
                Breakfast
              </Button>
            </ButtonGroup>
          </div>

          {scanning ? (
            <Scanner
              onScan={handleScan}
              onError={handleError}
              style={{ width: "100%" }}
            />
          ) : (
            <Button
              onClick={() => setScanning(true)}
              style={{
                background: "#061151",
                borderColor: "#061151",
              }}>
              Start Scanning for {selectedMealType}
            </Button>
          )}

          {error && (
            <Alert variant="danger" className="mt-3">
              {error}
            </Alert>
          )}

          {scanResult && (
            <Alert variant="success" className="mt-3">
              {scanResult}
            </Alert>
          )}
        </Card.Body>
      </Card>

      <Card style={{ border: "2px solid #061151" }}>
        <Card.Header style={{ background: "#061151", color: "white" }}>
          Scanned Meal Tickets
        </Card.Header>
        <ListGroup variant="flush">
          {scannedMeals.map((meal, index) => (
            <ListGroup.Item
              key={index}
              style={{
                borderLeft: `4px solid ${
                  meal.mealType === "lunch" ? "#bf9731" : "#2c5282"
                }`,
                margin: "8px",
                borderRadius: "4px",
              }}>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <strong>{meal.email}</strong>
                  <br />
                  <small className="text-muted">
                    Phone: {meal.phoneNumber}
                  </small>
                  <br />
                  <small
                    style={{
                      color: meal.mealType === "lunch" ? "#bf9731" : "#2c5282",
                    }}>
                    {meal.mealType.charAt(0).toUpperCase() +
                      meal.mealType.slice(1)}
                    {" • "}
                    {meal.mealsRemaining} meal
                    {meal.mealsRemaining !== 1 ? "s" : ""} remaining
                  </small>
                </div>
                <small className="text-muted">{meal.verifiedAt}</small>
              </div>
            </ListGroup.Item>
          ))}
          {!scannedMeals.length && (
            <ListGroup.Item className="text-center text-muted">
              No meals scanned yet
            </ListGroup.Item>
          )}
        </ListGroup>
      </Card>
    </Container>
  );
};

export default MealTicketScanner;

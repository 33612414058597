import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button, Card } from "react-bootstrap";
import {
  FaGraduationCap,
  FaBullseye,
  FaChartLine,
  FaBrain,
  FaSmile,
  FaBalanceScale,
  FaHandshake,
  FaChartBar,
  FaMoneyBillWave,
  FaRocket,
  FaShieldAlt,
} from "react-icons/fa";
import axios from "axios";

const DiagnosticForm = ({ businessId, onSubmitSuccess, userId }) => {
  const [formData, setFormData] = useState({
    entrepreneurshipTraining: [],
    entrepreneurialIntent: "",
    skillsDevelopment: "",
    knowledgeAcquisition: "",
    confidence: "",
    satisfaction: "",
    resilience: "",
    networking: "",
    marketEngagement: "",
    revenueGrowth: "",
    roi: "",
    marketGrowth: "",
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setFormData((prevState) => ({
        ...prevState,
        entrepreneurshipTraining: checked
          ? [...prevState.entrepreneurshipTraining, value]
          : prevState.entrepreneurshipTraining.filter((item) => item !== value),
      }));
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://yaei-backend-n63o.onrender.com/api/diagnostics",
        { ...formData, business: businessId, user: userId }
      );
      console.log(response.data);
      onSubmitSuccess();
      // Handle success (e.g., show success message)
    } catch (error) {
      console.error(
        "Error submitting diagnostic:",
        error.response?.data || error.message
      );
      // Handle error (e.g., show error message)
    }
  };

  const renderScaleOptions = (name) => {
    return [1, 2, 3, 4, 5].map((num) => (
      <Form.Check
        style={{ color: "#bf9731" }}
        key={num}
        inline
        type="radio"
        label={num}
        name={name}
        id={`${name}-${num}`}
        value={num}
        checked={formData[name] === num.toString()}
        onChange={handleChange}
        className="custom-radio"
      />
    ));
  };

  return (
    <Container
      fluid
      className="min-vh-100 d-flex align-items-center justify-content-center"
      style={{ background: "#F0F3FA", width: "100%" }}>
      <Row className="justify-content-center w-100">
        <Col md={12} lg={12} xl={12}>
          <Card className="shadow-lg border-0 rounded-lg">
            <Card.Body className="p-5">
              <div className="text-center mb-4">
                <img
                  src="/logo.png"
                  alt="Young African Entrepreneurs Institute"
                  height="100"
                />
                <h2 className="mt-3" style={{ color: "#061151" }}>
                  Business Diagnostic
                </h2>
              </div>
              <hr style={{ color: "#bf9731" }} />
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Label style={{ color: "#061151" }}>
                    <FaGraduationCap className="me-2" />
                    Entrepreneurship Training Received
                  </Form.Label>
                  {[
                    "Financial training",
                    "Business training",
                    "Marketing",
                    "Entrepreneurship",
                    "Digital literacy",
                  ].map((type) => (
                    <Form.Check
                      key={type}
                      type="checkbox"
                      id={type}
                      label={type}
                      value={type}
                      checked={formData.entrepreneurshipTraining.includes(type)}
                      onChange={handleChange}
                      name="entrepreneurshipTraining"
                    />
                  ))}
                </Form.Group>

                <hr style={{ color: "#bf9731" }} />

                <Form.Group className="mb-3">
                  <Form.Label style={{ color: "#061151" }}>
                    <FaBullseye className="me-2" />
                    Entrepreneurial Intent (1-5)
                  </Form.Label>
                  <div>{renderScaleOptions("entrepreneurialIntent")}</div>
                </Form.Group>
                <hr style={{ color: "#bf9731" }} />

                <Form.Group className="mb-3">
                  <Form.Label style={{ color: "#061151" }}>
                    <FaChartLine className="me-2" />
                    Skills Development (1-5)
                  </Form.Label>
                  <div>{renderScaleOptions("skillsDevelopment")}</div>
                </Form.Group>
                <hr style={{ color: "#bf9731" }} />
                <Form.Group className="mb-3">
                  <Form.Label style={{ color: "#061151" }}>
                    <FaBrain className="me-2" />
                    Knowledge Acquisition (1-5)
                  </Form.Label>
                  <div>{renderScaleOptions("knowledgeAcquisition")}</div>
                </Form.Group>
                <hr style={{ color: "#bf9731" }} />
                <Form.Group className="mb-3">
                  <Form.Label style={{ color: "#061151" }}>
                    <FaSmile className="me-2" />
                    Confidence in Business Success (1-5)
                  </Form.Label>
                  <div>{renderScaleOptions("confidence")}</div>
                </Form.Group>
                <hr style={{ color: "#bf9731" }} />
                <Form.Group className="mb-3">
                  <Form.Label style={{ color: "#061151" }}>
                    <FaBalanceScale className="me-2" />
                    Satisfaction with Progress (1-5)
                  </Form.Label>
                  <div>{renderScaleOptions("satisfaction")}</div>
                </Form.Group>
                <hr style={{ color: "#bf9731" }} />
                <Form.Group className="mb-3">
                  <Form.Label style={{ color: "#061151" }}>
                    <FaShieldAlt className="me-2" />
                    Resilience (1-5)
                  </Form.Label>
                  <div>{renderScaleOptions("resilience")}</div>
                </Form.Group>
                <hr style={{ color: "#bf9731" }} />
                <Form.Group className="mb-3">
                  <Form.Label style={{ color: "#061151" }}>
                    <FaHandshake className="me-2" />
                    Networking Effectiveness (1-5)
                  </Form.Label>
                  <div>{renderScaleOptions("networking")}</div>
                </Form.Group>
                <hr style={{ color: "#bf9731" }} />
                <Form.Group className="mb-3">
                  <Form.Label style={{ color: "#061151" }}>
                    <FaChartBar className="me-2" />
                    Market Engagement (1-5)
                  </Form.Label>
                  <div>{renderScaleOptions("marketEngagement")}</div>
                </Form.Group>
                <hr style={{ color: "#bf9731" }} />
                <Form.Group className="mb-3">
                  <Form.Label style={{ color: "#061151" }}>
                    <FaMoneyBillWave className="me-2" />
                    Revenue Growth
                  </Form.Label>
                  <Form.Select
                    name="revenueGrowth"
                    value={formData.revenueGrowth}
                    onChange={handleChange}
                    style={{ color: "#bf9731" }}
                    required>
                    <option value="">Select revenue growth</option>
                    <option value="Negative growth">
                      Negative growth (-10% or lower)
                    </option>
                    <option value="Minimal growth">
                      Minimal growth (0-5%)
                    </option>
                    <option value="Moderate growth">
                      Moderate growth (6-15%)
                    </option>
                    <option value="Strong growth">
                      Strong growth (16-30%)
                    </option>
                    <option value="Exceptional growth">
                      Exceptional growth (31% or higher)
                    </option>
                  </Form.Select>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label style={{ color: "#061151" }}>
                    <FaRocket className="me-2" />
                    Return on Investment (ROI)
                  </Form.Label>
                  <Form.Select
                    name="roi"
                    value={formData.roi}
                    onChange={handleChange}
                    style={{ color: "#bf9731" }}
                    required>
                    <option value="">Select ROI range</option>
                    <option value="Negative ROI">Negative ROI</option>
                    <option value="Minimal ROI">Minimal ROI (0-5%)</option>
                    <option value="Average ROI">Average ROI (6-10%)</option>
                    <option value="Good ROI">Good ROI (11-20%)</option>
                    <option value="Excellent ROI">
                      Excellent ROI (21% or higher)
                    </option>
                  </Form.Select>
                </Form.Group>

                <Form.Group className="mb-4">
                  <Form.Label style={{ color: "#061151" }}>
                    <FaChartLine className="me-2" />
                    Market Growth/Penetration
                  </Form.Label>
                  <Form.Select
                    style={{ color: "#bf9731" }}
                    name="marketGrowth"
                    value={formData.marketGrowth}
                    onChange={handleChange}
                    required>
                    <option value="">Select market growth</option>
                    <option value="Lagging behind">
                      Lagging behind competitors
                    </option>
                    <option value="Catching up">
                      Catching up with competitors
                    </option>
                    <option value="On par">On par with competitors</option>
                    <option value="Surpassing">
                      Surpassing competitors in some areas
                    </option>
                    <option value="Leading">
                      Leading the market in innovation and growth
                    </option>
                  </Form.Select>
                </Form.Group>

                <Button
                  type="submit"
                  className="w-100"
                  style={{ background: "#bf9731", border: "none" }}>
                  Submit Diagnostic
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default DiagnosticForm;

import React from "react";
import { Navbar, Nav, Image } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { HiOutlineViewGrid, HiOutlineUserGroup } from "react-icons/hi";
import { BsArrowRight, BsCalendar2Date } from "react-icons/bs";
import { CiLogout } from "react-icons/ci";
import { LiaChalkboardTeacherSolid } from "react-icons/lia";
import { TbBuildingFactory } from "react-icons/tb";

function Sidebar() {
  const location = useLocation();
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));

  const isLinkActive = (path) => location.pathname === path;

  const logout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    navigate("/signin");
  };

  const renderAdminLinks = () => (
    <>
      <Nav.Link
        as={Link}
        to="/admin/dashboard"
        style={{
          color: isLinkActive("/admin/dashboard") ? "#FFF" : "#061151",
          background: isLinkActive("/admin/dashboard")
            ? "linear-gradient(to right, #bf9731, #F0F3FA)"
            : "transparent",
          borderRadius: "10px",
        }}>
        <HiOutlineViewGrid
          size={isLinkActive("/admin/dashboard") ? 20 : 30}
          style={{
            fill: isLinkActive("/admin/dashboard") ? "#FFF" : "transparent",
          }}
        />
        <span style={{ marginLeft: "1rem", marginRight: "0.5rem" }}>
          Overview
        </span>
        {isLinkActive("/admin/dashboard") && <BsArrowRight size={15} />}
      </Nav.Link>
      <Nav.Link
        as={Link}
        to="/admin/entrepreneurs"
        style={{
          color: isLinkActive("/admin/entrepreneurs") ? "#FFF" : "#061151",
          background: isLinkActive("/admin/entrepreneurs")
            ? "linear-gradient(to right, #bf9731, #F0F3FA)"
            : "transparent",
          borderRadius: "10px",
        }}>
        <HiOutlineUserGroup
          size={isLinkActive("/admin/entrepreneurs") ? 20 : 30}
          style={{
            fill: isLinkActive("/admin/entrepreneurs") ? "#FFF" : "",
          }}
        />
        <span style={{ marginLeft: "0.5rem", marginRight: "0.5rem" }}>
          Entrepreneurs
        </span>
        {isLinkActive("/admin/entrepreneurs") && <BsArrowRight size={15} />}
      </Nav.Link>
      <Nav.Link
        as={Link}
        to="/admin/businesses"
        style={{
          color: isLinkActive("/admin/businesses") ? "#FFF" : "#061151",
          background: isLinkActive("/admin/businesses")
            ? "linear-gradient(to right, #bf9731, #F0F3FA)"
            : "transparent",
          borderRadius: "10px",
        }}>
        <TbBuildingFactory
          size={isLinkActive("/admin/businesses") ? 20 : 30}
          style={{
            fill: isLinkActive("/admin/businesses") ? "#FFF" : "",
          }}
        />
        <span style={{ marginLeft: "0.5rem", marginRight: "0.5rem" }}>
          Businesses
        </span>
        {isLinkActive("/admin/businesses") && <BsArrowRight size={15} />}
      </Nav.Link>
      <Nav.Link
        as={Link}
        to="/admin/events"
        style={{
          color: isLinkActive("/admin/events") ? "#FFF" : "#061151",
          background: isLinkActive("/admin/events")
            ? "linear-gradient(to right, #bf9731, #F0F3FA)"
            : "transparent",
          borderRadius: "10px",
        }}>
        <BsCalendar2Date
          size={isLinkActive("/admin/events") ? 15 : 20}
          style={{
            fill: isLinkActive("/admin/events") ? "#FFF" : "",
          }}
        />
        <span style={{ marginLeft: "0.5rem", marginRight: "0.5rem" }}>
          Events
        </span>
        {isLinkActive("/admin/events") && <BsArrowRight size={15} />}
      </Nav.Link>
      <Nav.Link
        as={Link}
        to="/admin/masterclasses"
        style={{
          color: isLinkActive("/admin/masterclasses") ? "#FFF" : "#061151",
          background: isLinkActive("/admin/masterclasses")
            ? "linear-gradient(to right, #bf9731, #F0F3FA)"
            : "transparent",
          borderRadius: "10px",
        }}>
        <LiaChalkboardTeacherSolid
          size={isLinkActive("/admin/masterclasses") ? 15 : 20}
          style={{
            fill: isLinkActive("/admin/masterclasses") ? "#FFF" : "",
          }}
        />
        <span style={{ marginLeft: "0.5rem", marginRight: "0.5rem" }}>
          Masterclasses
        </span>
        {isLinkActive("/admin/masterclasses") && <BsArrowRight size={15} />}
      </Nav.Link>
    </>
  );

  const renderEntrepreneurLinks = () => (
    <>
      <Nav.Link
        as={Link}
        to="/dashboard"
        style={{
          color: isLinkActive("/dashboard") ? "#FFF" : "#061151",
          background: isLinkActive("/dashboard")
            ? "linear-gradient(to right, #bf9731, #F0F3FA)"
            : "transparent",
          borderRadius: "10px",
        }}>
        <HiOutlineViewGrid
          size={isLinkActive("/dashboard") ? 20 : 30}
          style={{
            fill: isLinkActive("/dashboard") ? "#FFF" : "transparent",
          }}
        />
        <span style={{ marginLeft: "1rem", marginRight: "0.5rem" }}>
          Dashboard
        </span>
        {isLinkActive("/dashboard") && <BsArrowRight size={15} />}
      </Nav.Link>
      <Nav.Link
        as={Link}
        to="/businesses"
        style={{
          color: isLinkActive("/businesses") ? "#FFF" : "#061151",
          background: isLinkActive("/businesses")
            ? "linear-gradient(to right, #bf9731, #F0F3FA)"
            : "transparent",
          borderRadius: "10px",
        }}>
        <TbBuildingFactory
          size={isLinkActive("/businesses") ? 20 : 30}
          style={{
            fill: isLinkActive("/businesses") ? "#FFF" : "",
          }}
        />
        <span style={{ marginLeft: "0.5rem", marginRight: "0.5rem" }}>
          My businesses
        </span>
        {isLinkActive("/businesses") && <BsArrowRight size={15} />}
      </Nav.Link>
      <Nav.Link
        as={Link}
        to="/events"
        style={{
          color: isLinkActive("/events") ? "#FFF" : "#061151",
          background: isLinkActive("/events")
            ? "linear-gradient(to right, #bf9731, #F0F3FA)"
            : "transparent",
          borderRadius: "10px",
        }}>
        <BsCalendar2Date
          size={isLinkActive("/events") ? 15 : 20}
          style={{
            fill: isLinkActive("/events") ? "#FFF" : "",
          }}
        />
        <span style={{ marginLeft: "0.5rem", marginRight: "0.5rem" }}>
          Events
        </span>
        {isLinkActive("/events") && <BsArrowRight size={15} />}
      </Nav.Link>
      <Nav.Link
        as={Link}
        to="/masterclasses"
        style={{
          color: isLinkActive("/masterclasses") ? "#FFF" : "#061151",
          background: isLinkActive("/masterclasses")
            ? "linear-gradient(to right, #bf9731, #F0F3FA)"
            : "transparent",
          borderRadius: "10px",
        }}>
        <LiaChalkboardTeacherSolid
          size={isLinkActive("/masterclasses") ? 15 : 20}
          style={{
            fill: isLinkActive("/masterclasses") ? "#FFF" : "",
          }}
        />
        <span style={{ marginLeft: "0.5rem", marginRight: "0.5rem" }}>
          Masterclasses
        </span>
        {isLinkActive("/masterclasses") && <BsArrowRight size={15} />}
      </Nav.Link>
    </>
  );

  return (
    <Navbar
      expand="lg"
      variant="dark"
      className="flex-column d-none d-lg-flex"
      style={{
        width: "20rem",
        background: "#F0F3FA",
        display: "flex",
        alignContent: "start",
        alignItems: "start",
        paddingLeft: "2rem",
        borderRight: "1px solid #dee2e6",
        height: "100vh",
      }}>
      <Navbar.Brand
        as={Link}
        to={user?.role === "admin" ? "/admin/dashboard" : "/dashboard"}>
        <Image src="/logo.png" alt="Logo" width="150px" />
      </Navbar.Brand>

      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="flex-column gap-3" style={{ marginTop: "2rem" }}>
          {user?.role === "admin"
            ? renderAdminLinks()
            : renderEntrepreneurLinks()}
        </Nav>
      </Navbar.Collapse>

      <div
        style={{
          color: "#bf9731",
          paddingBottom: "0.5rem",
          cursor: "pointer",
          marginTop: "auto",
        }}
        onClick={logout}>
        <CiLogout size={30} style={{ marginRight: "0.1rem" }} />
        <span style={{ color: "#bf9731" }}>Logout</span>
      </div>

      <div className="mb-5">
        <div style={{ color: "#061151", textAlign: "center" }}>
          YAEI Connect
        </div>
      </div>
    </Navbar>
  );
}

export default Sidebar;

import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Card,
  Alert,
  Spinner,
} from "react-bootstrap";
import { FaEnvelope, FaLock } from "react-icons/fa";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";

const SignIn = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);
    try {
      const response = await axios.post(
        "https://yaei-backend-n63o.onrender.com/api/users/login",
        formData
      );
      localStorage.setItem("user", JSON.stringify(response.data.user));
      localStorage.setItem("token", response.data.token);
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${response.data.token}`;
      console.log("Sign-in successful");
      // Redirect based on user role
      if (response.data.user.role === "admin") {
        navigate("/admin/dashboard");
      } else if (response.data.user.role === "entrepreneur") {
        navigate("/businesses");
      } else {
        setError("Unknown user role");
      }
    } catch (error) {
      console.error(
        "Error signing in:",
        error.response?.data?.message || error.message
      );
      setError("Incorrect Username or Password");
    }

    setLoading(false);
  };

  return (
    <Container
      fluid
      className="min-vh-100 d-flex align-items-center justify-content-center"
      style={{ backgroundColor: "#F0F3FA" }}>
      <Row className="justify-content-center w-100">
        <Col md={8} lg={6} xl={4}>
          <Card
            className="shadow-lg border-0 rounded-lg"
            style={{ color: "#00001F" }}>
            <Card.Body className="p-5">
              <div className="text-center mb-4">
                <img
                  src="/logo.png"
                  alt="Young African Entrepreneurs Institute"
                  height="150"
                />
              </div>
              <h2 className="text-center mb-4">Sign In</h2>
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <FaEnvelope className="me-2" />
                    Email Address
                  </Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-4">
                  <Form.Label>
                    <FaLock className="me-2" />
                    Password
                  </Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                {error && (
                  <Alert variant="danger" className="mt-3 mb-3">
                    {error}
                  </Alert>
                )}
                <Button
                  type="submit"
                  className="w-100"
                  style={{
                    background: "#bf9731",
                    border: "none",
                    transition: "background-color 0.3s ease",
                  }}
                  disabled={loading}
                  onMouseEnter={(e) =>
                    (e.target.style.backgroundColor = "#00021D")
                  }
                  onMouseLeave={(e) =>
                    (e.target.style.backgroundColor = "#bf9731")
                  }>
                  {loading ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="me-2"
                      />
                      Signing In...
                    </>
                  ) : (
                    "Sign In"
                  )}
                </Button>
              </Form>
              <div className="text-center mt-3">
                <p>
                  Don't have an account?{" "}
                  <Link
                    to="/signup"
                    style={{
                      color: "#bf9731",
                      transition: "color 0.3s ease",
                    }}
                    onMouseEnter={(e) => (e.target.style.color = "#00021D")}
                    onMouseLeave={(e) => (e.target.style.color = "#bf9731")}>
                    Sign up
                  </Link>
                </p>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default SignIn;

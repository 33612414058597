import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Card,
  Alert,
} from "react-bootstrap";
import { FaUser, FaEnvelope, FaLock } from "react-icons/fa";
import { LuShield } from "react-icons/lu";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

const AdminSignup = () => {
  const [accessStep, setAccessStep] = useState("protection");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    accessCode: "",
  });
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // This should be replaced with a secure backend validation
  const ADMIN_ACCESS_CODE = "YAEI-ADMIN-2024-1999-05-31";

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setError("");
  };

  const validateAccessCode = (e) => {
    e.preventDefault();
    if (formData.accessCode === ADMIN_ACCESS_CODE) {
      setAccessStep("signup");
      setError("");
    } else {
      setError("Invalid access code");
    }
  };

  const validateForm = () => {
    const errors = {};

    if (!formData.name.trim()) {
      errors.name = "Name is required";
    }

    if (!formData.email.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email is invalid";
    }

    if (!formData.password) {
      errors.password = "Password is required";
    } else if (formData.password.length < 8) {
      errors.password = "Password must be at least 8 characters long";
    } else if (!/(?=.*[0-9])(?=.*[!@#$%^&*])/.test(formData.password)) {
      errors.password =
        "Password must contain at least one number and one special character";
    }

    if (formData.password !== formData.confirmPassword) {
      errors.confirmPassword = "Passwords do not match";
    }

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = validateForm();

    if (Object.keys(formErrors).length > 0) {
      setError(formErrors);
      return;
    }

    setLoading(true);
    try {
      const { confirmPassword, accessCode, ...dataToSend } = formData;

      const response = await axios.post(
        "https://yaei-backend-n63o.onrender.com/api/users/register",
        {
          ...dataToSend,
          role: "admin",
        }
      );

      navigate("/");
    } catch (error) {
      console.error(
        "Error registering admin:",
        error.response?.data?.message || error.message
      );
      setError({
        general:
          error.response?.data?.message ||
          "An error occurred during registration",
      });
    }
    setLoading(false);
  };

  return (
    <Container
      fluid
      className="min-vh-100 d-flex align-items-center justify-content-center"
      style={{ backgroundColor: "#F0F3FA" }}>
      <Row className="justify-content-center w-100">
        <Col md={8} lg={6} xl={5}>
          <Card
            className="shadow-lg border-0 rounded-lg"
            style={{ color: "#00001F" }}>
            <Card.Body className="p-5">
              <div className="text-center mb-4">
                <img
                  src="/logo.png"
                  alt="Young African Entrepreneurs Institute"
                  height="150"
                />
                <h2 className="mt-3" style={{ color: "#061151" }}>
                  YAEI Admin Registration
                </h2>
              </div>

              {error.general && <Alert variant="danger">{error.general}</Alert>}

              {accessStep === "protection" ? (
                <Form onSubmit={validateAccessCode}>
                  <Form.Group className="mb-3">
                    <Form.Label style={{ color: "#061151" }}>
                      <LuShield className="me-2" />
                      Admin Access Code
                    </Form.Label>
                    <Form.Control
                      type="password"
                      name="accessCode"
                      value={formData.accessCode}
                      onChange={handleChange}
                      isInvalid={!!error.accessCode}
                      placeholder="Enter admin access code"
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      {error}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Button
                    type="submit"
                    className="w-100"
                    style={{ background: "#bf9731", border: "none" }}>
                    Verify Access
                  </Button>
                </Form>
              ) : (
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mb-3">
                    <Form.Label style={{ color: "#061151" }}>
                      <FaUser className="me-2" />
                      Full Name
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      isInvalid={!!error.name}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      {error.name}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label style={{ color: "#061151" }}>
                      <FaEnvelope className="me-2" />
                      Email Address
                    </Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      isInvalid={!!error.email}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      {error.email}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label style={{ color: "#061151" }}>
                      <FaLock className="me-2" />
                      Password
                    </Form.Label>
                    <Form.Control
                      type="password"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                      isInvalid={!!error.password}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      {error.password}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="mb-4">
                    <Form.Label style={{ color: "#061151" }}>
                      <FaLock className="me-2" />
                      Confirm Password
                    </Form.Label>
                    <Form.Control
                      type="password"
                      name="confirmPassword"
                      value={formData.confirmPassword}
                      onChange={handleChange}
                      isInvalid={!!error.confirmPassword}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      {error.confirmPassword}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Button
                    type="submit"
                    className="w-100"
                    style={{ background: "#bf9731", border: "none" }}
                    disabled={loading}>
                    {loading ? "Registering..." : "Register as Admin"}
                  </Button>
                </Form>
              )}

              <div className="text-center mt-3">
                <p>
                  Already have an account?{" "}
                  <Link to="/signin" style={{ color: "#bf9731" }}>
                    Sign In
                  </Link>
                </p>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default AdminSignup;

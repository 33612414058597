import React from "react";
import { Container, Alert } from "react-bootstrap";

const Unauthorized = () => {
  return (
    <Container className="mt-5">
      <Alert variant="danger">
        <Alert.Heading>Unauthorized Access</Alert.Heading>
        <p>You do not have permission to access this page.</p>
      </Alert>
    </Container>
  );
};

export default Unauthorized;

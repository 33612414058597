import React, { useState, useRef } from "react";
import { Card, Form, Button, Alert } from "react-bootstrap";
import { QRCodeSVG } from "qrcode.react";
import axios from "axios";
import html2canvas from "html2canvas";

const MealTicketGenerator = () => {
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [ticket, setTicket] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const ticketRef = useRef();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);
    try {
      const response = await axios.post(
        "https://yaei-backend-n63o.onrender.com/api/meal-tickets/generate",
        {
          email,
          phoneNumber,
        }
      );
      if (response.data.success) {
        setTicket(response.data.ticket);
      }
    } catch (err) {
      setError(err.response?.data?.message || "Error generating ticket");
    } finally {
      setLoading(false);
    }
  };

  const downloadTicket = () => {
    const ticketElement = ticketRef.current;
    html2canvas(ticketElement).then((canvas) => {
      const link = document.createElement("a");
      link.download = `meal-ticket-${email}.png`;
      link.href = canvas.toDataURL();
      link.click();
    });
  };

  return (
    <div className="d-flex justify-content-center mt-5">
      <Card
        className="p-4"
        style={{
          width: "400px",
          background: "#FFF",
          border: "2px solid #061151",
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
          borderRadius: "16px",
        }}>
        <Card.Body>
          {error && (
            <Alert variant="danger" className="mb-4">
              {error}
            </Alert>
          )}

          {!ticket ? (
            <Form onSubmit={handleSubmit}>
              <h3
                className="text-center mb-4"
                style={{ color: "#061151", fontWeight: "700" }}>
                Meal Ticket Generator
              </h3>
              <Form.Group className="mb-3">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="tel"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  required
                />
              </Form.Group>
              <Button
                type="submit"
                disabled={loading}
                className="w-100"
                style={{
                  background: "#061151",
                  borderColor: "#061151",
                  transition: "all 0.3s ease",
                }}>
                {loading ? "Generating..." : "Generate Ticket"}
              </Button>
            </Form>
          ) : (
            <div ref={ticketRef} className="text-center">
              <h3 style={{ color: "#061151", fontWeight: "700" }}>
                Your Meal Ticket
              </h3>
              <div className="mb-4 mt-4">
                <QRCodeSVG value={ticket.qrCode} size={200} level="H" />
              </div>
              <div className="mb-3" style={{ color: "#333" }}>
                <p className="mb-1">Email: {ticket.email}</p>
                <p className="mb-3">Phone: {ticket.phoneNumber}</p>
                <div
                  style={{
                    border: "1px solid #bf9731",
                    borderRadius: "8px",
                    padding: "10px",
                    marginTop: "15px",
                    marginBottom: "20px",
                  }}>
                  <p style={{ color: "#bf9731", fontWeight: "600", margin: 0 }}>
                    Includes: 2 Meals
                  </p>
                  <small style={{ color: "#666" }}>
                    Valid for Breakfast and lunch
                  </small>
                </div>
              </div>
              <Button
                onClick={downloadTicket}
                className="w-100 mb-3"
                style={{
                  background: "#061151",
                  borderColor: "#061151",
                }}>
                Download Ticket
              </Button>
            </div>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default MealTicketGenerator;

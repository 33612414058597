import React from "react";
import { Modal, Button } from "react-bootstrap";
import DiagnosticForm from "../screens/User/DiagnosticForm";

const DiagnosticFormModal = ({ show, onHide, businessId, userId }) => {
  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title style={{ color: "#061151" }}>
          Business Diagnostic
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <DiagnosticForm
          businessId={businessId}
          onSubmitSuccess={onHide}
          userId={userId}
        />
      </Modal.Body>
    </Modal>
  );
};

export default DiagnosticFormModal;

import React from "react";
import { Table, Button } from "react-bootstrap";

const MasterclassTable = ({ courses, onView }) => {
  return (
    <Table hover>
      <thead>
        <tr>
          <th style={TableStyle}>Course Name</th>
          <th style={TableStyle}>Start Date</th>
          <th style={TableStyle}>End Date</th>
          <th style={TableStyle}>Actions</th>
        </tr>
      </thead>
      <tbody>
        {courses.map((course) => (
          <tr key={course.id}>
            <td style={TableStyle}>{course.fullname}</td>
            <td style={TableStyle}>
              {new Date(course.startdate * 1000).toLocaleDateString()}
            </td>
            <td style={TableStyle}>
              {new Date(course.enddate * 1000).toLocaleDateString()}
            </td>
            <td style={TableStyle}>
              <Button
                style={{ background: "#bf9731", border: "none" }}
                onClick={() => onView(course)}>
                View
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default MasterclassTable;
const TableStyle = { background: "#F0F3FA" };

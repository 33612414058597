import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { TbBuildingFactory } from "react-icons/tb";
import { BsCalendar2Date } from "react-icons/bs";
import { LiaChalkboardTeacherSolid } from "react-icons/lia";
import { FaTasks } from "react-icons/fa";
import Sidebar from "../../components/SideBar";
import CalendarComponent from "../../components/calender";
import axios from "axios";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
} from "chart.js";
import { Pie, Radar } from "react-chartjs-2";
import Profile from "../../components/userProfile";
import TopNavBar from "../../components/topNavBar";
import { useNavigate } from "react-router-dom";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler
);

const MetricCard = ({ title, value, icon: Icon }) => (
  <Card style={{ background: "#F0F3FA" }}>
    <Card.Body className="d-flex justify-content-between align-items-center">
      <div>
        <div style={{ color: "#061151" }}>{title}</div>
        <h3 style={{ color: "#061151" }}>{value}</h3>
      </div>
      <Icon size={30} color="#bf9731" />
    </Card.Body>
  </Card>
);

const CalendarSection = ({ events }) => (
  <div
    style={{
      background: "#F0F3FA",
      padding: "1rem",
      borderRadius: "5px",
      paddingBottom: "2rem",
    }}>
    <div style={{ color: "#061151", marginBottom: "1rem" }}>
      Upcoming Events and Masterclasses
    </div>
    <hr />
    <CalendarComponent events={events} />
  </div>
);

const MainDashboard = ({
  user,
  businesses,
  diagnostics,
  masterclasses,
  events,
}) => {
  const userBusinesses = businesses.filter(
    (business) => business.owner === user.id
  );
  const userDiagnostics = diagnostics.filter(
    (diagnostic) => diagnostic.user === user.id
  );
  const userMasterclasses = masterclasses.filter((masterclass) =>
    masterclass.enrolledUsers.includes(user.id)
  );
  const userEvents = events.filter((event) =>
    event.attendees.includes(user.id)
  );

  // Business Maturity Overview
  const businessMaturityData = {
    labels: ["Ideation", "Validation", "Creation", "Scaling"],
    datasets: [
      {
        data: [
          userBusinesses.filter((b) => b.maturityLevel === "Ideation").length,
          userBusinesses.filter((b) => b.maturityLevel === "Validation").length,
          userBusinesses.filter((b) => b.maturityLevel === "Creation").length,
          userBusinesses.filter((b) => b.maturityLevel === "Scaling").length,
        ],
        backgroundColor: ["#bf9731", "#061151", "#737373", "#D9D9D9"],
        hoverBackgroundColor: ["#bf9731", "#061151", "#737373", "#D9D9D9"],
      },
    ],
  };

  // Entrepreneurial Diagnostics Average Score
  let averageDiagnostic = null;
  if (userDiagnostics.length > 0) {
    const totalDiagnostics = userDiagnostics.length;
    const sumDiagnostic = userDiagnostics.reduce(
      (sum, diagnostic) => ({
        entrepreneurialIntent:
          sum.entrepreneurialIntent + diagnostic.entrepreneurialIntent,
        skillsDevelopment: sum.skillsDevelopment + diagnostic.skillsDevelopment,
        knowledgeAcquisition:
          sum.knowledgeAcquisition + diagnostic.knowledgeAcquisition,
        confidence: sum.confidence + diagnostic.confidence,
        satisfaction: sum.satisfaction + diagnostic.satisfaction,
        resilience: sum.resilience + diagnostic.resilience,
        networking: sum.networking + diagnostic.networking,
        marketEngagement: sum.marketEngagement + diagnostic.marketEngagement,
      }),
      {
        entrepreneurialIntent: 0,
        skillsDevelopment: 0,
        knowledgeAcquisition: 0,
        confidence: 0,
        satisfaction: 0,
        resilience: 0,
        networking: 0,
        marketEngagement: 0,
      }
    );

    averageDiagnostic = {
      entrepreneurialIntent:
        sumDiagnostic.entrepreneurialIntent / totalDiagnostics,
      skillsDevelopment: sumDiagnostic.skillsDevelopment / totalDiagnostics,
      knowledgeAcquisition:
        sumDiagnostic.knowledgeAcquisition / totalDiagnostics,
      confidence: sumDiagnostic.confidence / totalDiagnostics,
      satisfaction: sumDiagnostic.satisfaction / totalDiagnostics,
      resilience: sumDiagnostic.resilience / totalDiagnostics,
      networking: sumDiagnostic.networking / totalDiagnostics,
      marketEngagement: sumDiagnostic.marketEngagement / totalDiagnostics,
    };
  }

  const skillsData = averageDiagnostic
    ? {
        labels: [
          "Entrepreneurial Intent",
          "Skills Development",
          "Knowledge Acquisition",
          "Confidence",
          "Satisfaction",
          "Resilience",
          "Networking",
          "Market Engagement",
        ],
        datasets: [
          {
            label: "Average Diagnostics Score",
            data: [
              averageDiagnostic.entrepreneurialIntent,
              averageDiagnostic.skillsDevelopment,
              averageDiagnostic.knowledgeAcquisition,
              averageDiagnostic.confidence,
              averageDiagnostic.satisfaction,
              averageDiagnostic.resilience,
              averageDiagnostic.networking,
              averageDiagnostic.marketEngagement,
            ],
            backgroundColor: "rgba(6, 17, 81, 0.2)",
            borderColor: "#061151",
            pointBackgroundColor: "#061151",
          },
        ],
      }
    : null;

  const pieOptions = {
    responsive: true,
    maintainAspectRatio: false,
  };

  const radarOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      r: {
        angleLines: {
          display: false,
        },
        suggestedMin: 0,
        suggestedMax: 5,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  // Combine user's events and masterclasses into calendar events
  const calendarEvents = [
    ...userMasterclasses.map((mc) => ({
      title: mc.title,
      start: new Date(mc.startDate),
      end: new Date(mc.endDate),
      background: mc.background || "#3174ad",
    })),
    ...userEvents.map((event) => ({
      title: event.title,
      start: new Date(event.start),
      end: new Date(event.end),
      background: event.background || "#bf9731",
    })),
  ];

  return (
    <Container fluid className="p-4">
      <TopNavBar />
      <Row className="mb-4">
        <div className="d-flex flex-row justify-content-between">
          <h4
            style={{ fontSize: "23px", fontWeight: "500", color: "#061151" }}
            className="mb-4">
            Dashboard
          </h4>
          <Profile />
        </div>
      </Row>

      <Row>
        <Col md={3} sm={6} xs={6} className="mb-3">
          <MetricCard
            title="Businesses"
            value={userBusinesses.length}
            icon={TbBuildingFactory}
          />
        </Col>

        <Col md={3} sm={6} xs={6} className="mb-3">
          <MetricCard
            title="Masterclasses"
            value={userMasterclasses.length}
            icon={LiaChalkboardTeacherSolid}
          />
        </Col>

        <Col md={3} sm={6} xs={6} className="mb-3">
          <MetricCard
            title="Diagnostics Taken"
            value={userDiagnostics.length}
            icon={FaTasks}
          />
        </Col>
        <Col md={3} sm={6} xs={6} className="mb-3">
          <MetricCard
            title="Upcoming Events"
            value={userEvents.length}
            icon={BsCalendar2Date}
          />
        </Col>
      </Row>

      <Row>
        <Col md={8}>
          <CalendarSection events={calendarEvents} />
        </Col>
        <Col md={4}>
          <Card
            className="mb-4"
            style={{ background: "#F0F3FA", border: "none" }}>
            <Card.Body>
              <div style={{ color: "#061151", marginBottom: "1rem" }}>
                Business Maturity Overview
              </div>
              <div style={{ height: "200px" }}>
                <Pie data={businessMaturityData} options={pieOptions} />
              </div>
            </Card.Body>
          </Card>
          <Card
            className="mb-2"
            style={{
              background: "#F0F3FA",
              border: "none",
              paddingBottom: "2.1rem",
            }}>
            <Card.Body>
              <div style={{ color: "#061151", marginBottom: "" }}>
                Entrepreneurial Diagnostics Score
              </div>
              <div style={{ height: "220px" }}>
                {skillsData ? (
                  <Radar data={skillsData} options={radarOptions} />
                ) : (
                  <div>No diagnostic data available</div>
                )}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

const EntrepreneurDashboard = () => {
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("user")) || {
      id: "",
      name: "John",
      surname: "Doe",
      profileImage: "/default-profile.jpg",
    }
  );
  const [businesses, setBusinesses] = useState([]);
  const [diagnostics, setDiagnostics] = useState([]);
  const [masterclasses, setMasterclasses] = useState([]);
  const [events, setEvents] = useState([]); // Added state for events
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");

      if (!token) {
        setError("No authentication token found");
        setLoading(false);
        return;
      }

      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      try {
        const [businessesRes, diagnosticsRes, masterclassesRes, eventsRes] =
          await Promise.all([
            axios.get(
              "https://yaei-backend-n63o.onrender.com/api/businesses",
              config
            ),
            axios.get(
              "https://yaei-backend-n63o.onrender.com/api/diagnostics",
              config
            ),
            axios.get(
              "https://yaei-backend-n63o.onrender.com/api/masterclasses",
              config
            ),
            axios.get(
              "https://yaei-backend-n63o.onrender.com/api/events",
              config
            ), // Fetch events
          ]);

        setBusinesses(businessesRes.data);
        setDiagnostics(diagnosticsRes.data);
        setMasterclasses(masterclassesRes.data);
        setEvents(eventsRes.data); // Set events data
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Failed to fetch data. Please try again later.");
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading)
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}>
        Loading...
      </div>
    );
  if (error) return <div>Error: {error}</div>;

  return (
    <div
      className="d-flex"
      style={{
        minHeight: "100vh",
        maxHeight: "100vh",
        background: "#FFF",
        overflowY: "scroll",
        maxWidth: "100vw",
        overflowX: "hidden",
      }}>
      <Sidebar />
      <MainDashboard
        user={user}
        businesses={businesses}
        diagnostics={diagnostics}
        masterclasses={masterclasses}
        events={events} // Pass events to MainDashboard
      />
    </div>
  );
};

export default EntrepreneurDashboard;

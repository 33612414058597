import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Button,
  Container,
  Card,
  InputGroup,
  FormControl,
  Table,
} from "react-bootstrap";
import Sidebar from "../../components/SideBar";
import { CiSearch } from "react-icons/ci";
import EntrepreneurDetailsModal from "../../components/EntrepreneurDetailsModal";
import Profile from "../../components/userProfile";
import TopNavBar from "../../components/topNavBar";
import * as XLSX from "xlsx";

const EntrepreneurAdminView = () => {
  const [entrepreneurs, setEntrepreneurs] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedEntrepreneur, setSelectedEntrepreneur] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    fetchEntrepreneurs();
  }, []);

  const fetchEntrepreneurs = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("No authentication token found");
      }
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await axios.get(
        "https://yaei-backend-n63o.onrender.com/api/users?role=entrepreneur",
        config
      );
      const entrepreneursData = response.data;
      const entrepreneursWithBusinesses = await Promise.all(
        entrepreneursData.map(async (entrepreneur) => {
          const businessesResponse = await axios.get(
            `https://yaei-backend-n63o.onrender.com/api/businesses?owner=${entrepreneur._id}`,
            config
          );
          return { ...entrepreneur, businesses: businessesResponse.data };
        })
      );
      setEntrepreneurs(entrepreneursWithBusinesses);
      setError(null);
    } catch (err) {
      if (err.message === "No authentication token found") {
        setError("You are not authenticated. Please log in.");
      } else if (err.response && err.response.status === 401) {
        setError("Your session has expired. Please log in again.");
      } else {
        setError(
          "Error fetching entrepreneurs and businesses. Please try again later."
        );
      }
      console.error("Error in fetchEntrepreneurs:", err);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleView = (entrepreneur) => {
    setSelectedEntrepreneur(entrepreneur);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedEntrepreneur(null);
  };

  const handleDelete = async (id) => {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("No authentication token found");
    }
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      await axios.delete(
        `https://yaei-backend-n63o.onrender.com/api/users/${id}`,
        config
      );
      fetchEntrepreneurs();
    } catch (err) {
      setError("Error deleting entrepreneur. Please try again later.");
    }
  };

  const filteredEntrepreneurs = entrepreneurs.filter((entrepreneur) =>
    entrepreneur.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(
      filteredEntrepreneurs.map((entrepreneur) => ({
        Name: entrepreneur.name,
        Email: entrepreneur.email,
        Phone: entrepreneur.phone,
        Age: entrepreneur.age,
        Area: entrepreneur.area,
        Businesses: entrepreneur.businesses.length,
      }))
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Entrepreneurs");
    XLSX.writeFile(workbook, "entrepreneurs.xlsx");
  };

  return (
    <div
      className="d-flex flex-row gap-4"
      style={{
        minHeight: "100vh",
        maxHeight: "100vh",
        overflowY: "hidden",
        background: "#FFF",
      }}>
      <Sidebar />

      <Container fluid className="d-flex flex-column mt-4">
        <TopNavBar />
        <div className="d-flex flex-row justify-content-between">
          <h4
            style={{ fontSize: "23px", fontWeight: "500", color: "#061151" }}
            className="mb-4">
            Entrepreneurs
          </h4>

          <Profile />
        </div>

        <Card
          className="p-2 mt-3"
          style={{
            border: "none",
            background: "#F0F3FA",
            minHeight: "80vh",
            maxHeight: "80vh",
            overflowY: "scroll",
          }}>
          <Card.Body>
            <div className="d-flex justify-content-between mb-5 mt-3">
              <InputGroup className="mb-3" style={{ width: "40%" }}>
                <FormControl
                  placeholder="Search Entrepreneurs"
                  value={searchTerm}
                  onChange={handleSearch}
                  style={{
                    backgroundColor: "transparent",
                    paddingLeft: "40px",
                    borderColor: "#061151",
                    borderRadius: "5px",
                  }}
                />
                <InputGroup.Text
                  style={{
                    position: "absolute",
                    zIndex: 10,
                    border: "none",
                    background: "none",
                    padding: "10px 0 0 10px",
                  }}>
                  <CiSearch />
                </InputGroup.Text>
              </InputGroup>
              <Button
                onClick={downloadExcel}
                style={{ background: "#061151", border: "none" }}>
                Download Excel
              </Button>
            </div>

            {loading && <p>Loading entrepreneurs...</p>}
            {error && <p className="text-danger">{error}</p>}
            {!loading && !error && (
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th style={TableStyle}>Name</th>
                    <th style={TableStyle}>Email</th>
                    <th style={TableStyle}>Phone</th>
                    <th style={TableStyle}>Age</th>
                    <th style={TableStyle}>Area</th>
                    <th style={TableStyle}>Businesses</th>
                    <th style={TableStyle}>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredEntrepreneurs.map((entrepreneur) => (
                    <tr key={entrepreneur._id}>
                      <td style={TableStyle}>{entrepreneur.name}</td>
                      <td style={TableStyle}>{entrepreneur.email}</td>
                      <td style={TableStyle}>{entrepreneur.phone}</td>
                      <td style={TableStyle}>{entrepreneur.age}</td>
                      <td style={TableStyle}>{entrepreneur.area}</td>
                      <td style={TableStyle}>
                        {entrepreneur.businesses.length}
                      </td>
                      <td style={TableStyle}>
                        <Button
                          style={{ background: "#061151", border: "none" }}
                          size="sm"
                          onClick={() => handleView(entrepreneur)}
                          className="me-2">
                          View
                        </Button>
                        <Button
                          style={{ background: "#bf9731", border: "none" }}
                          size="sm"
                          onClick={() => handleDelete(entrepreneur._id)}>
                          Delete
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}

            <EntrepreneurDetailsModal
              show={showModal}
              handleClose={handleCloseModal}
              entrepreneur={selectedEntrepreneur}
            />
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
};

export default EntrepreneurAdminView;

const TableStyle = { background: "#F0F3FA" };

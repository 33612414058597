import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { HiOutlineViewGrid, HiOutlineUserGroup } from "react-icons/hi";
import { TbBuildingFactory } from "react-icons/tb";
import { BsArrowRight, BsCalendar2Date } from "react-icons/bs";
import { LiaChalkboardTeacherSolid } from "react-icons/lia";
import { CiLogout } from "react-icons/ci";

function TopNavBar() {
  const location = useLocation();
  const user = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();

  const isLinkActive = (path) => location.pathname === path;

  const logout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    navigate("/signin");
  };

  const linkStyle = (path) => ({
    color: isLinkActive(path) ? "#FFF" : "#061151",
    background: isLinkActive(path)
      ? "linear-gradient(to right, #bf9731, #F0F3FA)"
      : "transparent",
    borderRadius: "10px",
    padding: "0.5rem 1rem",
    display: "flex",
    alignItems: "center",
    marginBottom: "0.5rem",
  });

  const iconStyle = (path) => ({
    marginRight: "1rem",
    color: isLinkActive(path) ? "#FFF" : "#061151",
  });

  return (
    <Navbar
      collapseOnSelect
      expand="md"
      className="bg-body-tertiary d-lg-none mb-3 mx-0 p-1"
      style={{ background: "#F0F3FA" }}>
      <Container>
        <Navbar.Brand
          as={Link}
          to={user?.role === "admin" ? "/admin/dashboard" : "/dashboard"}>
          <img
            src="/logo.png"
            alt="YAEI Connect Logo"
            style={{ maxHeight: "50px", maxWidth: "auto" }}
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ml-auto" style={{ textAlign: "left", width: "100%" }}>
            <Nav.Link
              as={Link}
              to={user?.role === "admin" ? "/admin/dashboard" : "/dashboard"}
              style={linkStyle(
                user?.role === "admin" ? "/admin/dashboard" : "/dashboard"
              )}>
              <HiOutlineViewGrid
                size={20}
                style={iconStyle(
                  user?.role === "admin" ? "/admin/dashboard" : "/dashboard"
                )}
              />
              <span>{user?.role === "admin" ? "Overview" : "Dashboard"}</span>
              {isLinkActive(
                user?.role === "admin" ? "/admin/dashboard" : "/dashboard"
              ) && <BsArrowRight size={15} style={{ marginLeft: "auto" }} />}
            </Nav.Link>
            {user?.role === "admin" && (
              <Nav.Link
                as={Link}
                to="/admin/entrepreneurs"
                style={linkStyle("/admin/entrepreneurs")}>
                <HiOutlineUserGroup
                  size={20}
                  style={iconStyle("/admin/entrepreneurs")}
                />
                <span>Entrepreneurs</span>
                {isLinkActive("/admin/entrepreneurs") && (
                  <BsArrowRight size={15} style={{ marginLeft: "auto" }} />
                )}
              </Nav.Link>
            )}
            <Nav.Link
              as={Link}
              to={user?.role === "admin" ? "/admin/businesses" : "/businesses"}
              style={linkStyle(
                user?.role === "admin" ? "/admin/businesses" : "/businesses"
              )}>
              <TbBuildingFactory
                size={20}
                style={iconStyle(
                  user?.role === "admin" ? "/admin/businesses" : "/businesses"
                )}
              />
              <span>
                {user?.role === "admin" ? "Businesses" : "My businesses"}
              </span>
              {isLinkActive(
                user?.role === "admin" ? "/admin/businesses" : "/businesses"
              ) && <BsArrowRight size={15} style={{ marginLeft: "auto" }} />}
            </Nav.Link>
            <Nav.Link
              as={Link}
              to={user?.role === "admin" ? "/admin/events" : "/events"}
              style={linkStyle(
                user?.role === "admin" ? "/admin/events" : "/events"
              )}>
              <BsCalendar2Date
                size={20}
                style={iconStyle(
                  user?.role === "admin" ? "/admin/events" : "/events"
                )}
              />
              <span>Events</span>
              {isLinkActive(
                user?.role === "admin" ? "/admin/events" : "/events"
              ) && <BsArrowRight size={15} style={{ marginLeft: "auto" }} />}
            </Nav.Link>
            <Nav.Link
              as={Link}
              to={
                user?.role === "admin"
                  ? "/admin/masterclasses"
                  : "/masterclasses"
              }
              style={linkStyle(
                user?.role === "admin"
                  ? "/admin/masterclasses"
                  : "/masterclasses"
              )}>
              <LiaChalkboardTeacherSolid
                size={20}
                style={iconStyle(
                  user?.role === "admin"
                    ? "/admin/masterclasses"
                    : "/masterclasses"
                )}
              />
              <span>Masterclasses</span>
              {isLinkActive(
                user?.role === "admin"
                  ? "/admin/masterclasses"
                  : "/masterclasses"
              ) && <BsArrowRight size={15} style={{ marginLeft: "auto" }} />}
            </Nav.Link>
            <Nav.Link
              onClick={logout}
              style={{
                color: "#bf9731",
                marginTop: "1rem",
                display: "flex",
                alignItems: "center",
              }}>
              <CiLogout size={20} style={{ marginRight: "1rem" }} />
              <span>Logout</span>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default TopNavBar;

import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button, Card } from "react-bootstrap";
import axios from "axios";

const PreEvaluationForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    university: "",
    province: "",
    businessName: "",
    businessStage: "",
    masterclasses: [],
    marketResearchGoal: "",
    primaryResearchExample: "",
    mvpPurpose: "",
    rapidExperimentation: "",
    primaryResearchReliability: "",
    customerDiscovery: "",
    businessModelCanvas: "",
    businessModelComponent: "",
    valueProposition: "",
    businessModelUpdate: "",
    businessModelChange: "",
    businessModelIncludes: "",
    legalStructure: "",
    intellectualProperty: "",
    trademarkProtection: "",
    incorporationAdvantage: "",
    intellectualPropertyProtection: "",
    soleProprietorshipLiability: "",
    businessBudgetGoal: "",
    financialForecasting: "",
    equityFinancing: "",
    financialForecastingPrediction: "",
    financialPerformanceDocument: "",
    equityFinancingVsLoan: "",
    profitableBudget: "",
    profitabilityStatement: "",
    balanceSheet: "",
    incomeStatementContent: "",
    balanceSheetReveal: "",
    cashFlowStatement: "",
  });

  useEffect(() => {
    // Fetch user info from local storage if needed
    const userInfo = JSON.parse(localStorage.getItem("userInfo")) || {};
    setFormData((prevState) => ({
      ...prevState,
      name: userInfo.name || "",
      email: userInfo.email || "",
    }));
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setFormData((prevState) => ({
        ...prevState,
        [name]: checked
          ? [...prevState[name], value]
          : prevState[name].filter((item) => item !== value),
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://your-api-endpoint.com/api/pre-evaluation",
        formData
      );
      console.log(response.data);
      // Handle success (e.g., show success message, redirect)
    } catch (error) {
      console.error(
        "Error submitting pre-evaluation:",
        error.response?.data || error.message
      );
      // Handle error (e.g., show error message)
    }
  };

  return (
    <Container className="bg-gray-100 min-h-screen py-8">
      <Row className="justify-content-center">
        <Col md={8}>
          <Card className="shadow-lg">
            <Card.Body className="p-6">
              <h2 className="text-2xl font-bold text-center mb-6 text-indigo-800">
                Pre-evaluation for YESP: Sustainable Venture Practice
              </h2>
              <p className="mb-4 text-sm text-gray-600">
                The purpose of this pre-evaluation is to assess your baseline
                knowledge and understanding of key concepts. You do not have to
                get the right answers; or use other platforms to research. This
                is to help us identify knowledge gaps, allowing the team to
                tailor the content to meet your needs, while also benchmarking
                your progress by comparing pre- and post-evaluation results.
              </p>
              <Form onSubmit={handleSubmit}>
                {/* Personal Information */}
                <Form.Group className="mb-4">
                  <Form.Label>Name & Surname</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-4">
                  <Form.Label>Email Address</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-4">
                  <Form.Label>University/Community</Form.Label>
                  <Form.Control
                    type="text"
                    name="university"
                    value={formData.university}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-4">
                  <Form.Label>Province</Form.Label>
                  <Form.Select
                    name="province"
                    value={formData.province}
                    onChange={handleChange}
                    required>
                    <option value="">Select province</option>
                    <option value="Gauteng">Gauteng</option>
                    <option value="North West">North West</option>
                    <option value="Kwa-Zulu Natal">Kwa-Zulu Natal</option>
                    <option value="Limpopo">Limpopo</option>
                    <option value="Free State">Free State</option>
                    <option value="Western Cape">Western Cape</option>
                    <option value="Mpumalanga">Mpumalanga</option>
                    <option value="Northern Cape">Northern Cape</option>
                    <option value="Eastern Cape">Eastern Cape</option>
                  </Form.Select>
                </Form.Group>

                <Form.Group className="mb-4">
                  <Form.Label>Business Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="businessName"
                    value={formData.businessName}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-4">
                  <Form.Label>Stage of Business</Form.Label>
                  <Form.Select
                    name="businessStage"
                    value={formData.businessStage}
                    onChange={handleChange}
                    required>
                    <option value="">Select business stage</option>
                    <option value="Ideation">
                      Ideation: The business is still an idea and hasn't been
                      implemented
                    </option>
                    <option value="Validation">
                      Validation: The business is beyond an idea; it has been
                      piloted but still at early stages
                    </option>
                    <option value="Creation">
                      Creation: The business is in motion but still needs areas
                      to be refined and for it to scale
                    </option>
                    <option value="Scaling">
                      Scaling: The business is fully-fledged and experiencing
                      organic growth
                    </option>
                  </Form.Select>
                </Form.Group>

                <Form.Group className="mb-4">
                  <Form.Label>
                    Have you received any masterclasses on the following topics:
                  </Form.Label>
                  {[
                    "Market Research, Marketing & Product Development",
                    "Business Modelling",
                    "Business Legalities",
                    "Financial Planning",
                    "Business Reporting",
                    "None",
                  ].map((topic) => (
                    <Form.Check
                      key={topic}
                      type="checkbox"
                      label={topic}
                      name="masterclasses"
                      value={topic}
                      checked={formData.masterclasses.includes(topic)}
                      onChange={handleChange}
                    />
                  ))}
                </Form.Group>

                {/* Market Research, Marketing & Product Development Questions */}
                <h3 className="text-xl font-semibold mt-6 mb-4">
                  Market Research, Marketing & Product Development
                </h3>

                <Form.Group className="mb-4">
                  <Form.Label>
                    What is the primary goal of conducting market research?
                  </Form.Label>
                  <Form.Select
                    name="marketResearchGoal"
                    value={formData.marketResearchGoal}
                    onChange={handleChange}
                    required>
                    <option value="">Select an option</option>
                    <option value="To determine customer needs and preferences">
                      To determine customer needs and preferences
                    </option>
                    <option value="To increase company profits">
                      To increase company profits
                    </option>
                    <option value="To create more products">
                      To create more products
                    </option>
                    <option value="To improve supply chain efficiency">
                      To improve supply chain efficiency
                    </option>
                    <option value="Not sure">Not sure</option>
                  </Form.Select>
                </Form.Group>

                <Form.Group className="mb-4">
                  <Form.Label>
                    Which of the following is an example of primary research?
                  </Form.Label>
                  <Form.Select
                    name="primaryResearchExample"
                    value={formData.primaryResearchExample}
                    onChange={handleChange}
                    required>
                    <option value="">Select an option</option>
                    <option value="Reading industry reports">
                      Reading industry reports
                    </option>
                    <option value="Conducting surveys with potential customers">
                      Conducting surveys with potential customers
                    </option>
                    <option value="Using data from government databases">
                      Using data from government databases
                    </option>
                    <option value="Reviewing competitors' websites">
                      Reviewing competitors' websites
                    </option>
                    <option value="Not sure">Not sure</option>
                  </Form.Select>
                </Form.Group>

                <Form.Group className="mb-4">
                  <Form.Label>
                    What is the main purpose of a Minimum Viable Product (MVP)?
                  </Form.Label>
                  <Form.Select
                    name="mvpPurpose"
                    value={formData.mvpPurpose}
                    onChange={handleChange}
                    required>
                    <option value="">Select an option</option>
                    <option value="To launch a fully-featured product">
                      To launch a fully-featured product
                    </option>
                    <option value="To test product hypotheses with minimum effort">
                      To test product hypotheses with minimum effort
                    </option>
                    <option value="To capture the largest possible market share">
                      To capture the largest possible market share
                    </option>
                    <option value="To reduce production costs">
                      To reduce production costs
                    </option>
                    <option value="Not sure">Not sure</option>
                  </Form.Select>
                </Form.Group>

                <Form.Group className="mb-4">
                  <Form.Label>
                    In the context of lean startup methodology, what does "rapid
                    experimentation" aim to achieve?
                  </Form.Label>
                  <Form.Select
                    name="rapidExperimentation"
                    value={formData.rapidExperimentation}
                    onChange={handleChange}
                    required>
                    <option value="">Select an option</option>
                    <option value="Test product ideas quickly with minimal investment">
                      Test product ideas quickly with minimal investment
                    </option>
                    <option value="Perfect the final product before launch">
                      Perfect the final product before launch
                    </option>
                    <option value="Ensure maximum product features before going to market">
                      Ensure maximum product features before going to market
                    </option>
                    <option value="Expand the customer base quickly">
                      Expand the customer base quickly
                    </option>
                    <option value="Not sure">Not sure</option>
                  </Form.Select>
                </Form.Group>

                <Form.Group className="mb-4">
                  <Form.Label>
                    The primary research is always more reliable than secondary
                    research.
                  </Form.Label>
                  <Form.Select
                    name="primaryResearchReliability"
                    value={formData.primaryResearchReliability}
                    onChange={handleChange}
                    required>
                    <option value="">Select an option</option>
                    <option value="True">True</option>
                    <option value="False">False</option>
                  </Form.Select>
                </Form.Group>

                <Form.Group className="mb-4">
                  <Form.Label>
                    The customer discovery is used to test assumptions about the
                    target market?
                  </Form.Label>
                  <Form.Select
                    name="customerDiscovery"
                    value={formData.customerDiscovery}
                    onChange={handleChange}
                    required>
                    <option value="">Select an option</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </Form.Select>
                </Form.Group>

                {/* Business Modelling Questions */}
                <h3 className="text-xl font-semibold mt-6 mb-4">
                  Business Modelling
                </h3>

                <Form.Group className="mb-4">
                  <Form.Label>
                    What key aspect does a business model canvas help identify?
                  </Form.Label>
                  <Form.Select
                    name="businessModelCanvas"
                    value={formData.businessModelCanvas}
                    onChange={handleChange}
                    required>
                    <option value="">Select an option</option>
                    <option value="Competitor pricing strategies">
                      Competitor pricing strategies
                    </option>
                    <option value="Revenue streams and key partnerships">
                      Revenue streams and key partnerships
                    </option>
                    <option value="Employee performance metrics">
                      Employee performance metrics
                    </option>
                    <option value="Product pricing">Product pricing</option>
                    <option value="Not sure">Not sure</option>
                  </Form.Select>
                </Form.Group>

                <Form.Group className="mb-4">
                  <Form.Label>
                    Which of the following is NOT a component of the Business
                    Model Canvas?
                  </Form.Label>
                  <Form.Select
                    name="businessModelComponent"
                    value={formData.businessModelComponent}
                    onChange={handleChange}
                    required>
                    <option value="">Select an option</option>
                    <option value="Key partners">Key partners</option>
                    <option value="Value propositions">
                      Value propositions
                    </option>
                    <option value="Intellectual property">
                      Intellectual property
                    </option>
                    <option value="Customer segments">Customer segments</option>
                    <option value="Not sure">Not sure</option>
                  </Form.Select>
                </Form.Group>

                <Form.Group className="mb-4">
                  <Form.Label>
                    A "value proposition" in a business model refers to:
                  </Form.Label>
                  <Form.Select
                    name="valueProposition"
                    value={formData.valueProposition}
                    onChange={handleChange}
                    required>
                    <option value="">Select an option</option>
                    <option value="How the business makes money">
                      How the business makes money
                    </option>
                    <option value="The core benefit offered to the customer">
                      The core benefit offered to the customer
                    </option>
                    <option value="The cost structure of the business">
                      The cost structure of the business
                    </option>
                    <option value="A company's legal obligations">
                      A company's legal obligations
                    </option>
                    <option value="Not sure">Not sure</option>
                  </Form.Select>
                </Form.Group>

                <Form.Group className="mb-4">
                  <Form.Label>
                    When should a business model be updated or refined?
                  </Form.Label>
                  <Form.Select
                    name="businessModelUpdate"
                    value={formData.businessModelUpdate}
                    onChange={handleChange}
                    required>
                    <option value="">Select an option</option>
                    <option value="Only when competitors change their models">
                      Only when competitors change their models
                    </option>
                    <option value="When the business faces new market conditions or challenges">
                      When the business faces new market conditions or
                      challenges
                    </option>
                    <option value="After every product launch">
                      After every product launch
                    </option>
                    <option value="Every year regardless of business performance">
                      Every year regardless of business performance
                    </option>
                    <option value="Not sure">Not sure</option>
                  </Form.Select>
                </Form.Group>

                <Form.Group className="mb-4">
                  <Form.Label>
                    Can a business model be the same and not change?
                  </Form.Label>
                  <Form.Select
                    name="businessModelChange"
                    value={formData.businessModelChange}
                    onChange={handleChange}
                    required>
                    <option value="">Select an option</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </Form.Select>
                </Form.Group>

                <Form.Group className="mb-4">
                  <Form.Label>
                    The Business Model Canvas includes key partners, customer
                    segments, and revenue streams.
                  </Form.Label>
                  <Form.Select
                    name="businessModelIncludes"
                    value={formData.businessModelIncludes}
                    onChange={handleChange}
                    required>
                    <option value="">Select an option</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </Form.Select>
                </Form.Group>

                {/* Business Legalities Questions */}
                <h3 className="text-xl font-semibold mt-6 mb-4">
                  Business Legalities
                </h3>

                <Form.Group className="mb-4">
                  <Form.Label>
                    Which legal structure provides the most protection against
                    personal liability?
                  </Form.Label>
                  <Form.Select
                    name="legalStructure"
                    value={formData.legalStructure}
                    onChange={handleChange}
                    required>
                    <option value="">Select an option</option>
                    <option value="Sole proprietorship">
                      Sole proprietorship
                    </option>
                    <option value="General partnership">
                      General partnership
                    </option>
                    <option value="Corporation">Corporation</option>
                    <option value="Limited liability company (LLC)">
                      Limited liability company (LLC)
                    </option>
                    <option value="Not sure">Not sure</option>
                  </Form.Select>
                </Form.Group>

                <Form.Group className="mb-4">
                  <Form.Label>
                    Intellectual property (IP) rights are important because:
                  </Form.Label>
                  <Form.Select
                    name="intellectualProperty"
                    value={formData.intellectualProperty}
                    onChange={handleChange}
                    required>
                    <option value="">Select an option</option>
                    <option value="They prevent new businesses from entering the market">
                      They prevent new businesses from entering the market
                    </option>
                    <option value="They protect the creator's inventions, designs, and trademarks">
                      They protect the creator's inventions, designs, and
                      trademarks
                    </option>
                    <option value="They limit competition in the marketplace">
                      They limit competition in the marketplace
                    </option>
                    <option value="They allow businesses to reduce taxes">
                      They allow businesses to reduce taxes
                    </option>
                    <option value="Not sure">Not sure</option>
                  </Form.Select>
                </Form.Group>

                <Form.Group className="mb-4">
                  <Form.Label>What does a trademark protect?</Form.Label>
                  <Form.Select
                    name="trademarkProtection"
                    value={formData.trademarkProtection}
                    onChange={handleChange}
                    required>
                    <option value="">Select an option</option>
                    <option value="An invention or process">
                      An invention or process
                    </option>
                    <option value="A business name, logo, or symbol">
                      A business name, logo, or symbol
                    </option>
                    <option value="A company's financial statements">
                      A company's financial statements
                    </option>
                    <option value="A new product prototype">
                      A new product prototype
                    </option>
                    <option value="Not sure">Not sure</option>
                  </Form.Select>
                </Form.Group>

                <Form.Group className="mb-4">
                  <Form.Label>
                    What is the main advantage of incorporating a business?
                  </Form.Label>
                  <Form.Select
                    name="incorporationAdvantage"
                    value={formData.incorporationAdvantage}
                    onChange={handleChange}
                    required>
                    <option value="">Select an option</option>
                    <option value="Easy access to bank loans">
                      Easy access to bank loans
                    </option>
                    <option value="Ability to raise large sums of money quickly">
                      Ability to raise large sums of money quickly
                    </option>
                    <option value="Limited liability protection for owners">
                      Limited liability protection for owners
                    </option>
                    <option value="Reduced tax obligations">
                      Reduced tax obligations
                    </option>
                    <option value="Not sure">Not sure</option>
                  </Form.Select>
                </Form.Group>

                <Form.Group className="mb-4">
                  <Form.Label>
                    Does registering intellectual property protect a business
                    from all forms of competition?
                  </Form.Label>
                  <Form.Select
                    name="intellectualPropertyProtection"
                    value={formData.intellectualPropertyProtection}
                    onChange={handleChange}
                    required>
                    <option value="">Select an option</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </Form.Select>
                </Form.Group>

                <Form.Group className="mb-4">
                  <Form.Label>
                    Can a sole proprietorship be held liable for the business's
                    debts?
                  </Form.Label>
                  <Form.Select
                    name="soleProprietorshipLiability"
                    value={formData.soleProprietorshipLiability}
                    onChange={handleChange}
                    required>
                    <option value="">Select an option</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </Form.Select>
                </Form.Group>

                {/* Financial Planning Questions */}
                <h3 className="text-xl font-semibold mt-6 mb-4">
                  Financial Planning
                </h3>

                <Form.Group className="mb-4">
                  <Form.Label>
                    What is the main goal of a business budget?
                  </Form.Label>
                  <Form.Select
                    name="businessBudgetGoal"
                    value={formData.businessBudgetGoal}
                    onChange={handleChange}
                    required>
                    <option value="">Select an option</option>
                    <option value="To set pricing for products">
                      To set pricing for products
                    </option>
                    <option value="To ensure the business remains profitable">
                      To ensure the business remains profitable
                    </option>
                    <option value="To determine the market demand for products">
                      To determine the market demand for products
                    </option>
                    <option value="To allocate financial resources effectively">
                      To allocate financial resources effectively
                    </option>
                    <option value="Not sure">Not sure</option>
                  </Form.Select>
                </Form.Group>

                <Form.Group className="mb-4">
                  <Form.Label>
                    What is financial forecasting primarily used for?
                  </Form.Label>
                  <Form.Select
                    name="financialForecasting"
                    value={formData.financialForecasting}
                    onChange={handleChange}
                    required>
                    <option value="">Select an option</option>
                    <option value="To predict market demand">
                      To predict market demand
                    </option>
                    <option value="To estimate future income and expenses">
                      To estimate future income and expenses
                    </option>
                    <option value="To create a new marketing strategy">
                      To create a new marketing strategy
                    </option>
                    <option value="To find new investors">
                      To find new investors
                    </option>
                    <option value="Not sure">Not sure</option>
                  </Form.Select>
                </Form.Group>

                <Form.Group className="mb-4">
                  <Form.Label>
                    Which funding option involves giving up a portion of
                    business ownership?
                  </Form.Label>
                  <Form.Select
                    name="equityFinancing"
                    value={formData.equityFinancing}
                    onChange={handleChange}
                    required>
                    <option value="">Select an option</option>
                    <option value="Debt financing">Debt financing</option>
                    <option value="Equity financing">Equity financing</option>
                    <option value="Bootstrapping">Bootstrapping</option>
                    <option value="Crowdfunding">Crowdfunding</option>
                    <option value="Not sure">Not sure</option>
                  </Form.Select>
                </Form.Group>

                <Form.Group className="mb-4">
                  <Form.Label>
                    Does financial forecasting involve predicting future income
                    and expenses?
                  </Form.Label>
                  <Form.Select
                    name="financialForecastingPrediction"
                    value={formData.financialForecastingPrediction}
                    onChange={handleChange}
                    required>
                    <option value="">Select an option</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </Form.Select>
                </Form.Group>

                <Form.Group className="mb-4">
                  <Form.Label>
                    Which financial document forecasts a company's future
                    financial performance?
                  </Form.Label>
                  <Form.Select
                    name="financialPerformanceDocument"
                    value={formData.financialPerformanceDocument}
                    onChange={handleChange}
                    required>
                    <option value="">Select an option</option>
                    <option value="Profit and loss statement">
                      Profit and loss statement
                    </option>
                    <option value="Cash flow statement">
                      Cash flow statement
                    </option>
                    <option value="Balance sheet">Balance sheet</option>
                    <option value="Financial forecast">
                      Financial forecast
                    </option>
                    <option value="Not sure">Not sure</option>
                  </Form.Select>
                </Form.Group>

                <Form.Group className="mb-4">
                  <Form.Label>
                    Equity financing is the same as taking a business loan
                  </Form.Label>
                  <Form.Select
                    name="equityFinancingVsLoan"
                    value={formData.equityFinancingVsLoan}
                    onChange={handleChange}
                    required>
                    <option value="">Select an option</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </Form.Select>
                </Form.Group>

                <Form.Group className="mb-4">
                  <Form.Label>
                    Should a company have a budget even if it is profitable?
                  </Form.Label>
                  <Form.Select
                    name="profitableBudget"
                    value={formData.profitableBudget}
                    onChange={handleChange}
                    required>
                    <option value="">Select an option</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </Form.Select>
                </Form.Group>

                {/* Business Reporting Questions */}
                <h3 className="text-xl font-semibold mt-6 mb-4">
                  Business Reporting
                </h3>

                <Form.Group className="mb-4">
                  <Form.Label>
                    Which financial statement provides information about a
                    company's profitability?
                  </Form.Label>
                  <Form.Select
                    name="profitabilityStatement"
                    value={formData.profitabilityStatement}
                    onChange={handleChange}
                    required>
                    <option value="">Select an option</option>
                    <option value="Income statement">Income statement</option>
                    <option value="Balance sheet">Balance sheet</option>
                    <option value="Cash flow statement">
                      Cash flow statement
                    </option>
                    <option value="Equity report">Equity report</option>
                    <option value="Not sure">Not sure</option>
                  </Form.Select>
                </Form.Group>

                <Form.Group className="mb-4">
                  <Form.Label>The balance sheet shows:</Form.Label>
                  <Form.Select
                    name="balanceSheet"
                    value={formData.balanceSheet}
                    onChange={handleChange}
                    required>
                    <option value="">Select an option</option>
                    <option value="The company's assets, liabilities, and equity">
                      The company's assets, liabilities, and equity
                    </option>
                    <option value="The company's expenses and revenues">
                      The company's expenses and revenues
                    </option>
                    <option value="The company's market share">
                      The company's market share
                    </option>
                    <option value="The company's net cash flow">
                      The company's net cash flow
                    </option>
                    <option value="Not sure">Not sure</option>
                  </Form.Select>
                </Form.Group>

                <Form.Group className="mb-4">
                  <Form.Label>
                    Which of the following would you find in an income
                    statement?
                  </Form.Label>
                  <Form.Select
                    name="incomeStatementContent"
                    value={formData.incomeStatementContent}
                    onChange={handleChange}
                    required>
                    <option value="">Select an option</option>
                    <option value="A breakdown of fixed and variable costs">
                      A breakdown of fixed and variable costs
                    </option>
                    <option value="Cash balances for the month">
                      Cash balances for the month
                    </option>
                    <option value="A list of assets and liabilities">
                      A list of assets and liabilities
                    </option>
                    <option value="Sales revenue and expenses during a period">
                      Sales revenue and expenses during a period
                    </option>
                    <option value="Not sure">Not sure</option>
                  </Form.Select>
                </Form.Group>

                <Form.Group className="mb-4">
                  <Form.Label>
                    What does the balance sheet reveal about a company's
                    financial health?
                  </Form.Label>
                  <Form.Select
                    name="balanceSheetReveal"
                    value={formData.balanceSheetReveal}
                    onChange={handleChange}
                    required>
                    <option value="">Select an option</option>
                    <option value="It shows the company's performance over time">
                      It shows the company's performance over time
                    </option>
                    <option value="It reflects the company's net worth at a specific point in time">
                      It reflects the company's net worth at a specific point in
                      time
                    </option>
                    <option value="It indicates profitability trends">
                      It indicates profitability trends
                    </option>
                    <option value="It tracks daily financial transactions">
                      It tracks daily financial transactions
                    </option>
                    <option value="Not sure">Not sure</option>
                  </Form.Select>
                </Form.Group>

                <Form.Group className="mb-4">
                  <Form.Label>
                    Does the cash flow statement track all of a company's
                    expenses and revenue over a period of time?
                  </Form.Label>
                  <Form.Select
                    name="cashFlowStatement"
                    value={formData.cashFlowStatement}
                    onChange={handleChange}
                    required>
                    <option value="">Select an option</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </Form.Select>
                </Form.Group>

                <Button
                  type="submit"
                  className="w-full bg-indigo-600 text-white py-2 px-4 rounded hover:bg-indigo-700 transition duration-300 mt-6">
                  Submit Pre-evaluation
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default PreEvaluationForm;

import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Button,
  Container,
  Card,
  InputGroup,
  FormControl,
  Alert,
} from "react-bootstrap";
import Sidebar from "../../components/SideBar";
import { CiSearch } from "react-icons/ci";
import MasterclassTable from "../../components/MasterclassTable";
import MasterclassDetailsModal from "../../components/MasterclassDetailsModal";
import Profile from "../../components/userProfile";
import TopNavBar from "../../components/topNavBar";

const CourseAdminView = () => {
  const [courses, setCourses] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [syncMessage, setSyncMessage] = useState(null);

  useEffect(() => {
    fetchCourses();
  }, []);

  const fetchCourses = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        "https://yaei-backend-n63o.onrender.com/api/masterclasses"
      );
      setCourses(response.data);
      setError(null);
    } catch (err) {
      setError("Error fetching courses. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSync = async () => {
    try {
      setLoading(true);
      await axios.get(
        "https://yaei-backend-n63o.onrender.com/api/masterclasses/sync"
      );
      setSyncMessage("Courses synced successfully");
      fetchCourses();
    } catch (err) {
      setError("Error syncing courses. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handleView = (course) => {
    setSelectedCourse(course);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedCourse(null);
  };

  const filteredCourses = courses.filter((course) =>
    course.fullname.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div
      className="d-flex flex-row gap-4"
      style={{
        minHeight: "100vh",
        maxHeight: "100vh",
        overflowY: "hidden",
        background: "#FFF",
      }}>
      <Sidebar />

      <Container fluid className="d-flex flex-column mt-4">
        <TopNavBar />
        <div className="d-flex flex-row justify-content-between">
          <h4
            style={{ fontSize: "23px", fontWeight: "500", color: "#061151" }}
            className="mb-4">
            Courses
          </h4>

          <Profile />
        </div>

        <Card
          className="p-2 mt-3"
          style={{
            border: "none",
            background: "#F0F3FA",
            minHeight: "80vh",
            maxHeight: "80vh",
            overflowY: "scroll",
          }}>
          <Card.Body>
            {syncMessage && (
              <Alert
                variant="success"
                onClose={() => setSyncMessage(null)}
                dismissible>
                {syncMessage}
              </Alert>
            )}
            <div className="d-flex justify-content-between mb-5 mt-3">
              <InputGroup className="mb-3" style={{ width: "40%" }}>
                <FormControl
                  placeholder="Search Courses"
                  value={searchTerm}
                  onChange={handleSearch}
                  style={{
                    backgroundColor: "transparent",
                    paddingLeft: "40px",
                    borderColor: "#061151",
                    borderRadius: "5px",
                  }}
                />
                <InputGroup.Text
                  style={{
                    position: "absolute",
                    zIndex: 10,
                    border: "none",
                    background: "none",
                    padding: "10px 0 0 10px",
                  }}>
                  <CiSearch />
                </InputGroup.Text>
              </InputGroup>

              <Button
                onClick={handleSync}
                style={{
                  float: "left",
                  background: "#061151",
                  borderColor: "#061151",
                  color: "#FFF",
                  padding: "0.1rem 1rem",
                  lineHeight: "1",
                  fontSize: "0.875rem",
                  height: "2.3rem",
                  borderRadius: "5px",
                }}>
                Sync Courses
              </Button>
            </div>

            {loading && <p>Loading courses...</p>}
            {error && <p className="text-red-500">{error}</p>}
            {!loading && !error && (
              <MasterclassTable courses={filteredCourses} onView={handleView} />
            )}

            <MasterclassDetailsModal
              show={showModal}
              handleClose={handleCloseModal}
              course={selectedCourse}
            />
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
};

export default CourseAdminView;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container, Row, Col, Card, Button, Modal } from "react-bootstrap";
import Sidebar from "../../components/SideBar";
import Profile from "../../components/userProfile";
import TopNavBar from "../../components/topNavBar";
import Ticket from "../../components/Ticket";

const EventsPage = () => {
  const [events, setEvents] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentUserId, setCurrentUserId] = useState(null);
  const [user, setUser] = useState(null);
  const [showRSVPModal, setShowRSVPModal] = useState(false);
  const [currentRSVP, setCurrentRSVP] = useState(null);

  useEffect(() => {
    const fetchEvents = async () => {
      const token = localStorage.getItem("token");
      const userString = localStorage.getItem("user");

      if (!token || !userString) {
        setError("No authentication token or user ID found");
        setLoading(false);
        return;
      }

      try {
        const userData = JSON.parse(userString);
        setUser(userData);
        setCurrentUserId(userData.id);

        const config = {
          headers: { Authorization: `Bearer ${token}` },
        };

        const eventsResponse = await axios.get(
          "https://yaei-backend-n63o.onrender.com/api/events",
          config
        );

        setEvents(
          eventsResponse.data.map((event) => ({
            ...event,
            start: new Date(event.start),
            end: new Date(event.end),
          }))
        );
        setError(null);
      } catch (error) {
        console.error("Error fetching events:", error);
        setError("Failed to fetch events. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, []);

  const handleRSVP = async (eventId) => {
    const token = localStorage.getItem("token");

    if (!token) {
      setError("No authentication token found");
      return;
    }

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      await axios.post(
        `https://yaei-backend-n63o.onrender.com/api/events/${eventId}/register`,
        {},
        config
      );
      // Update the local state to reflect the change
      setEvents(
        events.map((event) =>
          event._id === eventId
            ? {
                ...event,
                attendees: [...(event.attendees || []), currentUserId],
              }
            : event
        )
      );
      alert("Successfully RSVPed to the event!");
    } catch (error) {
      console.error("Error RSVPing to event:", error);
      alert("Failed to RSVP to the event. Please try again.");
    }
  };

  const handleViewRSVP = (eventId) => {
    const event = events.find((e) => e._id === eventId);
    if (event) {
      setCurrentRSVP({
        eventId: event._id,
        eventTitle: event.title,
        eventDate: event.start.toLocaleString(),
        eventLocation: event.location,
        userName: user.name,
        qrValue: JSON.stringify({
          eventId: event._id,
          userId: currentUserId,
          eventTitle: event.title,
          eventDate: event.start,
        }),
      });
      setShowRSVPModal(true);
    } else {
      console.error("Event not found");
      alert("Unable to view RSVP details. Please try again later.");
    }
  };

  const hasUserRSVPed = (event) => {
    return event.attendees && event.attendees.includes(currentUserId);
  };

  return (
    <div
      className="d-flex flex-row gap-4 mb-5"
      style={{
        minHeight: "100vh",
        maxHeight: "100vh",
        overflowY: "hidden",
        background: "#FFF",
      }}>
      <Sidebar />

      <Container fluid className="d-flex flex-column mt-4 mb-4">
        <TopNavBar />
        <div className="d-flex flex-row justify-content-between">
          <h4
            style={{ fontSize: "23px", fontWeight: "500", color: "#061151" }}
            className="mb-4">
            Events
          </h4>
          <Profile />
        </div>

        <Card
          className="p-2 mt-3 mb-5"
          style={{
            border: "none",
            background: "#F0F3FA",
            minHeight: "90vh",
            maxHeight: "90vh",
            overflowY: "scroll",
            maxWidth: "100vw",
            overflowX: "hidden",
          }}>
          <Card.Body>
            {loading && <p style={{ color: "#061151" }}>Loading events...</p>}
            {error && <p className="text-danger">{error}</p>}

            {!loading && !error && (
              <>
                <Row xs={1} md={3} lg={4} className="g-4 mb-4">
                  {events.length > 0 ? (
                    events.map((event) => (
                      <Col key={event._id}>
                        <Card className="h-100 border-0 shadow-sm hover-shadow transition-all mb-3">
                          <Card.Body className="d-flex flex-column">
                            <Card.Title
                              className="mb-3 text-truncate"
                              style={{
                                fontSize: "1.25rem",
                                fontWeight: "600",
                                color: "#061151",
                              }}>
                              {event.title}
                            </Card.Title>
                            <div className="mb-3">
                              <Button
                                onClick={() => handleRSVP(event._id)}
                                variant="outline-primary"
                                className="me-2"
                                style={{
                                  borderColor: "#061151",
                                  color: "#061151",
                                  transition: "all 0.3s ease",
                                }}>
                                RSVP
                              </Button>
                              <Button
                                onClick={() => handleViewRSVP(event._id)}
                                variant="success"
                                className="me-2"
                                style={{
                                  borderColor: "#28a745",
                                  color: "white",
                                  transition: "all 0.3s ease",
                                }}>
                                View RSVP
                              </Button>
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                    ))
                  ) : (
                    <Col>
                      <p style={{ color: "#061151" }}>
                        No events available at the moment.
                      </p>
                    </Col>
                  )}
                </Row>
              </>
            )}
          </Card.Body>
        </Card>
      </Container>

      <Modal show={showRSVPModal} onHide={() => setShowRSVPModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Your RSVP Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {currentRSVP && (
            <Ticket
              eventTitle={currentRSVP.eventTitle}
              eventDate={currentRSVP.eventDate}
              eventLocation={currentRSVP.eventLocation}
              userName={currentRSVP.userName}
              qrValue={currentRSVP.qrValue}
              ticketStyle={{ paddingBottom: "20px" }}
            />
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default EventsPage;

import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Card,
  Alert,
} from "react-bootstrap";
import { FaLock } from "react-icons/fa";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const MoodleEnrollment = () => {
  const [moodlePassword, setMoodlePassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const validatePassword = (password) => {
    const regex = /^(?=.*[A-Z!@#$%^&*])(?=.{8,})/;
    return regex.test(password);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    if (!validatePassword(moodlePassword)) {
      setError(
        "Your Password is not strong enough. Please ensure that it has at least 8 characters and includes at least 1 uppercase letter and/or a symbol."
      );
      setLoading(false);
      return;
    }

    if (moodlePassword !== confirmPassword) {
      setError("Passwords do not match.");
      setLoading(false);
      return;
    }

    const token = localStorage.getItem("token");
    const user = JSON.parse(localStorage.getItem("user"));

    try {
      const response = await axios.post(
        "https://yaei-backend-n63o.onrender.com/api/users/enroll-moodle",
        {
          userId: user.id,
          password: moodlePassword,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      localStorage.setItem("user", JSON.stringify(response.data.user));
      navigate("/masterclasses");
    } catch (err) {
      console.error("Error enrolling in academy:", err);
      setError(
        err.response?.data?.message ||
          "Error enrolling in academy. Please try again later."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container
      fluid
      className="min-vh-100 d-flex align-items-center justify-content-center"
      style={{ backgroundColor: "#F0F3FA" }}>
      <Row className="justify-content-center w-100">
        <Col md={8} lg={6} xl={4}>
          <Card
            className="shadow-lg border-0 rounded-lg"
            style={{ color: "#00001F" }}>
            <Card.Body className="p-5">
              <div className="text-center mb-4">
                <img
                  src="/logo.png"
                  alt="Young African Entrepreneurs Institute"
                  height="150"
                />
              </div>
              <h2 className="text-center mb-4">Enroll in Academy</h2>
              {error && <Alert variant="danger">{error}</Alert>}
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <FaLock className="me-2" />
                    Moodle Password
                  </Form.Label>
                  <Form.Control
                    type="password"
                    value={moodlePassword}
                    onChange={(e) => setMoodlePassword(e.target.value)}
                    required
                  />
                  <Form.Text className="text-muted">
                    Password must be at least 8 characters long and include at
                    least 1 uppercase letter and/or a symbol.
                  </Form.Text>
                </Form.Group>
                <Form.Group className="mb-4">
                  <Form.Label>
                    <FaLock className="me-2" />
                    Confirm Moodle Password
                  </Form.Label>
                  <Form.Control
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                  />
                </Form.Group>
                <Button
                  type="submit"
                  className="w-100"
                  style={{ background: "#bf9731", border: "none" }}
                  disabled={loading}>
                  {loading ? "Enrolling..." : "Enroll"}
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default MoodleEnrollment;

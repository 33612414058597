import React from "react";
import { Card, Button, Container, Row, Col } from "react-bootstrap";

const EventLinks = () => {
  const events = [
    {
      title: "Youth Innovation Challenge",
      links: {
        preEvaluation:
          "https://docs.google.com/forms/d/e/1FAIpQLSdlJ7Ec8ODOPumcfEBFzPyzHpdMwp_Vx26hXhybaHU7Ax_ixQ/viewform?usp=sf_link",
        register:
          "https://docs.google.com/forms/d/e/1FAIpQLScu2dE5YlzDj7o6cp2O6VT89Z4tPw5YUut1oVSIDkk6v9kplQ/viewform?usp=sf_link",
        postEvaluation:
          "https://docs.google.com/forms/d/e/1FAIpQLSdyjF8xK528HYLxYXDeQnUHHN4eQ02ixmEwbZdVQpMgE_Kk8g/viewform?usp=sf_link",
      },
    },
    {
      title: "Absa Youth Enterprise Development Symposium",
      links: {
        preEvaluation:
          "https://docs.google.com/forms/d/e/1FAIpQLSfPjWWokD2KFwRLNEpp8ET0R5htAE-t7d80-za-HUrUOeQsYg/viewform?usp=sf_link",
        register:
          "https://docs.google.com/forms/d/e/1FAIpQLSfJKwE9K7nf95FUW7iHSaD7-hvv-L0_zw15ZogF7WxiN9DQDA/viewform?usp=sf_link",
        postEvaluation:
          "https://docs.google.com/forms/d/e/1FAIpQLSeYpMRrKtS1O6lhz7UTQJy4V3IKjA5hBNBonnfyNfOKdqYKQQ/viewform?usp=sf_link",
      },
    },
    {
      title: "Proudly African Market Festival",
      links: {
        preEvaluation:
          "https://docs.google.com/forms/d/e/1FAIpQLSciq0bStAE4uxv7fhJzwfeBDenL3rl8cFFZY7EepTlH4G-oqA/viewform?usp=sf_link",
        register:
          "https://docs.google.com/forms/d/e/1FAIpQLSc21yxK9YxIGMD54DehU4gkrHIawTXbMkMMrH3FiR7jbgfbIQ/viewform?usp=sf_link",
        postEvaluation:
          "https://docs.google.com/forms/d/e/1FAIpQLScrN_XAjsZPY9iUB_0ey3snywNjYJFmAlGwH5QyjzQSY6n-8g/viewform?usp=sf_link",
      },
    },
  ];

  const buttonStyle = {
    backgroundColor: "#bf9731",
    border: "none",
    marginBottom: "10px",
    width: "100%",
    transition: "background-color 0.3s",
  };

  return (
    <Container className="py-5">
      <h1 className="text-center mb-5" style={{ color: "#061151" }}>
        Event Links
      </h1>
      <Row className="justify-content-center">
        {events.map((event, index) => (
          <Col md={12} lg={10} className="mb-4" key={index}>
            <Card className="shadow-sm">
              <Card.Body>
                <h3 className="mb-4" style={{ color: "#061151" }}>
                  {event.title}
                </h3>
                <Row>
                  <Col md={4}>
                    <Button
                      href={event.links.preEvaluation}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={buttonStyle}
                      onMouseOver={(e) =>
                        (e.target.style.backgroundColor = "#061151")
                      }
                      onMouseOut={(e) =>
                        (e.target.style.backgroundColor = "#bf9731")
                      }>
                      Pre-evaluation Form
                    </Button>
                  </Col>
                  <Col md={4}>
                    <Button
                      href={event.links.register}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={buttonStyle}
                      onMouseOver={(e) =>
                        (e.target.style.backgroundColor = "#061151")
                      }
                      onMouseOut={(e) =>
                        (e.target.style.backgroundColor = "#bf9731")
                      }>
                      Registration Form
                    </Button>
                  </Col>
                  <Col md={4}>
                    <Button
                      href={event.links.postEvaluation}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={buttonStyle}
                      onMouseOver={(e) =>
                        (e.target.style.backgroundColor = "#061151")
                      }
                      onMouseOut={(e) =>
                        (e.target.style.backgroundColor = "#bf9731")
                      }>
                      Post-evaluation Form
                    </Button>
                  </Col>
                </Row>

                <div className="mt-3">
                  <p className="small text-muted mb-2">Direct Links:</p>
                  <div className="small">
                    <div className="mb-1">
                      Pre-evaluation:{" "}
                      <a
                        href={event.links.preEvaluation}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: "#bf9731" }}>
                        {event.links.preEvaluation}
                      </a>
                    </div>
                    <div className="mb-1">
                      Registration:{" "}
                      <a
                        href={event.links.register}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: "#bf9731" }}>
                        {event.links.register}
                      </a>
                    </div>
                    <div className="mb-1">
                      Post-evaluation:{" "}
                      <a
                        href={event.links.postEvaluation}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: "#bf9731" }}>
                        {event.links.postEvaluation}
                      </a>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default EventLinks;

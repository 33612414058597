import React, { useRef, useState } from "react";
import { Card, Button } from "react-bootstrap";
import { FaMapMarkerAlt, FaCalendarAlt } from "react-icons/fa";
import { QRCodeSVG } from "qrcode.react";
import html2canvas from "html2canvas";

const Ticket = ({
  eventTitle,
  eventDate,
  eventLocation,
  userName,
  qrValue,
}) => {
  const ticketRef = useRef();
  const [isDownloading, setIsDownloading] = useState(false);

  const downloadTicket = () => {
    setIsDownloading(true);
    const ticketElement = ticketRef.current;

    // Save original styles
    const originalStyles = {
      position: ticketElement.style.position,
      left: ticketElement.style.left,
      top: ticketElement.style.top,
      width: ticketElement.style.width,
      height: ticketElement.style.height,
    };

    // Temporarily modify the ticket's style for capturing
    ticketElement.style.position = "fixed";
    ticketElement.style.left = "0";
    ticketElement.style.top = "0";
    ticketElement.style.width = "400px";

    // Set the height to the scrollHeight to include all content
    ticketElement.style.height = `${ticketElement.scrollHeight}px`;

    html2canvas(ticketElement, {
      windowWidth: 400,
      windowHeight: ticketElement.scrollHeight, // Use scrollHeight here
      scrollY: -window.scrollY,
    }).then((canvas) => {
      const link = document.createElement("a");
      link.download = `${eventTitle}-ticket.png`;
      link.href = canvas.toDataURL();
      link.click();

      // Restore original styles
      Object.assign(ticketElement.style, originalStyles);
      setIsDownloading(false);
    });
  };

  return (
    <div className="d-flex justify-content-center mt-5">
      <Card
        ref={ticketRef}
        className="p-4"
        style={{
          width: "400px",
          background: "#FFF",
          border: "2px solid #061151",
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
          borderRadius: "16px",
        }}>
        <div className="text-center">
          <h3 style={{ color: "#061151", fontWeight: "700" }}>{eventTitle}</h3>
          <div className="mt-3 mb-3">
            <FaCalendarAlt color="#bf9731" className="me-2" />
            <span style={{ color: "#333", fontWeight: "500" }}>
              {eventDate}
            </span>
          </div>
          <div className="mb-4">
            <FaMapMarkerAlt color="#bf9731" className="me-2" />
            <span style={{ color: "#333", fontWeight: "500" }}>
              {eventLocation}
            </span>
          </div>
          <p
            style={{ color: "#bf9731", fontSize: "1.1rem", fontWeight: "600" }}>
            Attendee: {userName}
          </p>
          <QRCodeSVG
            value={qrValue}
            size={150}
            level="H"
            className="my-4 mb-4"
          />
        </div>
        <Button
          onClick={downloadTicket}
          variant="primary"
          disabled={isDownloading}
          style={{
            background: "#061151",
            borderColor: "#061151",
            transition: "all 0.3s ease",
          }}>
          {isDownloading ? "Downloading..." : "Download Ticket"}
        </Button>
      </Card>
    </div>
  );
};

export default Ticket;

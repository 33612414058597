import React from "react";
import { Card, Button, Badge } from "react-bootstrap";
import {
  FaChartLine,
  FaIndustry,
  FaArrowRight,
  FaChartBar,
  FaTrashAlt,
} from "react-icons/fa";

const BusinessCard = ({
  business,
  handleViewBusiness,
  handleAddDiagnostic,
  handleDeleteBusiness,
  currentUserId,
}) => {
  const isOwner = business.owner === currentUserId;

  return (
    <Card className="h-100 border-0 shadow-sm hover-shadow transition-all">
      <Card.Body className="d-flex flex-column">
        <div className="d-flex flex-row justify-content-between align-items-center">
          <Card.Title
            className="mb-0 text-truncate"
            style={{
              fontSize: "1.25rem",
              fontWeight: "600",
              color: "#061151",
            }}>
            {business.businessName}
          </Card.Title>

          <div className="d-flex align-items-center">
            <Button
              onClick={() => handleAddDiagnostic(business._id)}
              variant="outline-secondary"
              style={{
                border: "none",
                color: "#061151",
                transition: "all 0.3s ease",
              }}>
              + <FaChartBar className="ms-2" />
            </Button>
          </div>
        </div>
        <div className="mb-3 mt-3">
          <Badge bg="light" text="dark" className="me-2 p-2">
            <FaChartLine className="me-2" color="#bf9731" />
            {business.maturityLevel}
          </Badge>
          <Badge bg="light" text="dark" className="p-2">
            <FaIndustry className="me-2" color="#bf9731" /> {business.industry}
          </Badge>
        </div>
        <div className="mt-auto d-flex flex-row justify-content-between">
          <Button
            onClick={() => handleViewBusiness(business)}
            variant="outline-primary"
            className="me-2"
            style={{
              borderColor: "#061151",
              color: "#061151",
              transition: "all 0.3s ease",
            }}>
            View Details
            <FaArrowRight className="ms-2" />
          </Button>

          <Button
            onClick={() => handleDeleteBusiness(business._id)}
            variant="outline-danger"
            className="ms-2"
            style={{
              border: "none",
              color: "#dc3545",
              transition: "all 0.3s ease",
            }}>
            <FaTrashAlt />
          </Button>
        </div>
      </Card.Body>
    </Card>
  );
};

export default BusinessCard;

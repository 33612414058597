import React, { useState, useEffect } from "react";
import { Scanner } from "@yudiel/react-qr-scanner";
import axios from "axios";
import {
  Container,
  Card,
  Button,
  Alert,
  ListGroup,
  Form,
} from "react-bootstrap";

const PublicEventScanner = () => {
  const [scanning, setScanning] = useState(false);
  const [scanResult, setScanResult] = useState(null);
  const [error, setError] = useState(null);
  const [scannedAttendees, setScannedAttendees] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState("");
  const [events, setEvents] = useState([]);
  const [stats, setStats] = useState({
    totalScanned: 0,
    eventCapacity: 0,
  });

  // Fetch available events
  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axios.get(
          "https://yaei-backend-n63o.onrender.com/api/public-events/events"
        );
        if (response.data.success) {
          setEvents(response.data.events);
        }
      } catch (err) {
        setError("Failed to load events");
      }
    };
    fetchEvents();
  }, []);

  const handleScan = async (detectedCodes) => {
    if (!detectedCodes?.length) return;
    if (!selectedEvent) {
      setError("Please select an event first");
      setScanning(false);
      return;
    }

    const result = detectedCodes[0];
    try {
      const response = await axios.post(
        "https://yaei-backend-n63o.onrender.com/api/public-events/verify",
        {
          qrCode: result.rawValue,
          eventName: selectedEvent,
        }
      );

      if (response.data.success) {
        const {
          firstName,
          lastName,
          email,
          phoneNumber,
          eventName,
          verifiedAt,
        } = response.data.attendeeDetails;
        setScanResult(`✓ Verified: ${firstName} ${lastName}`);
        setScannedAttendees((prev) => [
          {
            firstName,
            lastName,
            email,
            phoneNumber,
            eventName,
            verifiedAt: new Date(verifiedAt).toLocaleString(),
          },
          ...prev,
        ]);

        // Update stats
        setStats((prev) => ({
          ...prev,
          totalScanned: prev.totalScanned + 1,
        }));
      }
    } catch (err) {
      console.error("Error verifying ticket:", err);
      setScanResult(null);
      setError(err.response?.data?.message || "Failed to verify ticket");
    }
    setScanning(false);
  };

  const handleEventChange = async (e) => {
    const eventName = e.target.value;
    setSelectedEvent(eventName);
    setScannedAttendees([]);
    setScanResult(null);
    setError(null);

    if (eventName) {
      const selectedEventData = events.find(
        (event) => event.title === eventName
      );
      if (selectedEventData) {
        setStats({
          totalScanned: 0,
          eventCapacity: selectedEventData.capacity,
        });
      }
    }
  };

  const handleError = (err) => {
    console.error(err);
    setError("Error accessing camera. Please check permissions.");
    setScanning(false);
  };

  return (
    <Container className="mt-4 mb-5">
      <Card className="mb-4" style={{ border: "2px solid #061151" }}>
        <Card.Header
          style={{
            background: "#061151",
            color: "white",
            padding: "1rem",
          }}>
          <h4 className="mb-0">Public Event Scanner</h4>
        </Card.Header>
        <Card.Body>
          <Form.Group className="mb-4">
            <Form.Label>Select Event</Form.Label>
            <Form.Select value={selectedEvent} onChange={handleEventChange}>
              <option value="">Choose an event...</option>
              {events.map((event) => (
                <option key={event._id} value={event.title}>
                  {event.title} - {new Date(event.date).toLocaleDateString()}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          {selectedEvent && (
            <div className="text-center mb-4">
              <div
                className="d-flex justify-content-around p-3"
                style={{ background: "#f8f9fa", borderRadius: "8px" }}>
                <div>
                  <h5 style={{ color: "#061151" }}>Scanned</h5>
                  <p className="h3" style={{ color: "#bf9731" }}>
                    {stats.totalScanned}
                  </p>
                </div>
                <div>
                  <h5 style={{ color: "#061151" }}>Capacity</h5>
                  <p className="h3" style={{ color: "#bf9731" }}>
                    {stats.eventCapacity}
                  </p>
                </div>
              </div>
            </div>
          )}

          {selectedEvent && (
            <div className="text-center mb-4">
              {scanning ? (
                <div className="position-relative">
                  <Scanner
                    onScan={handleScan}
                    onError={handleError}
                    style={{ width: "100%" }}
                  />
                  <Button
                    onClick={() => setScanning(false)}
                    variant="danger"
                    size="sm"
                    className="position-absolute top-0 end-0 m-2">
                    Stop Scanning
                  </Button>
                </div>
              ) : (
                <Button
                  onClick={() => setScanning(true)}
                  style={{
                    background: "#061151",
                    borderColor: "#061151",
                  }}>
                  Start Scanning
                </Button>
              )}
            </div>
          )}

          {error && (
            <Alert variant="danger" className="mt-3">
              {error}
            </Alert>
          )}

          {scanResult && (
            <Alert
              variant="success"
              className="mt-3"
              style={{ borderLeft: "4px solid #28a745" }}>
              {scanResult}
            </Alert>
          )}
        </Card.Body>
      </Card>

      <Card style={{ border: "2px solid #061151" }}>
        <Card.Header
          style={{
            background: "#061151",
            color: "white",
          }}>
          Recently Scanned Attendees
        </Card.Header>
        <ListGroup variant="flush">
          {scannedAttendees.map((attendee, index) => (
            <ListGroup.Item
              key={index}
              style={{
                borderLeft: "4px solid #bf9731",
                margin: "8px",
                borderRadius: "4px",
              }}>
              <div className="d-flex justify-content-between align-items-start">
                <div>
                  <h6
                    className="mb-1"
                    style={{ color: "#061151", fontWeight: "600" }}>
                    {attendee.firstName} {attendee.lastName}
                  </h6>
                  <small className="text-muted d-block">
                    Email: {attendee.email}
                  </small>
                  <small className="text-muted d-block">
                    Phone: {attendee.phoneNumber}
                  </small>
                </div>
                <small className="text-muted" style={{ whiteSpace: "nowrap" }}>
                  {attendee.verifiedAt}
                </small>
              </div>
            </ListGroup.Item>
          ))}
          {selectedEvent && !scannedAttendees.length && (
            <ListGroup.Item className="text-center text-muted">
              No attendees scanned yet
            </ListGroup.Item>
          )}
        </ListGroup>
      </Card>
    </Container>
  );
};

export default PublicEventScanner;

import React from "react";
import { Card, Form } from "react-bootstrap";

const POPIDeclarationContent = ({
  eventName = "Youth Entrepreneurship Week",
  contactEmail = "info@entrepreneursinstitutese.org.za",
  className = "",
}) => {
  return (
    <div>
      <p>
        In terms of the Protection of Personal Information Act, 4 of 2013
        (POPIA), we are required to inform you how we will use, share, and
        protect your personal information. Please read the following declaration
        carefully before providing your consent.
      </p>

      <h5 className="h6 mt-4 mb-2">Purpose of Collection:</h5>
      <p>We collect your personal information for the purposes of:</p>
      <ul>
        <li>Registering you for the {eventName}</li>
        <li>Communicating with you regarding event details and updates</li>
        <li>
          Providing you with relevant information and resources related to
          entrepreneurship
        </li>
        <li>Analyzing data to improve our services and tailor future events</li>
      </ul>

      <h5 className="h6 mt-4 mb-2">Use of Information:</h5>
      <p>
        Your personal information will be used solely for the purposes outlined
        above. We will not use your information for any other purpose without
        your consent.
      </p>

      <h5 className="h6 mt-4 mb-2">Sharing of Information:</h5>
      <p>We may share your personal information with:</p>
      <ul>
        <li>
          Event partners and sponsors, strictly for the purposes of providing
          event-related services and resources
        </li>
        <li>
          Service providers who assist us in managing and operating the event
        </li>
      </ul>

      <h5 className="h6 mt-4 mb-2">Protection of Information:</h5>
      <p>
        We are committed to ensuring that your personal information is secure.
        We have implemented suitable physical, electronic, and managerial
        procedures to safeguard and secure the information we collect.
      </p>

      <h5 className="h6 mt-4 mb-2">Your Rights:</h5>
      <p>You have the right to:</p>
      <ul>
        <li>Access your personal information held by us</li>
        <li>Request correction or deletion of your personal information</li>
        <li>Withdraw your consent at any time by contacting us</li>
      </ul>

      <h5 className="h6 mt-4 mb-2">Contact Information:</h5>
      <p>
        If you have any questions or concerns regarding this declaration or our
        practices, please contact us at:{" "}
        <a href={`mailto:${contactEmail}`} className="text-primary">
          {contactEmail}
        </a>
      </p>
    </div>
  );
};

export default POPIDeclarationContent;

import React from "react";
import { Modal, Button } from "react-bootstrap";

const MasterclassDetailsModal = ({ show, handleClose, course }) => {
  if (!course) return null;

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{course.fullname}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <strong>Category:</strong> {course.categoryname}
        </p>
        <p>
          <strong>Start Date:</strong>{" "}
          {new Date(course.startdate * 1000).toLocaleDateString()}
        </p>
        <p>
          <strong>End Date:</strong>{" "}
          {new Date(course.enddate * 1000).toLocaleDateString()}
        </p>
        <p>
          <strong>Summary:</strong> {course.summary}
        </p>
        <p>
          <strong>Enrolled Users:</strong>{" "}
          {course.enrolledUsers ? course.enrolledUsers.length : 0}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MasterclassDetailsModal;

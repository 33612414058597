// src/components/BusinessDetailsModal.js

import React, { useState, useEffect } from "react";
import { Modal, Button, Row, Col, Card, Tabs, Tab } from "react-bootstrap";
import axios from "axios";
import {
  FaChartLine,
  FaMapMarkerAlt,
  FaIndustry,
  FaMoneyBillWave,
  FaUsers,
  FaHandshake,
  FaTimes,
  FaChartBar,
} from "react-icons/fa";

const BusinessDetailsModal = ({
  show,
  onHide,
  business,
  handleAddDiagnostic,
}) => {
  const [diagnostics, setDiagnostics] = useState([]);
  const [activeTab, setActiveTab] = useState("details");

  useEffect(() => {
    if (business && show) {
      fetchDiagnostics();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [business, show]);

  const fetchDiagnostics = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("Authentication token not found.");
      }

      const response = await axios.get(
        `https://yaei-backend-n63o.onrender.com/api/diagnostics?business=${business._id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setDiagnostics(response.data);
    } catch (error) {
      console.error("Error fetching diagnostics:", error);
      // Optionally, set an error state here to display to the user
    }
  };

  if (!business) {
    return null;
  }

  const renderBusinessDetails = () => (
    <Row>
      {[
        {
          icon: FaChartLine,
          label: "Maturity Level",
          value: business.maturityLevel,
        },
        {
          icon: FaMapMarkerAlt,
          label: "Operating Area",
          value: business.operatingArea,
        },
        { icon: FaIndustry, label: "Industry", value: business.industry },
        { icon: FaMoneyBillWave, label: "Revenue", value: business.revenue },
        { icon: FaUsers, label: "Jobs Created", value: business.jobsCreated },
        { icon: FaHandshake, label: "Partners", value: business.partners },
        {
          icon: FaMapMarkerAlt,
          label: "Business Address",
          value: business.address,
        }, // Added Address
      ].map((item, index) => (
        <Col md={6} key={index} className="mb-3">
          <Card className="h-100 border-0 shadow-sm">
            <Card.Body className="d-flex align-items-start">
              <item.icon className="me-3 mt-1" size={24} color="#bf9731" />
              <div>
                <Card.Title className="fs-6">{item.label}</Card.Title>
                <Card.Text className="fs-5 fw-bold">{item.value}</Card.Text>
              </div>
            </Card.Body>
          </Card>
        </Col>
      ))}
    </Row>
  );

  const renderDiagnostics = () => (
    <Row>
      {diagnostics.length === 0 ? (
        <Col>
          <p>No diagnostics available for this business.</p>
        </Col>
      ) : (
        diagnostics.map((diagnostic, index) => (
          <Col md={12} key={index} className="mb-3">
            <Card className="border-0 shadow-sm">
              <Card.Body>
                <Card.Title className="fs-5 mb-3">
                  Diagnostic {index + 1}
                </Card.Title>
                <Row>
                  {Object.entries(diagnostic).map(([key, value]) => {
                    if (key !== "_id" && key !== "business" && key !== "__v") {
                      return (
                        <Col md={6} key={key} className="mb-2">
                          <strong>
                            {key.charAt(0).toUpperCase() + key.slice(1)}:
                          </strong>{" "}
                          {Array.isArray(value) ? value.join(", ") : value}
                        </Col>
                      );
                    }
                    return null;
                  })}
                </Row>
              </Card.Body>
            </Card>
          </Col>
        ))
      )}
    </Row>
  );

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header
        className="border-0 d-flex flex-row justify-content-between"
        style={{
          background: "#061151",
          color: "#bf9731",
        }}>
        <Modal.Title>{business.businessName}</Modal.Title>
        <Button variant="link" className="text-white" onClick={onHide}>
          <FaTimes size={20} />
        </Button>
      </Modal.Header>
      <Modal.Body className="bg-light">
        <Tabs
          activeKey={activeTab}
          onSelect={(k) => setActiveTab(k)}
          className="mb-3"
          fill>
          <Tab eventKey="details" title="Business Details">
            {renderBusinessDetails()}
          </Tab>
          <Tab eventKey="diagnostics" title="Diagnostics">
            {renderDiagnostics()}
          </Tab>
        </Tabs>
      </Modal.Body>
      <Modal.Footer className="border-0 bg-light">
        <Button
          onClick={() => handleAddDiagnostic(business._id)}
          variant="outline-primary"
          style={{
            borderColor: "#bf9731",
            color: "#bf9731",
          }}>
          Add New Diagnostic
          <FaChartBar className="ms-2" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default BusinessDetailsModal;

import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Card,
  Alert,
  Modal,
} from "react-bootstrap";
import {
  FaUser,
  FaEnvelope,
  FaPhone,
  FaMapMarkerAlt,
  FaBriefcase,
  FaBirthdayCake,
  FaLock,
  FaUserTag,
} from "react-icons/fa";
import { PiGenderIntersexFill } from "react-icons/pi";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

import AddressAutocomplete from "../../components/AddressAutoComplete";

const OnboardingForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    role: "entrepreneur",
    phone: "",
    age: "",
    gender: "",
    area: "",
    currentActivity: "",
  });

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState("");
  const [showTerms, setShowTerms] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [autocomplete, setAutocomplete] = useState(null);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Clear field-specific errors when the user starts typing
    if (error[name]) {
      setError({ ...error, [name]: "" });
    }
  };

  // Handle address selection from autocomplete
  const handleAddressSelect = ({ address, coordinates }) => {
    console.log("Selected Address:", address);
    console.log("Coordinates:", coordinates);

    setFormData({
      ...formData,
      area: address,
    });
  };

  const validateForm = () => {
    const errors = {};

    if (!formData.name.trim()) {
      errors.name = "Name is required";
    }

    if (!formData.email.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email is invalid";
    }

    if (!formData.password) {
      errors.password = "Password is required";
    } else if (formData.password.length < 8) {
      errors.password = "Password must be at least 8 characters long";
    } else if (!/(?=.*[0-9])(?=.*[!@#$%^&*])/.test(formData.password)) {
      errors.password =
        "Password must contain at least one number and one special character";
    }

    if (formData.password !== formData.confirmPassword) {
      errors.confirmPassword = "Passwords do not match";
    }

    if (!formData.phone.trim()) {
      errors.phone = "Phone number is required";
    } else if (!/^\d+$/.test(formData.phone)) {
      errors.phone = "Phone number should contain only digits";
    }

    if (!formData.age) {
      errors.age = "Age range is required";
    }

    if (!formData.gender) {
      errors.gender = "Gender is required";
    }

    if (!formData.area.trim()) {
      errors.area = "Area is required";
    }

    if (!formData.currentActivity) {
      errors.currentActivity = "Current activity is required";
    }

    if (!acceptedTerms) {
      errors.terms = "You must accept the terms of use";
    }

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = validateForm();

    if (Object.keys(formErrors).length > 0) {
      setError(formErrors);
      return;
    }

    setError({});
    setLoading(true);
    setSuccess("");

    try {
      const { confirmPassword, ...dataToSend } = formData;

      const response = await axios.post(
        "https://yaei-backend-n63o.onrender.com/api/users/register",
        dataToSend
      );
      console.log(response.data);
      setSuccess("Registration successful!");
      navigate("/");
    } catch (error) {
      console.error(
        "Error registering user:",
        error.response?.data?.message || error.message
      );
      setError({
        general:
          error.response?.data?.message ||
          "An error occurred during registration",
      });
    }
    setLoading(false);
  };

  const onLoad = (autocomplete) => {
    setAutocomplete(autocomplete);
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      setFormData({ ...formData, area: place.formatted_address });
    }
  };

  return (
    <Container
      fluid
      className="min-vh-100 d-flex align-items-center justify-content-center"
      style={{ backgroundColor: "#F0F3FA" }}>
      <Row className="justify-content-center w-100">
        <Col md={8} lg={6} xl={5}>
          <Card
            className="shadow-lg border-0 rounded-lg"
            style={{ color: "#00001F" }}>
            <Card.Body className="p-5">
              <div className="text-center mb-4">
                <img
                  src="/logo.png"
                  alt="Young African Entrepreneurs Institute"
                  height="150"
                />
                <h2 className="mt-3" style={{ color: "#061151" }}>
                  YAEI Connect SignUp
                </h2>
              </div>
              {error.general && <Alert variant="danger">{error.general}</Alert>}
              {success && <Alert variant="success">{success}</Alert>}
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Label style={{ color: "#061151" }}>
                    <FaUser className="me-2" />
                    Full Name *
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    isInvalid={!!error.name}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {error.name}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label style={{ color: "#061151" }}>
                    <FaEnvelope className="me-2" />
                    Email Address *
                  </Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    isInvalid={!!error.email}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {error.email}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label style={{ color: "#061151" }}>
                    <FaLock className="me-2" />
                    Password *
                  </Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    isInvalid={!!error.password}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {error.password}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label style={{ color: "#061151" }}>
                    <FaLock className="me-2" />
                    Confirm Password *
                  </Form.Label>
                  <Form.Control
                    type="password"
                    name="confirmPassword"
                    value={formData.confirmPassword}
                    onChange={handleChange}
                    isInvalid={!!error.confirmPassword}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {error.confirmPassword}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label style={{ color: "#061151" }}>
                    <FaUserTag className="me-2" />
                    Role *
                  </Form.Label>
                  <Form.Select
                    name="role"
                    value={formData.role}
                    onChange={handleChange}
                    required>
                    <option value="entrepreneur">Entrepreneur</option>
                  </Form.Select>
                </Form.Group>

                {formData.role === "entrepreneur" && (
                  <>
                    <Form.Group className="mb-3">
                      <Form.Label style={{ color: "#061151" }}>
                        <FaPhone
                          className="me-2"
                          style={{ transform: "scaleX(-1)" }}
                        />
                        Phone Number *
                      </Form.Label>
                      <Form.Control
                        type="tel"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        isInvalid={!!error.phone}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        {error.phone}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label style={{ color: "#061151" }}>
                        <FaBirthdayCake className="me-2" />
                        Age Range *
                      </Form.Label>
                      <Form.Select
                        name="age"
                        value={formData.age}
                        onChange={handleChange}
                        isInvalid={!!error.age}
                        required>
                        <option value="">Select age range</option>
                        <option value="18-24">18-24</option>
                        <option value="25-34">25-34</option>
                        <option value="35-44">35-44</option>
                        <option value="45+">45+</option>
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {error.age}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label style={{ color: "#061151" }}>
                        <PiGenderIntersexFill className="me-2" />
                        Gender *
                      </Form.Label>
                      <Form.Select
                        name="gender"
                        value={formData.gender}
                        onChange={handleChange}
                        isInvalid={!!error.gender}
                        required>
                        <option value="">Select gender</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Prefer not to say">
                          Prefer not to say
                        </option>
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {error.gender}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label style={{ color: "#061151" }}>
                        <FaMapMarkerAlt className="me-2" />
                        Area *
                      </Form.Label>
                      <AddressAutocomplete onSelect={handleAddressSelect} />
                      <Form.Control.Feedback type="invalid">
                        {error.area}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-4">
                      <Form.Label style={{ color: "#061151" }}>
                        <FaBriefcase className="me-2" />
                        Current Activity *
                      </Form.Label>
                      <Form.Select
                        name="currentActivity"
                        value={formData.currentActivity}
                        onChange={handleChange}
                        isInvalid={!!error.currentActivity}
                        required>
                        <option value="">Select current activity</option>
                        <option value="Young Entrepreneur">
                          Young Entrepreneur
                        </option>
                        <option value="Young Professional">
                          Young Professional
                        </option>
                        <option value="Student">Student</option>
                        <option value="Unemployed graduate">
                          Unemployed graduate
                        </option>
                        <option value="Employed Entrepreneur">
                          Employed Entrepreneur
                        </option>

                        <option value="Unemployed youth">
                          Unemployed youth
                        </option>
                        <option value="School learner">School learner</option>
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {error.currentActivity}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </>
                )}

                <Form.Group className="mb-3">
                  <Form.Check
                    type="checkbox"
                    label={
                      <>
                        I accept the{" "}
                        <span
                          style={{ color: "#bf9731", cursor: "pointer" }}
                          onClick={() => setShowTerms(true)}>
                          Terms of Use
                        </span>
                      </>
                    }
                    checked={acceptedTerms}
                    onChange={(e) => setAcceptedTerms(e.target.checked)}
                    isInvalid={!!error.terms}
                  />
                  <Form.Control.Feedback type="invalid">
                    {error.terms}
                  </Form.Control.Feedback>
                </Form.Group>

                <Button
                  type="submit"
                  className="w-100"
                  style={{ background: "#bf9731", border: "none" }}
                  disabled={loading}>
                  {loading ? "Registering..." : "Register"}
                </Button>
              </Form>

              <div className="text-center mt-3">
                <p>
                  Already have an account?{" "}
                  <Link to="/signin" style={{ color: "#bf9731" }}>
                    Sign In
                  </Link>
                </p>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Modal show={showTerms} onHide={() => setShowTerms(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Terms of Use</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            These are the Terms of Use for the Young African Entrepreneurs
            Institute (YAEI) Connect platform. By using this platform, you agree
            to abide by these terms.
          </p>
          {/* Add more terms of use content here */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowTerms(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default OnboardingForm;

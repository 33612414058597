import { useState, useEffect } from "react";
import { Container, Row, Col, Card, Image } from "react-bootstrap";
import { HiOutlineUserGroup } from "react-icons/hi";
import Sidebar from "../../components/SideBar";
import { TbBuildingFactory } from "react-icons/tb";
import { BsCalendar2Date } from "react-icons/bs";
import { LiaChalkboardTeacherSolid } from "react-icons/lia";
import CalendarComponent from "../../components/calender";
import axios from "axios";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
} from "chart.js";
import { Pie, Bar } from "react-chartjs-2";
import Profile from "../../components/userProfile";
import TopNavBar from "../../components/topNavBar";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title
);

// MetricCard Component
const MetricCard = ({ title, value, icon: Icon }) => (
  <Card style={{ background: "#F0F3FA" }}>
    <Card.Body className="d-flex justify-content-between align-items-center">
      <div>
        <div style={{ color: "#061151" }}>{title}</div>
        <h3 style={{ color: "#061151" }}>{value}</h3>
      </div>
      <Icon size={30} color="#bf9731" />
    </Card.Body>
  </Card>
);

// CalendarSection Component
const CalendarSection = ({ events }) => (
  <div
    style={{
      background: "#F0F3FA",
      padding: "1rem",
      borderRadius: "5px",
      paddingBottom: "2rem",
    }}>
    <div style={{ color: "#061151", marginBottom: "1rem" }}>
      Events and Masterclasses
    </div>
    <hr />
    <CalendarComponent events={events} />
  </div>
);

// MainDashboard Component
const MainDashboard = ({
  businesses,
  diagnostics,
  masterclasses,
  users,
  events,
}) => {
  const userInfo = {
    name: "Risuna",
    surname: "Maluleke",
    role: "Chief Operating Officer",
  };

  const businessMaturityData = {
    labels: ["Ideation", "Validation", "Creation", "Scaling"],
    datasets: [
      {
        data: [
          businesses.filter((b) => b.maturityLevel === "Ideation").length,
          businesses.filter((b) => b.maturityLevel === "Validation").length,
          businesses.filter((b) => b.maturityLevel === "Creation").length,
          businesses.filter((b) => b.maturityLevel === "Scaling").length,
        ],
        backgroundColor: ["#bf9731", "#061151", "#737373", "#D9D9D9"],
        hoverBackgroundColor: ["#bf9731", "#061151", "#737373", "#D9D9D9"],
      },
    ],
  };

  const averageDiagnostics = {
    labels: ["EI", "SD", "C", "ME"],
    datasets: [
      {
        label: "Average Scores",
        data: [
          diagnostics.reduce((sum, d) => sum + d.entrepreneurialIntent, 0) /
            diagnostics.length,
          diagnostics.reduce((sum, d) => sum + d.skillsDevelopment, 0) /
            diagnostics.length,
          diagnostics.reduce((sum, d) => sum + d.confidence, 0) /
            diagnostics.length,
          diagnostics.reduce((sum, d) => sum + d.marketEngagement, 0) /
            diagnostics.length,
        ],
        backgroundColor: "#061151",
        borderWidth: 1,
        borderRadius: 1,
        borderColor: "#061151",
        barThickness: 25,
      },
    ],
  };

  const pieOptions = {
    responsive: true,
    maintainAspectRatio: false,
  };

  const barOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        max: 5,
      },
    },
  };

  return (
    <Container fluid className="p-4">
      <TopNavBar />
      <Row className="mb-4">
        <div className="d-flex flex-row justify-content-between">
          <h4
            style={{ fontSize: "23px", fontWeight: "500", color: "#061151" }}
            className="mb-4">
            Overview
          </h4>
          <Profile />
        </div>
      </Row>

      <Row className="mb-1">
        <Col md={3} sm={6} xs={6} className="mb-3">
          <MetricCard
            title="Entrepreneurs"
            value={users
              .filter((user) => user.role === "entrepreneur")
              .length.toString()}
            icon={HiOutlineUserGroup}
          />
        </Col>
        <Col md={3} sm={6} xs={6} className="mb-3">
          <MetricCard
            title="Businesses"
            value={businesses.length.toString()}
            icon={TbBuildingFactory}
          />
        </Col>
        <Col md={3} sm={6} xs={6} className="mb-3">
          <MetricCard
            title="Masterclasses"
            value={masterclasses.length.toString()}
            icon={LiaChalkboardTeacherSolid}
          />
        </Col>
        <Col md={3} sm={6} xs={6} className="mb-3">
          <MetricCard
            title="Events"
            value={events.length.toString()}
            icon={BsCalendar2Date}
          />
        </Col>
      </Row>

      <Row>
        <Col md={8}>
          <CalendarSection events={events} />
        </Col>
        <Col md={4}>
          <Card
            className="mb-4"
            style={{ background: "#F0F3FA", border: "none" }}>
            <Card.Body>
              <div style={{ color: "#061151", marginBottom: "1rem" }}>
                Business Maturity
              </div>
              <div style={{ height: "200px" }}>
                <Pie data={businessMaturityData} options={pieOptions} />
              </div>
              <div className="text-center mt-3" style={{ color: "#061151" }}>
                Total Businesses: {businesses.length}
              </div>
            </Card.Body>
          </Card>
          <Card
            className="mb-4"
            style={{ background: "#F0F3FA", border: "none" }}>
            <Card.Body>
              <div style={{ color: "#061151", marginBottom: "1rem" }}>
                Average Diagnostic Scores
              </div>
              <div style={{ height: "200px" }}>
                <Bar data={averageDiagnostics} options={barOptions} />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

// Main Dashboard Component
const Dashboard = () => {
  const [businesses, setBusinesses] = useState([]);
  const [diagnostics, setDiagnostics] = useState([]);
  const [masterclasses, setMasterclasses] = useState([]);
  const [users, setUsers] = useState([]);
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");

      // If there's no token, throw an error
      if (!token) {
        throw new Error("No authentication token found");
      }

      // Create a config object with the authorization header
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      try {
        const [
          businessesRes,
          diagnosticsRes,
          masterclassesRes,
          usersRes,
          eventsRes,
        ] = await Promise.all([
          axios.get(
            "https://yaei-backend-n63o.onrender.com/api/businesses",
            config
          ),
          axios.get(
            "https://yaei-backend-n63o.onrender.com/api/diagnostics",
            config
          ),
          axios.get(
            "https://yaei-backend-n63o.onrender.com/api/masterclasses",
            config
          ),
          axios.get("https://yaei-backend-n63o.onrender.com/api/users", config),
          axios.get(
            "https://yaei-backend-n63o.onrender.com/api/events",
            config
          ),
        ]);

        setBusinesses(businessesRes.data);
        setDiagnostics(diagnosticsRes.data);
        setMasterclasses(masterclassesRes.data);
        setUsers(usersRes.data);
        setEvents(eventsRes.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div
      className="d-flex"
      style={{
        minHeight: "100vh",
        maxHeight: "100vh",
        background: "#FFF",
        overflowY: "scroll",
        maxWidth: "100vw",
        overflowX: "hidden",
      }}>
      <Sidebar />

      <MainDashboard
        businesses={businesses}
        diagnostics={diagnostics}
        masterclasses={masterclasses}
        users={users}
        events={events}
      />
    </div>
  );
};

export default Dashboard;

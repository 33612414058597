// EventAttendanceList.js

import React, { useState, useEffect } from "react";
import {
  Container,
  Card,
  Form,
  Table,
  Alert,
  Row,
  Col,
  Button,
} from "react-bootstrap";
import axios from "axios";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

const EventAttendanceList = () => {
  const [selectedEvent, setSelectedEvent] = useState("");
  const [events, setEvents] = useState([]);
  const [attendees, setAttendees] = useState([]);
  const [error, setError] = useState(null);

  // Statistics
  const [totalAttendees, setTotalAttendees] = useState(0);
  const [attendedCount, setAttendedCount] = useState(0);
  const [notAttendedCount, setNotAttendedCount] = useState(0);
  const [attendancePercentage, setAttendancePercentage] = useState(0);

  // State to manage auto-refresh
  const [isEventDay, setIsEventDay] = useState(false);

  // Search state
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredAttendees, setFilteredAttendees] = useState([]);

  // Fetch available events
  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axios.get(
          "https://yaei-backend-n63o.onrender.com/api/public-events/events"
        );
        if (response.data.success) {
          setEvents(response.data.events);
        }
      } catch (err) {
        setError("Failed to load events");
      }
    };
    fetchEvents();
  }, []);

  // Function to fetch attendees and calculate statistics
  const fetchAttendees = async (eventName) => {
    try {
      const response = await axios.get(
        `https://yaei-backend-n63o.onrender.com/api/public-events/attendees?eventName=${encodeURIComponent(
          eventName
        )}`
      );
      if (response.data.success) {
        const fetchedAttendees = response.data.attendees;
        setAttendees(fetchedAttendees);
        setFilteredAttendees(fetchedAttendees); // Initialize filtered attendees

        // Calculate statistics
        const total = fetchedAttendees.length;
        const attended = fetchedAttendees.filter(
          (attendee) => attendee.attended
        ).length;
        const notAttended = total - attended;
        const percentage =
          total > 0 ? ((attended / total) * 100).toFixed(2) : 0;

        setTotalAttendees(total);
        setAttendedCount(attended);
        setNotAttendedCount(notAttended);
        setAttendancePercentage(percentage);
      } else {
        setAttendees([]);
        setFilteredAttendees([]);
        setError("Failed to load attendees");
      }
    } catch (err) {
      setAttendees([]);
      setFilteredAttendees([]);
      setError("Failed to load attendees");
    }
  };

  // Fetch attendees when an event is selected or on refresh
  useEffect(() => {
    let intervalId;

    const loadAttendees = async () => {
      if (!selectedEvent) return;

      // Reset error and attendees
      setError(null);
      setAttendees([]);
      setFilteredAttendees([]);

      // Find the selected event details
      const eventDetails = events.find(
        (event) => event.title === selectedEvent
      );

      if (eventDetails) {
        // Check if today is the event date
        const eventDate = new Date(eventDetails.date);
        const today = new Date();

        // Set event day status
        const isTodayEventDay =
          eventDate.toDateString() === today.toDateString();
        setIsEventDay(isTodayEventDay);

        // Fetch attendees initially
        await fetchAttendees(selectedEvent);

        // If it's the event day, set up interval to refresh every 30 seconds
        if (isTodayEventDay) {
          intervalId = setInterval(() => {
            fetchAttendees(selectedEvent);
          }, 30000); // 30000 milliseconds = 30 seconds
        }
      }
    };

    loadAttendees();

    // Cleanup interval on unmount or when selectedEvent changes
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [selectedEvent, events]);

  const handleEventChange = (e) => {
    setSelectedEvent(e.target.value);
    setAttendees([]);
    setFilteredAttendees([]);
    setError(null);

    // Reset statistics and search
    setTotalAttendees(0);
    setAttendedCount(0);
    setNotAttendedCount(0);
    setAttendancePercentage(0);
    setIsEventDay(false);
    setSearchTerm("");
  };

  // Handle search input change
  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);

    if (term.trim() === "") {
      setFilteredAttendees(attendees);
    } else {
      const lowerTerm = term.toLowerCase();
      const filtered = attendees.filter((attendee) => {
        return (
          attendee.firstName.toLowerCase().includes(lowerTerm) ||
          attendee.lastName.toLowerCase().includes(lowerTerm) ||
          attendee.email.toLowerCase().includes(lowerTerm) ||
          attendee.phoneNumber.toLowerCase().includes(lowerTerm) ||
          (attendee.idNumber &&
            attendee.idNumber.toLowerCase().includes(lowerTerm)) ||
          (attendee.mealTicketId &&
            attendee.mealTicketId.toLowerCase().includes(lowerTerm)) ||
          (attendee.gender &&
            attendee.gender.toLowerCase().includes(lowerTerm)) ||
          (attendee.age && attendee.age.toString().includes(lowerTerm)) ||
          (attendee.race && attendee.race.toLowerCase().includes(lowerTerm)) ||
          (attendee.province &&
            attendee.province.toLowerCase().includes(lowerTerm)) ||
          (attendee.occupation &&
            attendee.occupation.toLowerCase().includes(lowerTerm)) ||
          (attendee.industry &&
            attendee.industry.toLowerCase().includes(lowerTerm)) ||
          (attendee.area && attendee.area.toLowerCase().includes(lowerTerm)) ||
          (attendee.areaOther &&
            attendee.areaOther.toLowerCase().includes(lowerTerm)) ||
          (attendee.interest &&
            attendee.interest.toLowerCase().includes(lowerTerm)) ||
          (attendee.interestOther &&
            attendee.interestOther.toLowerCase().includes(lowerTerm)) ||
          (attendee.businessName &&
            attendee.businessName.toLowerCase().includes(lowerTerm))
        );
      });
      setFilteredAttendees(filtered);
    }
  };

  // Handle attendance toggle
  const handleAttendanceToggle = async (attendeeId, currentStatus) => {
    try {
      const response = await axios.post(
        "https://yaei-backend-n63o.onrender.com/api/public-events/update-attendance",
        {
          attendeeId,
          attended: !currentStatus,
        }
      );

      if (response.data.success) {
        // Update the attendee's status in state
        const updatedAttendees = attendees.map((attendee) =>
          attendee._id === attendeeId
            ? { ...attendee, attended: !currentStatus }
            : attendee
        );
        setAttendees(updatedAttendees);

        // Update filtered attendees
        const updatedFilteredAttendees = filteredAttendees.map((attendee) =>
          attendee._id === attendeeId
            ? { ...attendee, attended: !currentStatus }
            : attendee
        );
        setFilteredAttendees(updatedFilteredAttendees);

        // Recalculate statistics
        const total = updatedAttendees.length;
        const attendedCount = updatedAttendees.filter(
          (attendee) => attendee.attended
        ).length;
        const notAttendedCount = total - attendedCount;
        const percentage =
          total > 0 ? ((attendedCount / total) * 100).toFixed(2) : 0;

        setTotalAttendees(total);
        setAttendedCount(attendedCount);
        setNotAttendedCount(notAttendedCount);
        setAttendancePercentage(percentage);
      } else {
        setError("Failed to update attendance status");
      }
    } catch (err) {
      setError("Failed to update attendance status");
    }
  };

  // Download Excel function
  const handleDownloadExcel = () => {
    // Check if there is data to export
    if (attendees.length === 0) {
      setError("No attendees to export");
      return;
    }

    // Map attendees data to a format suitable for the Excel file
    const data = attendees.map((attendee) => ({
      "First Name": attendee.firstName,
      "Last Name": attendee.lastName,
      Email: attendee.email,
      "Phone Number": attendee.phoneNumber,
      "ID Number": attendee.idNumber || "",
      "Meal Ticket ID": attendee.mealTicketId || "",
      Gender: attendee.gender || "",
      Age: attendee.age || "",
      Race: attendee.race || "",
      Province: attendee.province || "",
      Occupation: attendee.occupation || "",
      Industry: attendee.industry || "",
      Area: attendee.area || "",
      "Area Other": attendee.areaOther || "",
      Interest: attendee.interest || "",
      "Interest Other": attendee.interestOther || "",
      "Business Name": attendee.businessName || "",
      "POPI Consent": attendee.popiConsent ? "Yes" : "No",
      "Registration Date": attendee.registrationDate
        ? new Date(attendee.registrationDate).toLocaleString()
        : "",
      Attended: attendee.attended ? "Yes" : "No",
    }));

    // Create a worksheet
    const worksheet = XLSX.utils.json_to_sheet(data);

    // Create a workbook and add the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Attendees");

    // Generate a buffer
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    // Save the file
    const dataBlob = new Blob([excelBuffer], {
      type: "application/octet-stream",
    });
    saveAs(dataBlob, `${selectedEvent}_Attendees.xlsx`);
  };

  return (
    <Container className="mt-4 mb-5">
      <Card className="mb-4" style={{ border: "2px solid #061151" }}>
        <Card.Header
          style={{
            background: "#061151",
            color: "white",
            padding: "1rem",
          }}>
          <h4 className="mb-0">Event Attendance List</h4>
        </Card.Header>
        <Card.Body>
          <Form.Group className="mb-4">
            <Form.Label>Select Event</Form.Label>
            <Form.Select value={selectedEvent} onChange={handleEventChange}>
              <option value="">Choose an event...</option>
              {events.map((event) => (
                <option key={event._id} value={event.title}>
                  {event.title} - {new Date(event.date).toLocaleDateString()}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          {error && <Alert variant="danger">{error}</Alert>}

          {selectedEvent && attendees.length > 0 && (
            <>
              {/* Display refresh notice if it's the event day */}
              {isEventDay && (
                <Alert variant="info">
                  The attendee list is refreshing every 30 seconds.
                </Alert>
              )}

              {/* Search Input */}
              <Form.Group className="mb-4">
                <Form.Control
                  type="text"
                  placeholder="Search attendees by any field..."
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
              </Form.Group>

              {/* Statistics */}
              <Row className="mb-4">
                <Col md={3}>
                  <Card
                    className="text-center"
                    style={{ borderColor: "#bf9731" }}>
                    <Card.Body>
                      <Card.Title style={{ color: "#061151" }}>
                        Total Registered
                      </Card.Title>
                      <Card.Text
                        style={{ fontSize: "1.5rem", color: "#bf9731" }}>
                        {totalAttendees}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={3}>
                  <Card
                    className="text-center"
                    style={{ borderColor: "#28a745" }}>
                    <Card.Body>
                      <Card.Title style={{ color: "#061151" }}>
                        Attended
                      </Card.Title>
                      <Card.Text
                        style={{ fontSize: "1.5rem", color: "#28a745" }}>
                        {attendedCount}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={3}>
                  <Card
                    className="text-center"
                    style={{ borderColor: "#dc3545" }}>
                    <Card.Body>
                      <Card.Title style={{ color: "#061151" }}>
                        Not Attended
                      </Card.Title>
                      <Card.Text
                        style={{ fontSize: "1.5rem", color: "#dc3545" }}>
                        {notAttendedCount}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={3}>
                  <Card
                    className="text-center"
                    style={{ borderColor: "#17a2b8" }}>
                    <Card.Body>
                      <Card.Title style={{ color: "#061151" }}>
                        Attendance %
                      </Card.Title>
                      <Card.Text
                        style={{ fontSize: "1.5rem", color: "#17a2b8" }}>
                        {attendancePercentage}%
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              {/* Download Excel Button */}
              <Row className="mb-4">
                <Col>
                  <Button variant="primary" onClick={handleDownloadExcel}>
                    Download Excel
                  </Button>
                </Col>
              </Row>

              {/* Attendees Table */}
              <Table striped bordered hover responsive>
                <thead style={{ backgroundColor: "#061151", color: "#fff" }}>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone Number</th>
                    <th>ID Number</th>
                    <th>Meal Ticket ID</th>
                    <th>Gender</th>
                    <th>Age</th>
                    <th>Race</th>
                    <th>Province</th>
                    <th>Occupation</th>
                    <th>Industry</th>
                    <th>Area</th>
                    <th>Area Other</th>
                    <th>Interest</th>
                    <th>Interest Other</th>
                    <th>Business Name</th>
                    <th>POPI Consent</th>
                    <th>Registration Date</th>
                    <th>Attended</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredAttendees.map((attendee, index) => (
                    <tr key={index}>
                      <td>
                        {attendee.firstName} {attendee.lastName}
                      </td>
                      <td>{attendee.email}</td>
                      <td>{attendee.phoneNumber}</td>
                      <td>{attendee.idNumber || "-"}</td>
                      <td>{attendee.mealTicketId || "-"}</td>
                      <td>{attendee.gender || "-"}</td>
                      <td>{attendee.age || "-"}</td>
                      <td>{attendee.race || "-"}</td>
                      <td>{attendee.province || "-"}</td>
                      <td>{attendee.occupation || "-"}</td>
                      <td>{attendee.industry || "-"}</td>
                      <td>{attendee.area || "-"}</td>
                      <td>{attendee.areaOther || "-"}</td>
                      <td>{attendee.interest || "-"}</td>
                      <td>{attendee.interestOther || "-"}</td>
                      <td>{attendee.businessName || "-"}</td>
                      <td>{attendee.popiConsent ? "Yes" : "No"}</td>
                      <td>
                        {attendee.registrationDate
                          ? new Date(attendee.registrationDate).toLocaleString()
                          : "-"}
                      </td>
                      <td>
                        {attendee.attended ? (
                          <span style={{ color: "green", fontWeight: "bold" }}>
                            Yes
                          </span>
                        ) : (
                          <span style={{ color: "red", fontWeight: "bold" }}>
                            No
                          </span>
                        )}
                      </td>
                      <td>
                        <Button
                          variant={attendee.attended ? "secondary" : "success"}
                          size="sm"
                          onClick={() =>
                            handleAttendanceToggle(
                              attendee._id,
                              attendee.attended
                            )
                          }>
                          {attendee.attended
                            ? "Mark as Not Attended"
                            : "Mark as Attended"}
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </>
          )}

          {selectedEvent && attendees.length === 0 && !error && (
            <Alert variant="info">No attendees found for this event.</Alert>
          )}
        </Card.Body>
      </Card>
    </Container>
  );
};

export default EventAttendanceList;

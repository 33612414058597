import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Card,
  InputGroup,
  FormControl,
  Button,
  Alert,
} from "react-bootstrap";
import Sidebar from "../../components/SideBar";
import { CiSearch } from "react-icons/ci";
import Profile from "../../components/userProfile";
import TopNavBar from "../../components/topNavBar";
import CourseCard from "../../components/MasterclassCard";
import { useNavigate } from "react-router-dom";

const CoursesForUsers = () => {
  const [courses, setCourses] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [user, setUser] = useState(null);
  const [isEnrolledInAcademy, setIsEnrolledInAcademy] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const userString = localStorage.getItem("user");
    if (userString) {
      const userData = JSON.parse(userString);
      setUser(userData);
      setIsEnrolledInAcademy(userData.moodleEnrolled || false);
    }
    fetchCourses();
  }, []);

  const fetchCourses = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        "https://yaei-backend-n63o.onrender.com/api/masterclasses"
      );
      setCourses(response.data);
      setError(null);
    } catch (err) {
      setError("Error fetching courses. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleEnroll = async (courseId) => {
    if (!isEnrolledInAcademy) {
      setError("Please enroll in the academy first.");
      return;
    }

    const token = localStorage.getItem("token");
    const userString = localStorage.getItem("user");

    console.log("DATA MDHARA");
    console.log(userString);

    if (userString) {
      const userData = JSON.parse(userString);
      setUser(userData); // Set user state
      setIsEnrolledInAcademy(userData.moodleEnrolled || false); // Check enrollment status

      try {
        // Check for either _id or id
        const userId = userData._id || userData.id;
        if (!userData || !userId) {
          throw new Error("Invalid user data");
        }

        await axios.post(
          `https://yaei-backend-n63o.onrender.com/api/masterclasses/${courseId}/enroll`,
          {
            userId, // This will send either _id or id
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        fetchCourses(); // Refresh the course list after enrolling
      } catch (err) {
        console.error("Error enrolling in course:", err);
        setError(
          err.message || "Error enrolling in course. Please try again later."
        );
      }
    } else {
      setError("No user data found. Please log in again.");
    }
  };

  const handleAcademyEnrollment = () => {
    navigate("/moodle-enrollment");
  };

  const filteredCourses = courses.filter((course) =>
    course.fullname.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div
      className="d-flex flex-row gap-4 mb-5"
      style={{
        minHeight: "100vh",
        maxHeight: "100vh",
        overflowY: "hidden",
        background: "#FFF",
      }}>
      <Sidebar />

      <Container fluid className="d-flex flex-column mt-4 mb-4">
        <TopNavBar />
        <div className="d-flex flex-row justify-content-between">
          <h4
            style={{ fontSize: "23px", fontWeight: "500", color: "#061151" }}
            className="mb-4">
            Courses
          </h4>
          <Profile />
        </div>

        {!isEnrolledInAcademy && (
          <Alert variant="info" className="mb-3">
            You are not enrolled in the academy.
            <Button
              variant="primary"
              className="ms-3"
              onClick={handleAcademyEnrollment}>
              Enroll in Academy
            </Button>
          </Alert>
        )}

        <Card
          className="p-2 mt-3 mb-5"
          style={{
            border: "none",
            background: "#F0F3FA",
            minHeight: "90vh",
            maxHeight: "90vh",
            overflowY: "scroll",
            maxWidth: "100vw",
            overflowX: "hidden",
          }}>
          <Card.Body>
            <div className="d-flex justify-content-between mb-5 mt-3">
              <InputGroup className="mb-3" style={{ width: "40%" }}>
                <FormControl
                  placeholder="Course"
                  value={searchTerm}
                  onChange={handleSearch}
                  style={{
                    backgroundColor: "transparent",
                    paddingLeft: "40px",
                    borderColor: "#224D9C",
                    borderRadius: "5px",
                  }}
                />
                <InputGroup.Text
                  style={{
                    position: "absolute",
                    zIndex: 10,
                    border: "none",
                    background: "none",
                    padding: "10px 0 0 10px",
                  }}>
                  <CiSearch />
                </InputGroup.Text>
              </InputGroup>

              <div style={{ color: "#bf9731" }}>
                {" "}
                Moodle Username: {user && user.moodleUsername}
              </div>
            </div>

            {loading && <p>Loading courses...</p>}
            {error && <p className="text-danger">{error}</p>}
            {!loading && !error && (
              <>
                <h5>Enrolled Courses</h5>
                <Row xs={1} md={4} lg={4} className="g-4 mb-4">
                  {filteredCourses
                    .filter((course) =>
                      course.enrolledUsers?.some((userId) =>
                        [user?._id, user?.id].includes(userId)
                      )
                    )

                    .map((course) => (
                      <Col key={course.id}>
                        <CourseCard
                          course={course}
                          handleEnroll={handleEnroll}
                          isEnrolled={true}
                        />
                      </Col>
                    ))}
                </Row>

                <h5>Available Courses</h5>
                <Row xs={1} md={4} lg={4} className="g-4 mb-4">
                  {filteredCourses
                    .filter(
                      (course) => !course.enrolledUsers?.includes(user?.id)
                    )
                    .map((course) => (
                      <Col key={course.id}>
                        <CourseCard
                          course={course}
                          handleEnroll={handleEnroll}
                          isEnrolled={false}
                        />
                      </Col>
                    ))}
                </Row>

                <br className="mt-4" />
                <br className="mt-4" />
              </>
            )}
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
};

export default CoursesForUsers;

// CreatePublicEvent.js

import React, { useState } from "react";
import {
  Container,
  Card,
  Form,
  Button,
  Alert,
  Row,
  Col,
} from "react-bootstrap";
import axios from "axios";

const CreatePublicEvent = () => {
  const [formData, setFormData] = useState({
    title: "",
    eventName: "",
    description: "",
    date: "",
    venue: "",
    startTime: "",
    endTime: "",
    capacity: "",
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;

    // If eventName is empty, set it automatically to the title
    if (name === "title") {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
        eventName: value, // Automatically set eventName to title
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    setSuccess("");

    try {
      // Send formData including eventName to the backend
      const response = await axios.post(
        "https://yaei-backend-n63o.onrender.com/api/public-events/create",
        formData
      );

      if (response.data.success) {
        setSuccess("Event created successfully!");
        setFormData({
          title: "",
          eventName: "",
          description: "",
          date: "",
          venue: "",
          startTime: "",
          endTime: "",
          capacity: "",
        });
      }
    } catch (err) {
      setError(err.response?.data?.message || "Failed to create event");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container className="mt-5 mb-5">
      <Card
        style={{
          border: "2px solid #061151",
          borderRadius: "16px",
          maxWidth: "800px",
          margin: "0 auto",
        }}>
        <Card.Body className="p-4">
          <h3
            className="text-center mb-4"
            style={{ color: "#061151", fontWeight: "700" }}>
            Create Public Event
          </h3>

          {error && (
            <Alert variant="danger" className="mb-4">
              {error}
            </Alert>
          )}

          {success && (
            <Alert variant="success" className="mb-4">
              {success}
            </Alert>
          )}

          <Form onSubmit={handleSubmit}>
            {/* Event Title */}
            <Form.Group className="mb-3">
              <Form.Label>Event Title</Form.Label>
              <Form.Control
                type="text"
                name="title"
                value={formData.title}
                onChange={handleChange}
                required
                placeholder="Enter event title"
              />
            </Form.Group>

            {/* Event Name */}
            <Form.Group className="mb-3">
              <Form.Label>Event Name (Identifier)</Form.Label>
              <Form.Control
                type="text"
                name="eventName"
                value={formData.eventName}
                onChange={handleChange}
                required
                placeholder="Enter event name identifier"
              />
              <Form.Text className="text-muted">
                This name will be used to match registrations to this event. It
                should be unique and consistent.
              </Form.Text>
            </Form.Group>

            {/* Description */}
            <Form.Group className="mb-3">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="description"
                value={formData.description}
                onChange={handleChange}
                required
                placeholder="Enter event description"
              />
            </Form.Group>

            {/* Date and Venue */}
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Date</Form.Label>
                  <Form.Control
                    type="date"
                    name="date"
                    value={formData.date}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Venue</Form.Label>
                  <Form.Control
                    type="text"
                    name="venue"
                    value={formData.venue}
                    onChange={handleChange}
                    required
                    placeholder="Enter venue"
                  />
                </Form.Group>
              </Col>
            </Row>

            {/* Start Time and End Time */}
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Start Time</Form.Label>
                  <Form.Control
                    type="time"
                    name="startTime"
                    value={formData.startTime}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>End Time</Form.Label>
                  <Form.Control
                    type="time"
                    name="endTime"
                    value={formData.endTime}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>

            {/* Capacity */}
            <Form.Group className="mb-4">
              <Form.Label>Capacity</Form.Label>
              <Form.Control
                type="number"
                name="capacity"
                value={formData.capacity}
                onChange={handleChange}
                required
                min="1"
                placeholder="Enter maximum number of attendees"
              />
            </Form.Group>

            {/* Submit Button */}
            <div className="d-grid">
              <Button
                type="submit"
                disabled={loading}
                style={{
                  background: "#061151",
                  borderColor: "#061151",
                  padding: "12px",
                }}>
                {loading ? "Creating..." : "Create Event"}
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>

      <div className="mt-4 text-center">
        <p style={{ color: "#666" }}>
          Public events will be available for registration immediately after
          creation.
        </p>
      </div>
    </Container>
  );
};

export default CreatePublicEvent;

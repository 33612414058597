import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  InputGroup,
  FormControl,
  Image,
  Alert,
} from "react-bootstrap";
import axios from "axios";
import Sidebar from "../../components/SideBar";
import { CiSearch } from "react-icons/ci";
import BusinessCard from "../../components/BusinessCard";
import BusinessDetailsModal from "../../components/BusinessDetailModal";
import BusinessRegistrationModal from "./BusinessRegistrationForm";
import DiagnosticFormModal from "../../components/ DiagnosticFormModal";
import Profile from "../../components/userProfile";
import TopNavBar from "../../components/topNavBar";

const BusinessManagementPage = () => {
  const [businesses, setBusinesses] = useState([]);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedBusiness, setSelectedBusiness] = useState(null);
  const [showRegistrationModal, setShowRegistrationModal] = useState(false);
  const [showDiagnosticModal, setShowDiagnosticModal] = useState(false);
  const [businessCount, setBusinessCount] = useState(5);
  const [selectedBusinessForDiagnostic, setSelectedBusinessForDiagnostic] =
    useState(null);

  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("user")) || {
      name: "John",
      surname: "Doe",
      profileImage: "/default-profile.png",
    }
  );

  const [error, setError] = useState(null); // To handle and display errors

  useEffect(() => {
    console.log("Herere ");
    console.log(user);
    fetchBusinesses();
  }, []);

  const fetchBusinesses = async () => {
    try {
      // Retrieve the token and user from localStorage
      const token = localStorage.getItem("token");
      const userString = localStorage.getItem("user");

      // Ensure both token and user data are available
      if (!token || !userString) {
        throw new Error("User not authenticated. Please sign in.");
      }

      // Parse the user JSON string to an object
      const user = JSON.parse(userString);

      // Extract the user ID; adjust the key based on how it's stored
      // It could be 'id', '_id', or another key depending on your backend
      const userId = user._id || user.id;

      if (!userId) {
        throw new Error(
          "User ID not found. Please ensure you are signed in correctly."
        );
      }

      // Make the GET request with the owner query parameter and Authorization header
      const response = await axios.get(
        "https://yaei-backend-n63o.onrender.com/api/businesses",
        {
          params: { owner: userId }, // Query parameter to filter businesses by owner
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the Authorization header
            "Content-Type": "application/json", // Specify the content type
          },
        }
      );

      setBusinessCount(response.data.length);

      // Update the state with the fetched businesses
      setBusinesses(response.data);
    } catch (error) {
      // Log the error for debugging purposes
      console.error("Error fetching businesses:", error);

      // Optional: Provide user-friendly error messages
      if (error.response) {
        // Server responded with a status other than 2xx
        console.error("Server Error:", error.response.data.message);
        // You can set an error state here to display to the user
        // setError(error.response.data.message);
      } else if (error.request) {
        // Request was made but no response received
        console.error("Network Error: No response received from server.");
        // setError("Network error. Please try again later.");
      } else {
        // Something else caused the error
        console.error("Error:", error.message);
        // setError(error.message);
      }

      // Optional: Handle specific error scenarios
      // For example, redirect to sign-in if authentication fails
      if (error.response && error.response.status === 401) {
        // Redirect to sign-in page or show a modal
        // navigate("/signin"); // Uncomment if you have access to navigate
      }
    }
  };

  // const handleRegistrationSuccess = (newBusiness) => {
  //   setBusinesses([...businesses, newBusiness]);
  //   setShowRegistrationModal(false);
  //   // Optionally add a success message or other logic here
  // };
  const handleRegistrationSuccess = () => {
    setShowRegistrationModal(false);
    fetchBusinesses(); // Re-fetch the businesses
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleViewBusiness = (business) => {
    setSelectedBusiness(business);
    setShowDetailsModal(true);
  };

  const handleAddDiagnostic = (businessId) => {
    setSelectedBusinessForDiagnostic(businessId);
    setShowDiagnosticModal(true);
  };

  const handleDiagnosticSuccess = () => {
    setShowDiagnosticModal(false);
    setSelectedBusinessForDiagnostic(null);
    // Optionally, you can refresh the businesses data here
    fetchBusinesses();
  };

  // Filter businesses based on search term
  const filteredBusinesses = businesses.filter((business) =>
    business.businessName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Handle Add business button
  const checkBusinessCount = async () => {
    if (businessCount >= 3) {
      setError(
        "You have reached the maximum number of businesses you can register (3)."
      );
    } else {
      setShowRegistrationModal(true);
    }
  };

  const handleAddButton = () => {
    checkBusinessCount();
  };

  const handleDeleteBusiness = async (businessId) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this business?"
    );
    if (!confirmDelete) return;

    try {
      const token = localStorage.getItem("token");
      if (!token) throw new Error("No authentication token found");

      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      await axios.delete(
        `https://yaei-backend-n63o.onrender.com/api/businesses/${businessId}`,
        config
      );

      // Remove the deleted business from the state
      setBusinesses((prevBusinesses) =>
        prevBusinesses.filter((b) => b._id !== businessId)
      );
    } catch (error) {
      console.error("Error deleting business:", error);
      setError("Failed to delete business. Please try again.");
    }
  };

  return (
    <div
      className="d-flex flex-row gap-4"
      style={{
        minHeight: "100vh",
        maxHeight: "100vh",
        overflowY: "hidden",
        background: "#FFF",
        maxWidth: "100vw",
        overflowX: "hidden",
      }}>
      <Sidebar />

      <Container fluid className="d-flex flex-column mt-4">
        <TopNavBar />
        <div className="d-flex flex-row justify-content-between">
          <h4
            style={{ fontSize: "23px", fontWeight: "500", color: "#061151" }}
            className="mb-4">
            My Businesses
          </h4>
          <Profile />
        </div>

        <div>{error && <Alert variant="danger">{error}</Alert>}</div>

        <Card
          className="p-2 mt-3"
          style={{
            border: "none",
            background: "#F0F3FA",
            minHeight: "80vh",
            maxHeight: "80vh",
            overflowY: "scroll",
          }}>
          <Card.Body>
            <div className="d-flex justify-content-between mb-5 mt-3">
              <InputGroup className="mb-3" style={{ width: "40%" }}>
                <FormControl
                  placeholder="Business"
                  value={searchTerm}
                  onChange={handleSearch}
                  style={{
                    backgroundColor: "transparent",
                    paddingLeft: "40px",
                    borderColor: "#224D9C",
                    borderRadius: "5px",
                  }}
                />
                <InputGroup.Text
                  style={{
                    position: "absolute",
                    zIndex: 10,
                    border: "none",
                    background: "none",
                    padding: "10px 0 0 10px",
                  }}>
                  <CiSearch />
                </InputGroup.Text>
              </InputGroup>

              <Col className="text-end">
                <Button
                  onClick={handleAddButton}
                  style={{
                    background: "#061151",
                    border: "none",
                    color: "#F0F3FA",
                  }}>
                  Register New Business
                </Button>
              </Col>
            </div>
            <Row>
              {filteredBusinesses.map((business) => (
                <Col key={business._id} md={4} className="mb-4">
                  <BusinessCard
                    business={business}
                    handleViewBusiness={handleViewBusiness}
                    handleAddDiagnostic={handleAddDiagnostic}
                    handleDeleteBusiness={handleDeleteBusiness}
                    currentUserId={user.id}
                  />
                </Col>
              ))}
            </Row>
            <BusinessDetailsModal
              show={showDetailsModal}
              onHide={() => setShowDetailsModal(false)}
              business={selectedBusiness}
              handleAddDiagnostic={handleAddDiagnostic}
            />
            {/* <BusinessRegistrationModal
              show={showRegistrationModal}
              onHide={() => setShowRegistrationModal(false)}
              onSuccess={handleRegistrationSuccess}
              userId={user.id}
            /> */}
            <BusinessRegistrationModal
              show={showRegistrationModal}
              onHide={() => setShowRegistrationModal(false)}
              onSuccess={handleRegistrationSuccess}
              userId={user.id}
            />

            <DiagnosticFormModal
              show={showDiagnosticModal}
              onHide={() => setShowDiagnosticModal(false)}
              businessId={selectedBusinessForDiagnostic}
              onSubmitSuccess={handleDiagnosticSuccess}
              userId={user.id}
            />
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
};

export default BusinessManagementPage;

import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Row,
  Col,
  Card,
  Table,
  Tabs,
  Tab,
  Accordion,
} from "react-bootstrap";
import {
  FaTimes,
  FaEnvelope,
  FaPhone,
  FaBirthdayCake,
  FaVenusMars,
  FaMapMarkerAlt,
  FaBriefcase,
  FaBuilding,
  FaChartBar,
} from "react-icons/fa";
import axios from "axios";

const EntrepreneurDetailsModal = ({ show, handleClose, entrepreneur }) => {
  const [businessDiagnostics, setBusinessDiagnostics] = useState({});

  useEffect(() => {
    if (entrepreneur && show) {
      fetchDiagnosticsForBusinesses();
    }
  }, [entrepreneur, show]);

  const fetchDiagnosticsForBusinesses = async () => {
    const diagnostics = {};
    for (const business of entrepreneur.businesses) {
      try {
        const response = await axios.get(
          `https://yaei-backend-n63o.onrender.com/api/diagnostics?business=${business._id}`
        );
        diagnostics[business._id] = response.data;
      } catch (error) {
        console.error(
          `Error fetching diagnostics for business ${business._id}:`,
          error
        );
        diagnostics[business._id] = [];
      }
    }
    setBusinessDiagnostics(diagnostics);
  };

  if (!entrepreneur) return null;

  const renderPersonalInfo = () => (
    <Row>
      {[
        { icon: FaEnvelope, label: "Email", value: entrepreneur.email },
        { icon: FaPhone, label: "Phone", value: entrepreneur.phone },
        { icon: FaBirthdayCake, label: "Age", value: entrepreneur.age },
        { icon: FaVenusMars, label: "Gender", value: entrepreneur.gender },
        { icon: FaMapMarkerAlt, label: "Area", value: entrepreneur.area },
        {
          icon: FaBriefcase,
          label: "Current Activity",
          value: entrepreneur.currentActivity,
        },
      ].map((item, index) => (
        <Col md={6} key={index} className="mb-3">
          <Card className="h-100 border-0 shadow-sm">
            <Card.Body>
              <item.icon className="mb-2" size={24} color="#bf9731" />
              <Card.Title className="fs-6">{item.label}</Card.Title>
              <Card.Text className="fs-5 fw-bold">{item.value}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      ))}
    </Row>
  );

  const renderBusinesses = () => (
    <Row>
      <Col md={12}>
        <Card className="border-0 shadow-sm">
          <Card.Body>
            <Card.Title className="fs-5 mb-3">
              <FaBuilding className="me-2" color="#bf9731" />
              Registered Businesses
            </Card.Title>
            {entrepreneur.businesses.length === 0 ? (
              <p>No businesses registered.</p>
            ) : (
              <Table hover>
                <thead>
                  <tr>
                    <th>Business Name</th>
                    <th>Maturity Level</th>
                    <th>Industry</th>
                    <th>Revenue</th>
                  </tr>
                </thead>
                <tbody>
                  {entrepreneur.businesses.map((business) => (
                    <tr key={business._id}>
                      <td>{business.businessName}</td>
                      <td>{business.maturityLevel}</td>
                      <td>{business.industry}</td>
                      <td>{business.revenue}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );

  const renderDiagnostics = () => (
    <Row>
      <Col md={12}>
        <Card className="border-0 shadow-sm">
          <Card.Body>
            <Card.Title className="fs-5 mb-3">
              <FaChartBar className="me-2" color="#bf9731" />
              Business Diagnostics
            </Card.Title>
            {entrepreneur.businesses.length === 0 ? (
              <p>No businesses registered.</p>
            ) : (
              <Accordion>
                {entrepreneur.businesses.map((business, index) => (
                  <Accordion.Item
                    eventKey={index.toString()}
                    key={business._id}>
                    <Accordion.Header>{business.businessName}</Accordion.Header>
                    <Accordion.Body>
                      {businessDiagnostics[business._id]?.length > 0 ? (
                        businessDiagnostics[business._id].map(
                          (diagnostic, diagIndex) => (
                            <Card key={diagIndex} className="mb-3">
                              <Card.Body>
                                <Card.Title className="fs-6">
                                  Diagnostic {diagIndex + 1}
                                </Card.Title>
                                <Row>
                                  {Object.entries(diagnostic).map(
                                    ([key, value]) => {
                                      if (
                                        key !== "_id" &&
                                        key !== "business" &&
                                        key !== "__v"
                                      ) {
                                        return (
                                          <Col
                                            md={6}
                                            key={key}
                                            className="mb-2">
                                            <strong>
                                              {key.charAt(0).toUpperCase() +
                                                key.slice(1)}
                                              :
                                            </strong>{" "}
                                            {Array.isArray(value)
                                              ? value.join(", ")
                                              : value}
                                          </Col>
                                        );
                                      }
                                      return null;
                                    }
                                  )}
                                </Row>
                              </Card.Body>
                            </Card>
                          )
                        )
                      ) : (
                        <p>No diagnostics available for this business.</p>
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            )}
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );

  return (
    <Modal show={show} onHide={handleClose} size="lg" centered>
      <Modal.Header
        className="border-0 d-flex flex-row justify-content-between"
        style={{
          background: "#061151",
          color: "#bf9731",
        }}>
        <Modal.Title>{entrepreneur.name}'s Details</Modal.Title>
        <Button variant="link" className="text-white" onClick={handleClose}>
          <FaTimes />
        </Button>
      </Modal.Header>
      <Modal.Body className="bg-light">
        <Tabs defaultActiveKey="personal" className="mb-3">
          <Tab eventKey="personal" title="Personal Information">
            {renderPersonalInfo()}
          </Tab>
          <Tab eventKey="businesses" title="Businesses">
            {renderBusinesses()}
          </Tab>
          <Tab eventKey="diagnostics" title="Diagnostics">
            {renderDiagnostics()}
          </Tab>
        </Tabs>
      </Modal.Body>
      <Modal.Footer className="border-0 bg-light">
        <Button
          onClick={handleClose}
          variant="outline-primary"
          style={{
            borderColor: "#bf9731",
            color: "#bf9731",
          }}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EntrepreneurDetailsModal;

import React from "react";
import { Card, Button, Image } from "react-bootstrap";
import { Clock, GraduationCap } from "lucide-react";

const CourseCard = ({ course, handleEnroll, isEnrolled }) => {
  const removeTags = (str) => {
    if (str === null || str === "") return "";
    else str = str.toString();
    return str.replace(/(<([^>]+)>)/gi, "");
  };

  const visitCourse = () => {
    window.open(
      `https://yaeiconnect.org/course/view.php?id=${course.id}`,
      "_blank"
    );
  };
  return (
    <Card className="shadow">
      <div className="position-relative" style={{ padding: "0.5rem" }}>
        <Card.Img
          variant="top"
          src="/diploma.png" // You might want to replace this with a course-specific image if available
          alt="Course cover"
          style={{ height: "12rem", objectFit: "contain" }}
        />
      </div>

      <Card.Body>
        <Card.Title
          className="fw-bold mb-3"
          style={{ fontSize: "1.25rem", color: "#061151" }}>
          {course.fullname || "Course Title"}
        </Card.Title>
        <hr />
        <Card.Text
          className="text-muted mb-3"
          style={{
            maxHeight: "8rem",
            minHeight: "10rem",
            overflowY: "scroll",
            paddingBottom: "1rem",
          }}>
          {removeTags(course.summary) || "No description available"}
        </Card.Text>

        <hr />
        <div className="d-flex align-items-center mb-3">
          <Image
            src="/risuna.jpeg" // Replace with actual instructor image if available
            roundedCircle
            width={40}
            height={40}
            className="me-3"
          />
          <div>
            <p className="mb-0 fw-semibold" style={{ color: "#061151" }}>
              {course.shortname || "Uncategorized"}
            </p>
            <div
              className="d-flex align-items-center text-muted"
              style={{ fontSize: "0.875rem" }}>
              <hr />
              <Clock size={16} className="me-1" />
              <span className="me-3">
                {new Date(course.startdate * 1000).toLocaleDateString()}
              </span>
              <GraduationCap size={16} className="me-1" />
              <span>
                {course.enrolledUsers ? course.enrolledUsers.length : 0}
              </span>
            </div>
          </div>
        </div>
        {isEnrolled ? (
          <Button
            style={{ background: "#061151", border: "none" }}
            className="w-100"
            onClick={visitCourse}>
            Visit Course
          </Button>
        ) : (
          <Button
            style={{ background: "#bf9731", border: "none" }}
            className="w-100"
            onClick={() => handleEnroll(course.id)}>
            Enroll
          </Button>
        )}
      </Card.Body>
    </Card>
  );
};

export default CourseCard;

import React, { useState, useEffect } from "react";
import { Image } from "react-bootstrap";

const Profile = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const userDataString = localStorage.getItem("user");
    if (userDataString) {
      try {
        const userData = JSON.parse(userDataString);
        setUser(userData);
      } catch (error) {
        console.error("Error parsing user data from localStorage:", error);
      }
    }
  }, []);

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <div className="d-flex flex-row justify-content-between gap-3">
      <span>
        <div
          style={{
            fontSize: "16px",
            fontWeight: "600",
            color: "#061151",
          }}>
          {`${user.name} ${user.surname || ""}`}
        </div>
        <div
          style={{
            fontSize: "12px",
            fontWeight: "400",
            color: "#bf9731",
          }}>
          {user.role}
        </div>
      </span>
      <span
        style={{
          display: "inline-block",
          borderRadius: "50%",
          overflow: "hidden",
          border: "2px solid #bf9731",
          width: "50px",
          height: "50px",
          marginTop: "-5px",
        }}>
        <Image
          src={user.profilePicture || "/default-profile.png"}
          roundedCircle
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
      </span>
    </div>
  );
};

export default Profile;

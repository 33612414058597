// src/components/BusinessRegistrationModal.js

import React, { useEffect, useState } from "react";
import { Modal, Form, Button, Alert } from "react-bootstrap";
import {
  FaBuilding,
  FaChartLine,
  FaMapMarkerAlt,
  FaIndustry,
  FaMoneyBillWave,
  FaUsers,
  FaHandshake,
  FaBook,
} from "react-icons/fa";
import axios from "axios";
import AddressAutocomplete from "../../components/AddressAutoComplete";
import { useNavigate } from "react-router-dom";

const BusinessRegistrationModal = ({ show, onHide, onSuccess, userId }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    businessName: "",
    description: "",
    maturityLevel: "",
    operatingArea: "",
    industry: "",
    revenue: "",
    jobsCreated: "",
    partners: "",
    address: "",
    coordinates: { lat: null, lng: null },
  });

  const [error, setError] = useState(null); // To handle and display errors
  const [loading, setLoading] = useState(false); // To handle loading state

  // Handle input changes
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Handle address selection from autocomplete
  const handleAddressSelect = ({ address, coordinates }) => {
    console.log("Selected Address:", address);
    console.log("Coordinates:", coordinates);

    setFormData({
      ...formData,
      address,
      coordinates: {
        lat: parseFloat(coordinates.lat),
        lng: parseFloat(coordinates.lng),
      },
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(""); // Reset error
    setLoading(true); // Start loading

    try {
      // Retrieve token from localStorage
      const token = localStorage.getItem("token");

      if (!token) {
        throw new Error("User not authenticated. Please sign in.");
      }

      // Validate that all required fields are filled
      const {
        businessName,
        description,
        maturityLevel,
        operatingArea,
        industry,
        revenue,
        jobsCreated,
        partners,
        address,
        coordinates,
      } = formData;

      if (
        !businessName ||
        !description ||
        !maturityLevel ||
        !operatingArea ||
        !industry ||
        !revenue ||
        jobsCreated === "" ||
        partners === "" ||
        !address ||
        !coordinates.lat ||
        !coordinates.lng
      ) {
        throw new Error("Please fill in all required fields.");
      }

      // Prepare the payload
      const payload = {
        businessName,
        description,
        maturityLevel,
        operatingArea,
        industry,
        revenue,
        jobsCreated,
        partners,
        address,
        coordinates,
      };

      // Make POST request to the backend API
      const response = await axios.post(
        "https://yaei-backend-n63o.onrender.com/api/businesses",
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      // Handle successful registration
      console.log("Business registered successfully:", response.data);
      // Optionally, navigate to a different page or reset the form
      // navigate("/businesses"); // Adjust the path as necessary
      onSuccess();
    } catch (err) {
      console.error("Error registering business:", err);
      // Set error message based on the response
      if (err.response && err.response.data && err.response.data.message) {
        setError(err.response.data.message);
      } else {
        setError(err.message || "An unexpected error occurred.");
      }
    } finally {
      setLoading(false); // End loading
    }
  };

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header
        closeButton
        style={{ background: "#F0F3FA", paddingLeft: "2rem" }}>
        <Modal.Title style={{ color: "#061151" }}>
          Business Registration
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ background: "#F0F3FA", padding: "2rem" }}>
        {error && (
          <Alert variant="danger" onClose={() => setError(null)} dismissible>
            {error}
          </Alert>
        )}
        <Form onSubmit={handleSubmit}>
          {/* Business Name */}
          <Form.Group className="mb-3">
            <Form.Label>
              <FaBuilding className="me-2" />
              Business Name
            </Form.Label>
            <Form.Control
              type="text"
              name="businessName"
              value={formData.businessName}
              onChange={handleChange}
              required
            />
          </Form.Group>

          {/* Business Description */}
          <Form.Group className="mb-3">
            <Form.Label>
              {" "}
              <FaBook className="me-2" />
              Business Description
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="description"
              value={formData.description}
              onChange={handleChange}
              required
              maxLength={500}
            />
          </Form.Group>

          {/* Business Maturity Level */}
          <Form.Group className="mb-3">
            <Form.Label>
              <FaChartLine className="me-2" />
              Business Maturity Level
            </Form.Label>
            <Form.Select
              name="maturityLevel"
              value={formData.maturityLevel}
              onChange={handleChange}
              required>
              <option value="">Select maturity level</option>
              <option value="Ideation">Ideation</option>
              <option value="Validation">Validation</option>
              <option value="Creation">Creation</option>
              <option value="Scaling">Scaling</option>
            </Form.Select>
          </Form.Group>

          {/* Operating Area */}
          <Form.Group className="mb-3">
            <Form.Label>
              <FaMapMarkerAlt className="me-2" />
              Operating Area
            </Form.Label>
            <Form.Select
              name="operatingArea"
              value={formData.operatingArea}
              onChange={handleChange}
              required>
              <option value="">Select operating area</option>
              <option value="Township">Township</option>
              <option value="Rural">Rural</option>
              <option value="Urban">Urban</option>
              <option value="Informal settlement">Informal settlement</option>
            </Form.Select>
          </Form.Group>

          {/* Business Address with Autocomplete */}
          <Form.Group className="mb-3">
            <Form.Label>
              <FaMapMarkerAlt className="me-2" />
              Business Address
            </Form.Label>
            <AddressAutocomplete onSelect={handleAddressSelect} />
          </Form.Group>

          {/* Industry */}
          <Form.Group className="mb-3">
            <Form.Label>
              <FaIndustry className="me-2" />
              Industry
            </Form.Label>
            <Form.Select
              name="industry"
              value={formData.industry}
              onChange={handleChange}
              required>
              <option value="">Select industry</option>
              <option value="Agriculture/Farming">Agriculture/Farming</option>
              <option value="Technology">Technology</option>
              <option value="Education">Education</option>
              <option value="Healthcare">Healthcare</option>
              <option value="Retail">Retail</option>
              <option value="Manufacturing">Manufacturing</option>
              <option value="Services">Services</option>
              <option value="Other">Other</option>
            </Form.Select>
          </Form.Group>

          {/* Annual Revenue */}
          <Form.Group className="mb-3">
            <Form.Label>
              <FaMoneyBillWave className="me-2" />
              Annual Revenue (in ZAR)
            </Form.Label>
            <Form.Select
              name="revenue"
              value={formData.revenue}
              onChange={handleChange}
              required>
              <option value="">Select revenue range</option>
              <option value="0-50000">0 - 50,000</option>
              <option value="50001-100000">50,001 - 100,000</option>
              <option value="100001-250000">100,001 - 250,000</option>
              <option value="250001-500000">250,001 - 500,000</option>
              <option value="500001+">500,001+</option>
            </Form.Select>
          </Form.Group>

          {/* Number of Jobs Created */}
          <Form.Group className="mb-3">
            <Form.Label>
              <FaUsers className="me-2" />
              Number of Jobs Created
            </Form.Label>
            <Form.Control
              type="number"
              name="jobsCreated"
              value={formData.jobsCreated}
              onChange={handleChange}
              required
              min="0"
            />
          </Form.Group>

          {/* Number of Business Partners */}
          <Form.Group className="mb-4">
            <Form.Label>
              <FaHandshake className="me-2" />
              Number of Business Partners
            </Form.Label>
            <Form.Control
              type="number"
              name="partners"
              value={formData.partners}
              onChange={handleChange}
              required
              min="0"
            />
          </Form.Group>

          {/* Submit Button */}
          <Button
            type="submit"
            className="w-100"
            style={{ background: "#bf9731", border: "none" }}
            disabled={loading}>
            {loading ? "Registering..." : "Register Business"}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default BusinessRegistrationModal;

import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Container,
  Card,
  Table,
  Button,
  InputGroup,
  FormControl,
  Modal,
  Tab,
  Tabs,
  Row,
  Col,
} from "react-bootstrap";
import { CiSearch } from "react-icons/ci";
import Sidebar from "../../components/SideBar";
import { FaTimes } from "react-icons/fa";
import Profile from "../../components/userProfile";
import TopNavBar from "../../components/topNavBar";

const BusinessAdminView = () => {
  const [businesses, setBusinesses] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedBusiness, setSelectedBusiness] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    fetchBusinesses();
  }, []);

  const fetchBusinesses = async () => {
    const token = localStorage.getItem("token");

    // If there's no token, throw an error
    if (!token) {
      throw new Error("No authentication token found");
    }

    // Create a config object with the authorization header
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      setLoading(true);
      const response = await axios.get(
        "https://yaei-backend-n63o.onrender.com/api/businesses",
        config
      );

      const businessPromises = response.data.map(async (business) => {
        // Fetch owner and diagnostics data
        const ownerPromise = axios.get(
          `https://yaei-backend-n63o.onrender.com/api/users/${business.owner}`,
          config
        );
        const diagnosticsPromise = axios.get(
          `https://yaei-backend-n63o.onrender.com/api/diagnostics?business=${business._id}`,
          config
        );

        // Wait for both promises to settle
        const [ownerResult, diagnosticsResult] = await Promise.allSettled([
          ownerPromise,
          diagnosticsPromise,
        ]);

        // Check if owner fetch was successful
        if (ownerResult.status === "fulfilled") {
          return {
            ...business,
            owner: ownerResult.value.data,
            diagnostics:
              diagnosticsResult.status === "fulfilled"
                ? diagnosticsResult.value.data
                : [],
          };
        } else {
          // Owner not found, skip this business
          console.error(
            `Owner not found for business ${business.businessName}, skipping.`
          );
          return null;
        }
      });

      // Wait for all business promises to settle
      const results = await Promise.all(businessPromises);

      // Filter out businesses where the owner was not found
      const businessesWithDetails = results.filter(
        (business) => business !== null
      );
      setBusinesses(businessesWithDetails);
      setError(null);
    } catch (err) {
      setError("Error fetching businesses. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleView = (business) => {
    setSelectedBusiness(business);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedBusiness(null);
  };

  const handleDelete = async (id) => {
    const token = localStorage.getItem("token");

    // If there's no token, throw an error
    if (!token) {
      throw new Error("No authentication token found");
    }

    // Create a config object with the authorization header
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      await axios.delete(
        `https://yaei-backend-n63o.onrender.com/api/businesses/${id}`,
        config
      );
      fetchBusinesses();
    } catch (err) {
      setError("Error deleting business. Please try again later.");
    }
  };

  const filteredBusinesses = businesses.filter((business) =>
    business.businessName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const BusinessDetailsModal = ({ show, handleClose, business }) => {
    if (!business) return null;

    return (
      <Modal show={show} onHide={handleClose} size="lg" centered>
        <Modal.Header
          className="border-0 d-flex flex-row justify-content-between"
          style={{
            background: "#061151",
            color: "#bf9731",
          }}>
          <Modal.Title>{business.businessName}</Modal.Title>
          <Button variant="link" className="text-white" onClick={handleClose}>
            <FaTimes />
          </Button>
        </Modal.Header>
        <Modal.Body className="bg-light">
          <Tabs defaultActiveKey="details" className="mb-3">
            <Tab eventKey="details" title="Business Details">
              <Row>
                {[
                  { label: "Maturity Level", value: business.maturityLevel },
                  { label: "Operating Area", value: business.operatingArea },
                  { label: "Industry", value: business.industry },
                  { label: "Revenue", value: business.revenue },
                  { label: "Jobs Created", value: business.jobsCreated },
                  { label: "Partners", value: business.partners },
                ].map((item, index) => (
                  <Col md={6} key={index} className="mb-3">
                    <Card className="h-100 border-0 shadow-sm">
                      <Card.Body>
                        <Card.Title className="fs-6">{item.label}</Card.Title>
                        <Card.Text className="fs-5 fw-bold">
                          {item.value}
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Tab>
            <Tab eventKey="owner" title="Owner Information">
              <Card className="border-0 shadow-sm">
                <Card.Body>
                  <Card.Title className="fs-5 mb-3">Owner Details</Card.Title>
                  <Row>
                    <Col md={6}>
                      <strong>Name:</strong> {business.owner.name}
                    </Col>
                    <Col md={6}>
                      <strong>Email:</strong> {business.owner.email}
                    </Col>
                    <Col md={6}>
                      <strong>Phone:</strong> {business.owner.phone}
                    </Col>
                    <Col md={6}>
                      <strong>Age:</strong> {business.owner.age}
                    </Col>
                    <Col md={6}>
                      <strong>Gender:</strong> {business.owner.gender}
                    </Col>
                    <Col md={6}>
                      <strong>Area:</strong> {business.owner.area}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Tab>
            <Tab eventKey="diagnostics" title="Diagnostics">
              {business.diagnostics.length === 0 ? (
                <p>No diagnostics available for this business.</p>
              ) : (
                business.diagnostics.map((diagnostic, index) => (
                  <Card key={index} className="mb-3 border-0 shadow-sm">
                    <Card.Body>
                      <Card.Title className="fs-6">
                        Diagnostic {index + 1}
                      </Card.Title>
                      <Row>
                        {Object.entries(diagnostic).map(([key, value]) => {
                          if (
                            key !== "_id" &&
                            key !== "business" &&
                            key !== "__v"
                          ) {
                            return (
                              <Col md={6} key={key} className="mb-2">
                                <strong>
                                  {key.charAt(0).toUpperCase() + key.slice(1)}:
                                </strong>{" "}
                                {Array.isArray(value)
                                  ? value.join(", ")
                                  : value}
                              </Col>
                            );
                          }
                          return null;
                        })}
                      </Row>
                    </Card.Body>
                  </Card>
                ))
              )}
            </Tab>
          </Tabs>
        </Modal.Body>
        <Modal.Footer className="border-0 bg-light">
          <Button
            onClick={handleClose}
            variant="outline-primary"
            style={{
              borderColor: "#bf9731",
              color: "#bf9731",
            }}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <div
      className="d-flex flex-row gap-4"
      style={{
        minHeight: "100vh",
        maxHeight: "100vh",
        overflowY: "hidden",
        background: "#FFF",
      }}>
      <Sidebar />

      <Container fluid className="d-flex flex-column mt-4">
        <TopNavBar />
        <div className="d-flex flex-row justify-content-between">
          <h4
            style={{ fontSize: "23px", fontWeight: "500", color: "#061151" }}
            className="mb-4">
            Businesses
          </h4>

          <Profile />
        </div>

        <Card
          className="p-2 mt-3"
          style={{
            border: "none",
            background: "#F0F3FA",
            minHeight: "80vh",
            maxHeight: "80vh",
            overflowY: "scroll",
          }}>
          <Card.Body>
            <div className="d-flex justify-content-between mb-5 mt-3">
              <InputGroup className="mb-3" style={{ width: "40%" }}>
                <FormControl
                  placeholder="Search Businesses"
                  value={searchTerm}
                  onChange={handleSearch}
                  style={{
                    backgroundColor: "transparent",
                    paddingLeft: "40px",
                    borderColor: "#061151",
                    borderRadius: "5px",
                  }}
                />
                <InputGroup.Text
                  style={{
                    position: "absolute",
                    zIndex: 10,
                    border: "none",
                    background: "none",
                    padding: "10px 0 0 10px",
                  }}>
                  <CiSearch />
                </InputGroup.Text>
              </InputGroup>
            </div>

            {loading && <p>Loading businesses...</p>}
            {error && <p className="text-danger">{error}</p>}
            {!loading && !error && (
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th style={TableStyle}>Business Name</th>
                    <th style={TableStyle}>Owner</th>
                    <th style={TableStyle}>Maturity Level</th>
                    <th style={TableStyle}>Industry</th>
                    <th style={TableStyle}>Revenue</th>
                    <th style={TableStyle}>Diagnostics</th>
                    <th style={TableStyle}>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredBusinesses.map((business) => (
                    <tr key={business._id}>
                      <td style={TableStyle}>{business.businessName}</td>
                      <td style={TableStyle}>{business.owner.name}</td>
                      <td style={TableStyle}>{business.maturityLevel}</td>
                      <td style={TableStyle}>{business.industry}</td>
                      <td style={TableStyle}>{business.revenue}</td>
                      <td style={TableStyle}>{business.diagnostics.length}</td>
                      <td style={TableStyle}>
                        <Button
                          style={{ background: "#061151", border: "none" }}
                          size="sm"
                          onClick={() => handleView(business)}
                          className="me-2">
                          View
                        </Button>
                        <Button
                          style={{ background: "#bf9731", border: "none" }}
                          size="sm"
                          onClick={() => handleDelete(business._id)}>
                          Delete
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}

            <BusinessDetailsModal
              show={showModal}
              handleClose={handleCloseModal}
              business={selectedBusiness}
            />
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
};

export default BusinessAdminView;

const TableStyle = { background: "#F0F3FA" };

import React, { useState } from "react";
import { Scanner } from "@yudiel/react-qr-scanner";
import axios from "axios";
import { Container, Card, Button, Alert, ListGroup } from "react-bootstrap";

const AdminScanner = () => {
  const [scanning, setScanning] = useState(false);
  const [scanResult, setScanResult] = useState(null);
  const [error, setError] = useState(null);
  const [scannedAttendees, setScannedAttendees] = useState([]);

  const handleScan = async (detectedCodes) => {
    if (detectedCodes && detectedCodes.length > 0) {
      const result = detectedCodes[0]; // Get the first detected code
      try {
        const decodedText = result.rawValue; // Assuming rawValue contains the QR data
        console.log("Decoded text:", decodedText);

        let scannedData;
        try {
          scannedData = JSON.parse(decodedText); // Try parsing the result as JSON
        } catch (err) {
          console.error("Error parsing QR code:", err);
          setError("Invalid QR code format");
          setScanning(false);
          return;
        }

        const { eventId, userId, eventTitle } = scannedData;

        // Call your API to verify and mark attendance
        const response = await axios.post(
          "https://yaei-backend-n63o.onrender.com/api/events/verify-attendance",
          {
            eventId,
            userId,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (response.data.success) {
          setScanResult(
            `Verified: ${response.data.attendeeName} for ${eventTitle}`
          );
          setScannedAttendees((prev) => [
            ...prev,
            { name: response.data.attendeeName, eventTitle },
          ]);
        } else {
          // Show the exact message coming from the backend
          setScanResult(response.data.message || "Failed to verify QR code.");
        }
      } catch (err) {
        console.error("Error verifying attendance:", err);
        // setError("Failed to verify attendance. Please try again.");
      }
      setScanning(false);
    }
  };

  const handleError = (err) => {
    console.error(err);
    setError("Error accessing camera. Please check permissions and try again.");
    setScanning(false);
  };

  return (
    <Container className="mt-4">
      <h2>Admin QR Code Scanner</h2>
      <Card className="mb-4">
        <Card.Body>
          {scanning ? (
            <Scanner
              onScan={handleScan}
              onError={handleError}
              style={{ width: "100%" }}
            />
          ) : (
            <Button onClick={() => setScanning(true)}>Start Scanning</Button>
          )}
          {error && (
            <Alert variant="danger" className="mt-3">
              {error}
            </Alert>
          )}
          {scanResult && (
            <Alert
              variant={scanResult.includes("Verified") ? "success" : "warning"} // Use warning for failures
              className="mt-3">
              {scanResult}
            </Alert>
          )}
        </Card.Body>
      </Card>
      <Card>
        <Card.Header>Scanned Attendees</Card.Header>
        <ListGroup variant="flush">
          {scannedAttendees.map((attendee, index) => (
            <ListGroup.Item key={index}>
              {attendee.name} - {attendee.eventTitle}
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Card>
    </Container>
  );
};

export default AdminScanner;

// CalendarComponent updated to accept props
import React, { useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";

const CalendarComponent = ({ events }) => {
  const [view, setView] = useState("month");

  const handleViewChange = (newView) => {
    setView(newView);
  };

  const eventStyleGetter = (event, start, end, isSelected) => {
    const style = {
      backgroundColor: event.background || "#3174ad",
      borderRadius: "5px",
      opacity: 0.8,
      color: "white",
      border: "0px",
      display: "block",
    };
    return {
      style: style,
    };
  };

  return (
    <div
      style={{
        height: "500px",
      }}>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: "100%" }}
        view={view}
        onView={handleViewChange}
        views={["month", "week", "day"]}
        eventPropGetter={eventStyleGetter}
        components={{
          event: EventComponent, // Use our custom Event component
        }}
      />
    </div>
  );
};

export default CalendarComponent;
const localizer = momentLocalizer(moment);

// Custom Event component
const EventComponent = ({ event }) => {
  return (
    <div>
      <strong>{event.title}</strong>
      <br />
      <small>
        {moment(event.start).format("LT")} - {moment(event.end).format("LT")}
      </small>
    </div>
  );
};

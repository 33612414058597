import React from "react";
import {
  HashRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import AdminDashboard from "./screens/Admin/AdminDashboard";
import OnboardingForm from "./screens/User/OnboardingForm";
import BusinessRegistrationForm from "./screens/User/BusinessRegistrationForm";
import MasterclassAdminView from "./screens/Admin/MasterclassAdminView";
import MasterclassesForUsers from "./screens/User/MasterclassesForUsers";
import SignIn from "./screens/User/Signin";
import EntrepreneurDashboard from "./screens/User/EntrepreneurDashboard";
import ProtectedRoute from "./components/ProtectedRoute";
import Unauthorized from "./components/Unauthorized";
import BusinessManagementPage from "./screens/User/BusinessManagementPage";
import EntrepreneurAdminView from "./screens/Admin/EntrepreneurAdminView";
import BusinessAdminView from "./screens/Admin/BusinessAdminView";
import ComingSoon from "./components/ComingSoon";
import MoodleEnrollment from "./screens/User/ MoodleEnrollment";
import PreEvaluationForm from "./screens/User/PreEvaluationForm";
import EventManagement from "./screens/Admin/EventManagement";
import EventsPage from "./screens/User/EventsPage";
import AdminScanner from "./screens/Admin/ScanBarcode";
import AdminSignup from "./screens/Admin/Signup";
import MealTicketGenerator from "./screens/Public/MealTicketGenerator";
import MealTicketScanner from "./screens/Public/MealTicketScanner";
import EventRegistration from "./screens/Public/PublicEventRegistration";
import CreatePublicEvent from "./screens/Public/CreatePublicEvent";
import PublicEventScanner from "./screens/Public/PublicEventScanner";
import EventRegistrationSystem from "./screens/Public/EventRegistrationSystem";
import EventLinks from "./screens/Public/EventsLinks";
import EventAttendanceList from "./screens/Public/EventAttendanceList";

function App() {
  return (
    <Router>
      <Routes>
        {/* Public routes */}
        <Route path="/" element={<SignIn />} />
        <Route path="/thisisanadminsignup" element={<AdminSignup />} />
        <Route path="/meal-ticket" element={<MealTicketGenerator />} />
        <Route path="/meal-scanner" element={<MealTicketScanner />} />
        {/* <Route path="/event-registration" element={<EventRegistration />} /> */}
        <Route
          path="/event-registration"
          element={<EventRegistrationSystem />}
        />
        <Route path="/event-creation" element={<CreatePublicEvent />} />
        <Route path="/event-attendance" element={<EventAttendanceList />} />

        <Route path="/public-event-scanner" element={<PublicEventScanner />} />
        <Route path="/ev" element={<EventLinks />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/signup" element={<OnboardingForm />} />
        <Route path="/moodle-enrollment" element={<MoodleEnrollment />} />
        {/* Protected Admin routes */}
        <Route element={<ProtectedRoute allowedRoles={["admin"]} />}>
          <Route path="/admin/dashboard" element={<AdminDashboard />} />
          <Route
            path="/admin/masterclasses"
            element={<MasterclassAdminView />}
          />
          <Route
            path="/admin/entrepreneurs"
            element={<EntrepreneurAdminView />}
          />
          <Route path="/admin/businesses" element={<BusinessAdminView />} />
          <Route path="/admin/events" element={<EventManagement />} />
          <Route path="/admin/scan" element={<AdminScanner />} />
        </Route>
        {/* Protected Entrepreneur routes */}
        <Route element={<ProtectedRoute allowedRoles={["entrepreneur"]} />}>
          <Route path="/dashboard" element={<EntrepreneurDashboard />} />
          <Route path="/businesses" element={<BusinessManagementPage />} />
          <Route path="/masterclasses" element={<MasterclassesForUsers />} />
          <Route path="/events" element={<EventsPage />} />
          <Route path="/preEvaluation" element={<PreEvaluationForm />} />

          {/* Add other entrepreneur routes here */}
        </Route>
        {/* Unauthorized and catch-all routes */}
        <Route path="/unauthorized" element={<Unauthorized />} />
        <Route path="*" element={<Navigate to="/signin" replace />} />
      </Routes>
    </Router>
  );
}

export default App;
